import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../views/Home";
import Cleaning from "../views/Cleaning";
import Plumbing from "../views/Plumbing";
import Gardening from "../views/Gardening";
import ServiceCalendar from "../views/ServiceCalendar";
import ServiceUserForm from "../views/ServiceUserForm";

import FAQ from "../views/FAQ";
import AboutUs from "../views/AboutUs";
import Terms from "../views/Terms";
import Appointment from "../views/Appointment";
import AppointmentAddress from "../components/appointment/AppointmentAddress";
import AppointmentCalendar from "../components/appointment/AppointmentCalendar";
import AppointmentCount from "../components/appointment/AppointmentCount";
import AppointmentServiceForm from "../components/appointment/AppointmentServiceForm";
import AppointmentUserForm from "../components/appointment/AppointmentUserForm";
import AppointmentPaymentMethod from "../components/appointment/AppointmentPaymentMethod";
import AppoinmentPaymentResume from "../views/AppoinmentPaymentResume";
import Electricity from "../views/Electricity";
import Locksmith from "../views/Locksmith";
import WorkWithUS from "../views/WorkWithUS";
import Support from "../views/Support";
import { WireTransfer } from "../views/WireTransfer";
import WhatsappRedirect from "../views/WhatsappRedirect";

import AppointmentOptionalServices from "../components/appointment/AppointmentOptionalService";

// Users
import Register from "../views/Register";
import Login from "../views/Login"; 
import Profile from "../views/Profile";
import ProfileAccount from "../components/profile/ProfileAccount";
import ProfileAddresses from "../components/profile/ProfileAddresses";
import ProfileAppointments from "../components/profile/ProfileAppointments";
import ProfileChangePassword from "../components/profile/ProfileChangePassword";

export const RoutesLinks = () => {
  return (
    <Routes>
      <Route path="*" element={<Home />}></Route>

      <Route path="/limpieza" element={<Cleaning />}></Route>

      <Route path="/gasfiteria" element={<Plumbing />}></Route>

      <Route path="/jardineria" element={<Gardening />}></Route>

      <Route path="/electricidad" element={<Electricity />}></Route>

      <Route path="/cerrajeria" element={<Locksmith />}></Route>

      <Route
        path="/limpieza/general/"
        element={<Appointment service={"generalCleaningDept"} />}
      >
        <Route path="" element={<AppointmentServiceForm />}></Route>
        <Route path="*" element={<AppointmentServiceForm />}></Route>
        <Route path="contador" element={<AppointmentCount />}></Route>
        <Route path="adicionales" element={<AppointmentOptionalServices />}></Route>
        <Route path="direccion" element={<AppointmentAddress />}></Route>
        <Route path="agendamiento" element={<AppointmentCalendar />}></Route>
        <Route path="contacto" element={<AppointmentUserForm />}></Route>
        <Route path="pago" element={<AppointmentPaymentMethod />}></Route>
      </Route>

      <Route path="/limpieza/calendario" element={<ServiceCalendar />} />

      <Route path="/limpieza/registro" element={<ServiceUserForm />} />

      <Route path="/faq" element={<FAQ />} />

      <Route path="/sobre-nosotros" element={<AboutUs />} />

      <Route path="/trabaja-con-nosotros" element={<WorkWithUS />} />

      <Route path="/Terminos-Condiciones" element={<Terms />} />

      <Route path="/soporte" element={<Support/>} />

      <Route path="/datos-transferencia" element={<WireTransfer/>} />

      <Route path="/resumen-pago" element={<AppoinmentPaymentResume/>}/>

      <Route path="/registro" element={<Register />} />

      <Route path="/login" element={<Login />} />

      <Route path="/perfil" element={<Profile />}>
        <Route path="" element={<ProfileAccount />}></Route>
        <Route path="*" element={<ProfileAccount />}></Route>
        <Route path="direcciones" element={<ProfileAddresses />}></Route> 
        <Route path="citas" element={<ProfileAppointments />}></Route>
        <Route path="cambiar-contrasena" element={<ProfileChangePassword />}></Route>

      </Route>

      <Route 
        path="/wsp/" 
      >
        <Route path="*" element={<WhatsappRedirect name="Mopit"/>}></Route>
        <Route path="casas" element={<WhatsappRedirect name="limpieza de casas"/>}></Route>
        <Route path="oficinas" element={<WhatsappRedirect name="limpieza de oficinas"/>}></Route>
        <Route path="gasfiteria" element={<WhatsappRedirect name="gasfitería"/>}></Route>
        <Route path="jardineria" element={<WhatsappRedirect name="jardinería"/>}></Route>
        <Route path="electricidad" element={<WhatsappRedirect name="electricidad"/>}></Route>
        <Route path="cerrajeria" element={<WhatsappRedirect name="cerrajería"/>}></Route>
      </Route>
    </Routes>
  );
};
