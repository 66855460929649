import React, { useEffect } from 'react';
import Mockup from '../../assets/images/mockupWithBg.png'
import ButtonApple from '../../assets/images/apple.png'
import ButtonAndroid from '../../assets/images/android.png'
import HeaderSection from '../general/HeaderSection';

//aos
import Aos from 'aos';
import 'aos/dist/aos.css';

const HomeMobile = () => {
    useEffect(() => {
        Aos.init({duration: 1000, once: true});
    }, []);
    return (
        <>
            <HeaderSection title='Llévanos a todas partes contigo ' left={false}/>
            <div className='mobile-row'>
                <div data-aos="zoom-out"  className='mobile-item'>
                    <h3 data-aos="fade-up" data-aos-anchor-placement="top-bottom" className='mobile-item__title'>Descarga nuestra App!</h3>
                    <p className='mobile-item__description'>Pronto tendremos disponible una aplicación móvil donde podrás realizar el agendamiento de tus servicios de forma fácil, rápida y segura. ¡Estaremos informando a través de nuestras redes sociales!</p>
                    <div className="app-buttons">
                        <div className='app-button'>
                            <img className="app-button__img" src={ButtonApple} alt="appStore" />
                            <div className="app-button__text">
                                Pronto...
                            </div>
                        </div>
                        <div className='app-button'>
                            <img className="app-button__img" src={ButtonAndroid} alt="appStore" />
                            <div className="app-button__text">
                                Pronto...
                            </div>
                        </div>
                    </div>
                </div>
                <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" className='mobile-item__img'>
                    <img src={Mockup} alt='mopitApp'/>
                </div>
            </div>
        </>
    );
};

export default HomeMobile;