import React from "react";
import { NavLink } from "react-router-dom";
import mopitlogo from "../../assets/images/isotipo.png";
import { useNavigate } from "react-router-dom";
import { routes } from "../../data/routes.json";

const AppointmentHeader = ({ actualStep }) => {
  const navigate = useNavigate();

  const handleClick = (step) => {
    // find the route that matches the step
    const routeObject = routes.find((route) => route.step === step);
    navigate(routeObject.path);
  };

  return (
    <header className="header-appointment">
      {/* logo */}
      <NavLink to="/">
        <img
          src={mopitlogo}
          className="header-appointment__logo"
          alt="mopitlogo"
        ></img>
      </NavLink>
      {/* list */}
      <nav>
        <ul className="circles-appointment-container">
          <li
            onClick={() => handleClick(1)}
            className={
              actualStep >= 1
                ? "item-header-appointment item-header-appointment--active"
                : "item-header-appointment"
            }
          >
            <div
              className={
                actualStep >= 1
                  ? "circle-appointment circle-appointment--active"
                  : "circle-appointment"
              }
            >
              <h5>1</h5>
            </div>
            <div className="item-header-appointment__name-step">
              <h3>Opciones del Servicio</h3>
            </div>
          </li>
          <li
            onClick={() => handleClick(2)}
            className={
              actualStep >= 2
                ? "item-header-appointment item-header-appointment--active"
                : "item-header-appointment"
            }
          >
            <div
              className={
                actualStep >= 2
                  ? "circle-appointment circle-appointment--active"
                  : "circle-appointment"
              }
            >
              <h5>2</h5>
            </div>
            <div className="item-header-appointment__name-step">
              <h3>Cantidad</h3>
            </div>
          </li>

          <li
            onClick={() => handleClick(3)}
            className={
              actualStep >= 3
                ? "item-header-appointment item-header-appointment--active"
                : "item-header-appointment"
            }
          >
            <div
              className={
                actualStep >= 3
                  ? "circle-appointment circle-appointment--active"
                  : "circle-appointment"
              }
            >
              <h5>3</h5>
            </div>
            <div className="item-header-appointment__name-step">
              <h3>Servicio adicional</h3>
            </div>
          </li>

          <li
            onClick={() => handleClick(4)}
            className={
              actualStep >= 4
                ? "item-header-appointment item-header-appointment--active"
                : "item-header-appointment"
            }
          >
            <div
              className={
                actualStep >= 4
                  ? "circle-appointment circle-appointment--active"
                  : "circle-appointment"
              }
            >
              <h5>4</h5>
            </div>
            <div className="item-header-appointment__name-step">
              <h3>Dirección</h3>
            </div>
          </li>
          <li
            onClick={() => handleClick(4)}
            className={
              actualStep >= 5
                ? "item-header-appointment item-header-appointment--active"
                : "item-header-appointment"
            }
          >
            <div
              className={
                actualStep >= 5
                  ? "circle-appointment circle-appointment--active"
                  : "circle-appointment"
              }
            >
              <h5>5</h5>
            </div>
            <div className="item-header-appointment__name-step">
              <h3>Agendamiento</h3>
            </div>
          </li>
          <li
            onClick={() => handleClick(5)}
            className={
              actualStep >= 6
                ? "item-header-appointment item-header-appointment--active"
                : "item-header-appointment"
            }
          >
            <div
              className={
                actualStep >= 6
                  ? "circle-appointment circle-appointment--active"
                  : "circle-appointment"
              }
            >
              <h5>6</h5>
            </div>
            <div className="item-header-appointment__name-step">
              <h3>Contacto</h3>
            </div>
          </li>
          

          <li
            onClick={() => handleClick(6)}
            className={
              actualStep >= 7
                ? "item-header-appointment item-header-appointment--active"
                : "item-header-appointment"
            }
          >
            <div
              className={
                actualStep >= 7
                  ? "circle-appointment circle-appointment--active"
                  : "circle-appointment"
              }
            >
              <h5>7</h5>
            </div>
            <div className="item-header-appointment__name-step">
              <h3>Pago</h3>
            </div>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default AppointmentHeader;
