import React from 'react';
import AppointmentUserForm from '../components/appointment/AppointmentUserForm';
import Header from '../components/general/Header';

const ServiceUserForm = () => {
return (
    <>
        <Header/>

        <AppointmentUserForm />
    </>

);
};

export default ServiceUserForm