import React, {useState, useEffect} from 'react'
import { Button } from 'semantic-ui-react'
import axios from "axios";
import randomstring from "randomstring";

import {
    paymentAPI,
    mainPageURL
} from "../api/Api";


export const WireTransfer = () => {

    const data = require('../data/transferData.json').data;

    const { REACT_APP_PAYMENT_KEY } = process.env;

    const [price, setPrice] = useState("");

    useEffect(() => {
        let currentUrl = window.location.href;
        let params = new URL(currentUrl).searchParams;
        let paramPrice = params.get("price");
        setPrice(paramPrice);
    }, [])

    const onSubmit = async() => {
        let currentUrl = window.location.href;
        let params = new URL(currentUrl).searchParams;
        let appID = params.get("appointment_id");
        let appIDs = appID.split("x");
        
        let token = `${appIDs[0]}-${randomstring.generate(20)}`

        for (let i = 0; i < appIDs.length; i++) {
            const newPayment = await axios.post(
                `${paymentAPI}/${appIDs[i]}`,
                {
                    paymentMethod: "TransferenciaBancaria",
                    paymentId: token,
                },
                {
                    headers: {
                    "Content-Type": "application/json",
                    "x-api-key": REACT_APP_PAYMENT_KEY,
                    },
                }
            );
        }
        
        window.location.href = `${mainPageURL}/resumen-pago?appointment_id=${appID}&token=${token}&payment_method=TransferenciaBancaria&status=approved`;
    }

    return (
        <div>
            <h1 className='wt-title'>Datos de transferencia</h1>
            <div className='wt-data-box'>
                {
                    data.map((item, i) => {
                        return(
                            <div>
                                <p className='wt-data-box__title'>{item.name}</p>
                                <p className='wt-data-box__text'>{item.info}</p>
                            </div>
                        );
                    })
                }
                <p className='wt-data-box__title'>Monto</p>
                <p className='wt-data-box__text'>${price}</p>
            </div>
            <p className='wt-alert'>Cuando tu transferencia este lista presiona el siguiente botón, se te redirigirá a la ventana de pago confirmado, de todas maneras el pago será verificado manualmente</p>
            <p className='wt-alert--margin'>¡Gracias por agendar en Mopit!</p>
            <div className="wt-button">
                <Button primary onClick={e => {onSubmit()}}>¡Ya he pagado!</Button>
            </div>
            
        </div>
    )
}
