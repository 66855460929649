import React, { useState, useEffect } from "react";
import { Form, Button, Input, Message } from "semantic-ui-react";
import { format, validate } from "rut.js";
import { Auth } from "aws-amplify";

import * as validateMethods from "../../hook/ValidateMethods";
import registerLogo from "../../assets/images/banner/bannerLocksmith.png";

import usePasswordSecurityValidation from "../../hook/usePasswordSecurityValidation";

const formErrorMessage = (errorType, errorMessage) => {

    let message = "";
    switch (errorType) {
        case "UsernameExistsException":
            message = "El correo ingresado ya esta registrado";
            break;
        default:
            message = errorMessage;
    }

    return (
        <Message
        error
        header="Error"
        content={message}
    />
    )
    
}

const registerSuccessMessage = () => {
    return (
        <Message
            success
            header="Registro exitoso"
            content="Se ha enviado un correo de confirmación a su cuenta de correo"
        />
    )
}

const SignUp = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword, passwordValid] =
        usePasswordSecurityValidation();
    const [passwordMatch, setPasswordMatch] = useState(true);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [name, setName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [phoneValid, setPhoneValid] = useState(true);
    const [dni, setDni] = useState("");

    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorType, setErrorType] = useState("");
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        // remove error after 3 seconds
        if (error) {
            setTimeout(() => {
                setError(false);
                setErrorMessage("");
                setErrorType("");
            }, 3000);
        }
    }, [error]);

    const [emailValid, setEmailValid] = useState(true);
    const [rutValid, setRutValid] = useState(true);

    const formValid =
        email &&
        passwordValid &&
        confirmPassword &&
        name &&
        lastName &&
        phone &&
        dni;

    const onSubmit = async (e) => {
        e.preventDefault();
        setError(false);
        setErrorMessage("");
        setErrorType("");

        const rut_valid = validate(dni);
        if (!rut_valid) {
            setRutValid(false);
            return;
        }

        // validate phone
        const phone_valid = validateMethods.validatePhone(phone);
        if (!phone_valid) {
            setPhoneValid(false);
            return;
        }

        // check if passwords match
        if (password !== confirmPassword) {
            setPasswordMatch(false);
            return;
        }

        try {
            const { user } = await Auth.signUp({
                username: email,
                password,
                attributes: {
                    email,
                    given_name: name,
                    family_name: lastName,
                    phone_number: phone,
                    "custom:dni": format(dni, { dots: false }),
                },
            });
            setSuccess(true);
        } catch (error) {
            setError(true);
            setErrorMessage(error.message);
            setErrorType(error.code);
        }
    };

    return (
        <div className="login-container">
            <div className="login-container__header">Bienvenido a Mopit</div>
            <img
                className="login-container__logo"
                src={registerLogo}
                alt="Mopit"
            />
            {error && formErrorMessage(errorType, errorMessage)}
            {success && registerSuccessMessage()}
            <Form>
                <Form.Group widths="equal">
                    <Form.Field
                        required
                        id="form-input-control-nombre"
                        control={Input}
                        label="Nombres"
                        name="firstName"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Raúl Paolo"
                    />
                    <Form.Field
                        required
                        id="form-input-control-apellido"
                        control={Input}
                        label="Apellidos"
                        name="lastName"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        placeholder="Campos Aravena"
                    />
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field
                        required
                        id="form-input-control-rut"
                        control={Input}
                        label="RUT"
                        name="rut"
                        value={dni ? format(dni) : ""}
                        onChange={(e) => setDni(format(e.target.value))}
                        error={
                            rutValid
                                ? false
                                : {
                                      content:
                                          "Por favor ingrese un RUT valido",
                                  }
                        }
                        placeholder="12.345.678-9"
                    />
                    <Form.Field
                        required
                        id="form-input-control-phone"
                        control={Input}
                        label="Celular"
                        name="phone"
                        value={phone}
                        onChange={(e) => {
                            setPhone(e.target.value)
                            setPhoneValid(true)
                        }}
                        placeholder="+56912345678"
                        error={
                            !phoneValid && {
                                content: "Por favor ingrese un celular valido",
                            }
                        }
                    />
                </Form.Group>

                <Form.Field
                    required
                    id="form-input-control-email"
                    control={Input}
                    label="Correo electronico"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={
                        emailValid
                            ? false
                            : {
                                  content: "Por favor ingrese un e-mail valido",
                              }
                    }
                    placeholder="correo@mail.cl"
                />

                <Form.Group widths="equal">
                    <Form.Field
                        required
                        id="form-input-control-password"
                        control={Input}
                        type="password"
                        label="Contraseña"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        error={
                            !passwordValid && {
                                content:
                                    "La contraseña debe tener al menos 8 caracteres, 1 número, 1 mayúscula y 1 minúscula",
                                pointing: "above",
                            }
                        }
                    />
                    <Form.Field
                        required
                        id="form-input-control-confirm-password"
                        control={Input}
                        type="password"
                        label="Confirmar contraseña"
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => {
                            setConfirmPassword(e.target.value);
                            setPasswordMatch(true);
                        }}
                        error={
                            !passwordMatch && {
                                content: "Las contraseñas no coinciden",
                                pointing: "above",
                            }
                        }
                    />
                </Form.Group>
                <div className="login-container__send-button">
                    <Button disabled={!formValid} onClick={onSubmit} primary>
                        Registrarse
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default SignUp;
