import React from 'react';
import AppointmentCalendar from '../components/appointment/AppointmentCalendar';
import Header from '../components/general/Header';

const ServiceCalendar = () => {
  return (
    <>
      <Header/>
      <AppointmentCalendar/>
    </>
  
  );
};

export default ServiceCalendar