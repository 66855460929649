import React from "react";
import { NavLink } from "react-router-dom";
import Check from "../../assets/images/cardsIcon/check.png";
import Not from "../../assets/images/cardsIcon/close.png";
import { Icon } from 'semantic-ui-react'

const ServiceCard = ({ subService }) => {
  let subServices = require("../../data/cardSubServices.json");
  subServices = subServices.cardSubServices;
  let info = null;

  switch (subService) {
    case "cleaning":
      info = subServices.cleaning;
      break;

    case "plumbing":
      info = subServices.plumbing;
      break;

    case "gardening":
      info = subServices.gardening;
      break;
    case "electricity":
      info = subServices.electricity;
      break;
    case "locksmith":
      info = subServices.locksmith;
      break;

    default:
      info = null;
      break;
  }

  function renderSubServices() {
    if (info && info.length > 0) {
      return (
        <>
          {info.map((item) => {
            return (
              <div className="product-card">

                <div className='product-card__header'>
                  <h1>{item.title}</h1>
                  <p>{item.description}</p>
                </div>

                <NavLink to={item.path}>
                  <button className={item.subTitle !== "" ? "button-service button-service--active" : "button-service"}>
                    <span className="button-service__text">
                      {item.subTitle !== "" ? item.subTitle : "Pronto..."}
                    </span>
                  </button>
                </NavLink>

                <div className="subservice-details-container">
                  <div className="included-container">
                    <ul>
                      {item.include.map((included) => {
                        return (
                          <li>
                            <h4>{included.title}</h4>
                            {included.points.map((point) => {
                              return (
                                <div className="row-box">
                                  <img src={Check} alt="check" style={{ height: 10, width: 10 }} />
                                  <p>{point}</p>
                                </div>
                              );
                            })}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="not-included-container">
                    {item.notInclude.length > 1
                      ? <h4>Servicio no incluidos:</h4>
                      : null
                    }
                    <ul>
                      {item.notInclude.map((notIncluded) => {
                        return (
                          <li className="row-box row-box--not">
                            <img src={Not} alt="not" style={{ height: 8, width: 8 }} />
                            <p>{notIncluded}</p>
                          </li>
                        );
                      })
                      }
                    </ul>
                  </div>
                  
                  <div className="not-included-container">
                    {item.extraNotes.length >= 1
                      ? <h4>Información Importante</h4>
                      : null
                    }
                    <ul>
                      {item.extraNotes.map((extraNote) => {
                        return (
                          <li className="row-box row-box--not">
                            <Icon name='asterisk' size ="small" />
                            <p>{extraNote}</p>
                          </li>
                        );
                      })
                      }
                    </ul>
                  </div>

                </div>
              </div>

            );
          })}
        </>
      );
    } else {
      return <h5>No hay servicios disponibles por el momento.</h5>;
    }
  }

  return (
    // Contenedor
    <div className="product-card-list">{renderSubServices()}</div>
  );
};

export default ServiceCard;
