export const pricingCalculatorAPI = process.env.REACT_APP_PRICING_CALCULATOR_API_URL;
export const mercadopagoTestingAPI = process.env.REACT_APP_MERCADO_PAGO_TESTING_API_URL;
export const webpayTestingAPI = process.env.REACT_APP_WEBPAY_TESTING_API_URL;
export const checkWebpayPaymentTestingAPI = process.env.REACT_APP_CHECK_WEBPAY_PAYMENT_TESTING_API_URL;
export const sendSupportEmailAPI = process.env.REACT_APP_SEND_SUPPORT_EMAIL_API_URL;
export const sendPurchaseEmailShopAPI = process.env.REACT_APP_SEND_PURCHASE_EMAIL_SHOP_API_URL;
export const sendCouponEmailAPI = process.env.REACT_APP_SEND_COUPON_EMAIL_API_URL;

export const appointmentAPI = process.env.REACT_APP_APPOINTMENT_API_URL;
export const customerAPI = process.env.REACT_APP_CUSTOMER_API_URL;
export const locationAPI = process.env.REACT_APP_LOCATION_API_URL;
export const paymentAPI = process.env.REACT_APP_PAYMENT_API_URL;
export const couponAPI = process.env.REACT_APP_COUPON_API_URL;
export const employeeAPI = process.env.REACT_APP_EMPLOYEE_API_URL;

export const communeAPI = process.env.REACT_APP_COMMUNE_API_URL;

export const facebookURL = "https://www.facebook.com/Mopit-105704627908146/";
export const instagramURL = "https://www.instagram.com/mopit.app/";
export const wspURL = "https://wa.me/+56992129138";
export const mainPageURL = window.location.origin;