function useServiceNameTranslate(serviceType) {
  switch (serviceType) {
    case "generalCleaningDept":
      return { serviceCategory: "Limpieza", serviceName: "Limpieza general de departamentos" };
    default:
      return "";
  }
}

export default useServiceNameTranslate;
