import React, { useEffect } from 'react';
import HeaderSection from '../general/HeaderSection';

//aos
import Aos from 'aos';
import 'aos/dist/aos.css';



const content = [
    {
        id: "01",
        title: "Opciones de servicio",
        description: "Lo primero que hay que hacer es escoger que tipo de servicio es el necesitado."
    },
    {
        id: "02",
        title: "Dirección",
        description: "Luego dar la dirección en donde se realizará el servicio."
    },
    {
        id: "03",
        title: "Agendamiento",
        description: "Posteriormente hay que escoger la fecha y la hora para recibir el servicio."
    },
    {
        id: "04",
        title: "Contacto",
        description: "En este paso es necesario dar la información del usuario."
    },
    {
        id: "05",
        title: "Pago",
        description: "Finalmente se escoge el metodo para pagar y se paga."
    },
]

function renderCard(item, color, animation) {
    if (color === 0) {
        return (
            <div data-aos={animation} className='choose-card'>
                <div className='choose-top choose-top--color1'>
                    <div className="choose-top__circle">
                        <div className="choose-top__title">
                            <p>{item.id}</p>
                        </div>
                    </div>
                </div>
                <div className="choose-bottom">
                    <h3 className='choose-bottom__title'>{item.title}</h3>
                    <span className='choose-bottom__description'>{item.description}</span>
                </div>
                <div className='choose-social choose-social--color1'>
                    <p>.</p>
                </div>
            </div>
        )
    }
    if (color === 1) {
        return (
            <div data-aos={animation} className='choose-card'>
                <div className='choose-top choose-top--color2'>
                    <div className="choose-top__circle">
                        <div className="choose-top__title">
                            <p>{item.id}</p>
                        </div>
                    </div>
                </div>
                <div className="choose-bottom">
                    <h3 className='choose-bottom__title'>{item.title}</h3>
                    <span className='choose-bottom__description'>{item.description}</span>
                </div>
                <div className='choose-social choose-social--color2'>
                    <p>.</p>
                </div>
            </div>
        )
    }
    if (color === 2) {
        return (
            <div data-aos={animation} className='choose-card'>
                <div className='choose-top choose-top--color3'>
                    <div className="choose-top__circle">
                        <div className="choose-top__title">
                            <p>{item.id}</p>
                        </div>
                    </div>
                </div>
                <div className="choose-bottom">
                    <h3 className='choose-bottom__title'>{item.title}</h3>
                    <span className='choose-bottom__description'>{item.description}</span>
                </div>
                <div className='choose-social choose-social--color3'>
                    <p>.</p>
                </div>
            </div>
        )
    }
    if (color === 3) {
        return (
            <div data-aos={animation} className='choose-card'>
                <div className='choose-top choose-top--color4'>
                    <div className="choose-top__circle">
                        <div className="choose-top__title">
                            <p>{item.id}</p>
                        </div>
                    </div>
                </div>
                <div className="choose-bottom">
                    <h3 className='choose-bottom__title'>{item.title}</h3>
                    <span className='choose-bottom__description'>{item.description}</span>
                </div>
                <div className='choose-social choose-social--color4'>
                    <p>.</p>
                </div>
            </div>
        )
    }
    else {
        return (
            <div data-aos={animation} className='choose-card'>
                <div className='choose-top choose-top--color5'>
                    <div className="choose-top__circle">
                        <div className="choose-top__title">
                            <p>{item.id}</p>
                        </div>
                    </div>
                </div>
                <div className="choose-bottom">
                    <h3 className='choose-bottom__title'>{item.title}</h3>
                    <span className='choose-bottom__description'>{item.description}</span>
                </div>
                <div className='choose-social choose-social--color5'>
                    <p>.</p>
                </div>
            </div>
        )
    }
}


const HomeWorkflow = () => {
    useEffect(() => {
        Aos.init({ duration: 1000, once: true });
    }, []);
    return (
        <>
            <HeaderSection title='¿Como se solicitan los servicios?' left={false} />
            <div className='choose-row'>
                {renderCard(content[0], 0, "fade-right")}
                {renderCard(content[1], 1, "fade-up")}
                {renderCard(content[2], 2, "fade-left")}
                {renderCard(content[3], 3, "fade-up")}
                {renderCard(content[4], 4, "fade-up")}
            </div>
        </>
    );
};

export default HomeWorkflow;