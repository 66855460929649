import React, { useEffect } from 'react';
import CardInfo from '../../data/cardServices.json';
import HeaderSection from '../general/HeaderSection';
import Broom from '../../assets/images/broom.png';
import Garden from '../../assets/images/garden.png';
import Faucet from '../../assets/images/faucet.png';
import Electricity from '../../assets/images/services/electricity.png'
import Locksmith from '../../assets/images/services/locksmith.png'
import { NavLink } from 'react-router-dom';

//aos
import Aos from 'aos';
import 'aos/dist/aos.css';

function renderCard(title, description, icon) {

    const imageSelect = (resp) => {
        switch (resp) {
            case "broom":
                return Broom;
            case "faucet":
                return Faucet;
            case "garden":
                return Garden;
            case "electricity":
                return Electricity;
            case "locksmith":
                return Locksmith;
            default:
                return null;
        }
    }


    // gasfiteria jardineria

    const linkSelect = (title) => {
        switch (title) {
            case 'Limpieza':
                return '/limpieza'
            case 'Gasfitería':
                return '/gasfiteria'
            case 'Jardinería':
                return '/jardineria'
            case 'Electricidad':
                return '/electricidad'
            case 'Cerrajería':
                return '/cerrajeria'
            default:
                return '/jardineria';
        }
    }

    return (
        <NavLink to={linkSelect(title)}>
            <div data-aos="zoom-out-up" key={title} className='service-card'>
                <div className='service-item'>
                    <div className='service-item__icon'>
                        <img src={imageSelect(icon)} alt="service-icon" className='service-item__img' />
                    </div>
                    <h4 className='service-item__title'>{title}</h4>
                    <p className='service-item__description'>{description}</p>
                </div>
            </div>
        </NavLink>
    )
}


const HomeService = () => {

    useEffect(() => {
        Aos.init({ duration: 1000, once: true });
    }, []);

    let info = CardInfo.cardServices
    return (
        <>
            <HeaderSection title='¡Los servicios que ofrecemos!' />

            <div className='service-row'>
                {
                    info.map((item, index) => {
                        return (renderCard(item.title, item.description, item.icon))
                    })
                }
            </div>
        </>
    );
};

export default HomeService;