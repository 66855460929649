import React, {useState, useEffect} from 'react'
import {Modal, Form, Input, Button, Message} from 'semantic-ui-react'
import {Auth} from 'aws-amplify'


import axios from 'axios';
import { customerAPI } from "../../api/Api";

function UpdateUser({editedUser, closedModal, previousEmail}) {
    const [open, setOpen] = useState(false);

    const { REACT_APP_CUSTOMER_KEY } = process.env;

    const [confirmationCode, setConfirmationCode] = useState('');
    const [error, setError] = useState(false);

    const updateAttributes = async () => {
        try {
            const cognitoUser = await Auth.currentAuthenticatedUser();
            if (cognitoUser.attributes.email !== editedUser.email) {
                await Auth.updateUserAttributes(cognitoUser, {
                    'phone_number': editedUser.phone,
                    'given_name': editedUser.name,
                    'family_name': editedUser.lastName,
                    'custom:dni': editedUser.dni,
                    'email': editedUser.email
                });
                setOpen(true);
            } else{
                await Auth.updateUserAttributes(cognitoUser, {
                    'phone_number': editedUser.phone,
                    'given_name': editedUser.name,
                    'family_name': editedUser.lastName,
                    'custom:dni': editedUser.dni
                });
                // update in database
                const response = await axios.put(customerAPI + '/' + cognitoUser.attributes['custom:customer_id'], {
                    name: editedUser.name,
                    lastName: editedUser.lastName,
                    phone: editedUser.phone,
                    dni: editedUser.dni,
                    email: editedUser.email
                }, {
                    headers: {
                        'x-api-key': REACT_APP_CUSTOMER_KEY
                    }
                });
                closedModal();
            }
        }
        catch (e) { 
            console.log(e);
        }
    }

    useEffect(() => {
        updateAttributes();
    }, []);

    const handleSubmit = async (e) => {
        // verify code
        e.preventDefault();
        try {
            const result = await Auth.verifyCurrentUserAttributeSubmit('email', confirmationCode);
            if (result === 'SUCCESS') {
                closedModal();
            } else{
                setError(true);
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    return (
        <Modal
            closeIcon
            open={open}
            closeOnEscape={true}
            closeOnDimmerClick={false}
            onClose={() => {
                setOpen(false);
                closedModal();
            }}
        >
            <Modal.Header>Código de confirmación</Modal.Header>
            <Modal.Content>
                {error && <Message negative>Código de verificación incorrecto</Message>}
                <Modal.Description>
                    <p>
                        Ingrese el código de confirmación que se le ha enviado a su nuevo correo electrónico.
                    </p>
                    <Form>
                        <Form.Field
                            control={Input}
                            label='Código de confirmación'
                            placeholder='Código de confirmación'
                            onChange={(e) => setConfirmationCode(e.target.value)}
                        />
                        <Button type='submit' onClick={handleSubmit} disabled={confirmationCode.length === 0}>
                            Confirmar
                        </Button>
                    </Form>
                </Modal.Description>
            </Modal.Content>
        </Modal>
        );
}

export default UpdateUser