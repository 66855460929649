import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Button, Icon, Dimmer, Loader } from "semantic-ui-react";
import Header from "../components/general/Header";
import Footer from "../components/general/Footer";
import mopitLogo from "../assets/images/mopit_icon.png";
import Confirm from "../assets/images/like.png";
import UnConfirm from "../assets/images/unlike.png";
import shareSocial from "../assets/images/share-social.png";
import mercadoPago from "../assets/images/payMethods/mercado-pago.png";
import webpay from "../assets/images/payMethods/webpay.png";
import transferenciaBancaria from "../assets/images/payMethods/transferencia.png";
import successGif from "../assets/images/success.gif";
import axios from "axios";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';


// aos
import Aos from "aos";
import "aos/dist/aos.css";

import {
  checkWebpayPaymentTestingAPI,
  appointmentAPI,
  facebookURL,
  instagramURL,
  sendPurchaseEmailAPI,
  sendPurchaseEmailShopAPI,
} from "../api/Api";

const AppoinmentPaymentResume = () => {
  useEffect(() => {
    Aos.init({ duration: 1000, once: true });
  }, []);
  const [adress, setAdress] = useState("");
  const [customerName, setCustomerName] = useState("");

  const [customerEmail, setCustomerEmail] = useState("");

  const [load, setLoad] = useState(0);

  const [appointmentAttributes, setAppointmentAttributes] = useState([]);
  const [appointmentData, setAppointmentData] = useState({});

  const [appointmentsData, setAppointmentsData] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState("");

  const navigate = useNavigate();

  const {
    REACT_APP_CHECK_WEBPAY_PAYMENT_API_KEY,
    REACT_APP_APPOINTMENT_KEY,
    REACT_APP_SEND_EMAIL_SHOP_KEY,
  } = process.env;

  useEffect(() => {
    let currentUrl = window.location.href;
    let params = new URL(currentUrl).searchParams;
    let payMethod = params.get("payment_method");
    let appIDs = params.get("appointment_id").split("x");
    let appID = appIDs[0];

    let payID;
    let status;

    setPaymentMethod(payMethod);

    switch (payMethod) {
      case "MercadoPago": {
        payID = params.get("preference_id");
        status = params.get("status");
        obtainData(payID, status, appIDs);
        break;
      }
      case "Webpay": {
        payID = params.get("token_ws");
        if (payID == null) {
          setLoad(3);
        } else {
          checkStatus(payID, appIDs);
        }
        break;
      }
      case "TransferenciaBancaria": {
        payID = params.get("token");
        status = params.get("status");
        obtainData(payID, status, appIDs);
        break;
      }
      default: {
        break;
      }
    }
  }, []);

  function niceName(item){
    switch (item.attribute_name){
      case "planchado":
        return "Planchado"
      case "limpiezaVentanas":
        return "Limpieza de ventana"
      case "limpiezaMicroondas":
        return "Limpieza microondas"
      case "limpiezaRefrigerador":
        return "Limpieza refrigerador"
      case "limpiezaHorno":
        return "Limpieza horno"
      case "limpiezaHornoRefriMicroondas":
        return "Pack  electrodomésticos"
      case "propertyType":
        return "Tipo de vivienda"
      default:
        return item.attribute_title;
    }
  }

  const checkStatus = async (payID, appIDs) => {
    try {
      const statusResponse = await axios.get(
        `${checkWebpayPaymentTestingAPI}/${payID}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": REACT_APP_CHECK_WEBPAY_PAYMENT_API_KEY,
          },
        }
      );
      let status;
      if (statusResponse.data.status === "AUTHORIZED") {
        status = "approved";
      } else if(statusResponse.data.details.status === "AUTHORIZED") {
        status = "approved";
      } else {
        status = "failed";
      }
      obtainData(payID, status, appIDs);
    } catch (err) {
      setLoad(3);
    }
  };

  const obtainData = async (payID, status, appIDs) => {
    if (!status || !appIDs[0] || !payID) {
      navigate("/home");
    }
    try {
      let appsDataAux = [];
      for (let i = 0; i < appIDs.length; i++) {
        let appID = appIDs[i];
        const appointmentSummaryResp = await axios.get(
          `${appointmentAPI}/${appID}/summary`,
          {
            headers: {
              "x-api-key": REACT_APP_APPOINTMENT_KEY,
            },
          }
        );
        const appointmentSummary = appointmentSummaryResp.data;

        appsDataAux = [...appsDataAux, appointmentSummary];
        
        setAppointmentData(appointmentSummary);
        if (appointmentSummary.payment_id == payID && status == "approved") {
          const attributesResponse = await axios.get(
            `${appointmentAPI}/${appID}/attributes`,
            {
              headers: {
                "x-api-key": REACT_APP_APPOINTMENT_KEY,
              },
            }
          );
          
          setCustomerEmail(appointmentSummary.customer_email);
          setCustomerName(
            `${appointmentSummary.customer_name} ${appointmentSummary.customer_lastname}`
          );
          setAdress(
            `${appointmentSummary.location_street} ${
              appointmentSummary.location_home_number
            } ${
              appointmentSummary.location_dept != null
                ? appointmentSummary.location_dept
                : ""
            }`
          );
          setAppointmentAttributes(attributesResponse.data);
  
          let startDate = new Date(
            appointmentSummary.start_timestamp
          ).toLocaleTimeString("es-CL");
          startDate = `${startDate.split(":")[0]}:${startDate.split(":")[1]}`;
  
          let endDate = new Date(
            appointmentSummary.end_timestamp
          ).toLocaleTimeString("es-CL");
          endDate = `${endDate.split(":")[0]}:${endDate.split(":")[1]}`;
  
          let localJsonEmail = {
            appointmentId: appID,
            date: new Date(appointmentSummary.start_timestamp).toLocaleDateString(
              "es-CL"
            ),
            startTimestamp: startDate,
            endTimestamp: endDate,
            total: appointmentSummary.total,
            subtotal: appointmentSummary.subtotal,
            coupon: appointmentSummary.coupon_code ?? "",
            discount: appointmentSummary.subtotal - appointmentSummary.total,
            paymentMethod: appointmentSummary.payment_method,
            paymentId: appointmentSummary.payment_id,
            customer: {
              name: appointmentSummary.customer_name,
              lastName: appointmentSummary.customer_lastname,
              email: appointmentSummary.customer_email,
              phone: appointmentSummary.customer_phone,
              dni: appointmentSummary.customer_dni,
            },
            location: {
              street: appointmentSummary.location_street,
              homeNumber: appointmentSummary.location_home_number,
              dept: appointmentSummary.location_dept,
              other: appointmentSummary.location_other,
              commune: appointmentSummary.commune,
              region: appointmentSummary.region,
              country: "Chile",
            },
            employee: {
              id: appointmentSummary.employee_id,
              rating: appointmentSummary.employee_rating ?? 0,
              name: appointmentSummary.employee_name,
              lastName: appointmentSummary.employee_lastname,
              email: appointmentSummary.employee_email,
              phone: appointmentSummary.employee_phone ?? "",
              dni: appointmentSummary.employee_dni,
              location: {
                street: appointmentSummary.employee_location_street ?? "",
                homeNumber:
                  appointmentSummary.employee_location_home_number ?? "",
                dept: appointmentSummary.employee_location_dept ?? "",
                other: appointmentSummary.employee_location_other ?? "",
                commune: appointmentSummary.employee_commune ?? "",
                region: appointmentSummary.employee_region ?? "",
                country: "Chile",
              },
            },
            attributes: [],
          };
  
          attributesResponse.data?.map((item, i) => {
            localJsonEmail.attributes.push({
              name: niceName(item),
              value: checkValue(item),
            });
          });
  
          // check if email has already been sent (if it has, don't send it again) by checking local storage
          let localStorageEmail = localStorage.getItem(appID);
          if (localStorageEmail != appID) {
            // send email
            localStorage.setItem(appID, "sent");
            let responseMessage = await sendEmailSummary(localJsonEmail);
            if(responseMessage != 'Email already sent' && i == 0) {
              // --- Google Analytics Revenue ---
              ReactGA.plugin.require("ecommerce");
              ReactGA.plugin.execute('ecommerce', 'addTransaction', {
                'id': appID,
                'name': `Limpieza-${appID}`,
                'sku': '01-LIMP',
                'category': 'LIMPIEZA',
                'price': appointmentSummary.total,
                'quantity': '1',
                'currency': 'CLP'
              });
              ReactGA.plugin.execute('ecommerce', 'send');
              ReactGA.plugin.execute('ecommerce', 'clear');
              ReactGA.plugin.execute('ecommerce', 'addTransaction', {
                id: appID,
                revenue: appointmentSummary.total,
                currency: 'CLP'
              });
              ReactGA.plugin.execute('ecommerce', 'send');
              ReactGA.plugin.execute('ecommerce', 'clear');
              // --- ----------------------- ---
  
              // --- Facebool Pixel Revenue ---
              ReactPixel.track('Purchase',{
                value: appointmentSummary.total,
                currency: 'CLP',
              });
              // --- ---------------------- ---
            }
          }
  
          const res = await axios.put(
            `${appointmentAPI}/${appID}/status`,
            {
              newStatus: "CONFIRMED",
            },
            {
              headers: {
                "x-api-key": REACT_APP_APPOINTMENT_KEY,
              },
            }
          );
          if(i == appIDs.length - 1) {
            setAppointmentsData(appsDataAux);
            setLoad(1);
          }
        } else if (status == "failed") {
          // update appointment status to REJECTED
          const res = await axios.put(
            `${appointmentAPI}/${appID}/status`,
            {
              newStatus: "REJECTED",
            },
            {
              headers: {
                "x-api-key": REACT_APP_APPOINTMENT_KEY,
              },
            }
          );
          setLoad(3);
        } else {
          setLoad(2);
        }
      }
    } catch (error) {
      console.log(error);
      navigate("/home");
    }
  };

  const checkDirtiness = (value) => {
    switch (value) {
      case "low": {
        return "Bajo";
      }
      case "normal": {
        return "Medio";
      }
      case "high": {
        return "Alto";
      }
      default: {
        return value;
      }
    }
  };

  const checkSize = (value) => {
    switch (value) {
      case "1": {
        return "1-45 m²";
      }
      case "2": {
        return "46-100 m²";
      }
      case "3": {
        return "101-180 m²";
      }
      default: {
        return value;
      }
    }
  };

  const checkPropertyType = (value) => {
    switch (value) {
      case "apartment": 
        return "Departamento";
      case "house":
        return "Casa";
      default:
        return value;
    }
  }

  const checkValue = (item) => {
    switch (item.attribute_name) {
      case "size": {
        return checkSize(item.attribute_value);
      }
      case "dirtiness": {
        return checkDirtiness(item.attribute_value);
      }
      case "propertyType":{
        return checkPropertyType(item.attribute_value);
      }
      default: {
        return item.attribute_value;
      }
    }
  };

  const selectImage = () => {
    switch (paymentMethod) {
      case "MercadoPago": {
        return mercadoPago;
      }
      case "Webpay": {
        return webpay;
      }
      case "TransferenciaBancaria": {
        return transferenciaBancaria;
      }
    }
  };

  async function sendEmailSummary(localJsonEmail) {
    let response = await axios.post(
      sendPurchaseEmailShopAPI, 
      localJsonEmail,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": REACT_APP_SEND_EMAIL_SHOP_KEY,
        },
      }
    );
    return response.data.message;
  }

  if (load === 0) {
    return (
      <>
        <Dimmer active inverted>
          <Loader size="massive">Cargando resumen del pago</Loader>
        </Dimmer>
      </>
    );
  } else if (load === 2) {
    return <Navigate to="/home"></Navigate>;
  } else if (load === 3) {
    return (
      <>
        <div className="appointment-pr-banner appointment-pr-banner--failure">
          <div data-aos="zoom-in" className="banner-pr-banner-container">
            <img src={UnConfirm} alt="mopit" />
            <h1 className="banner-pr-banner-container__failure">
              ¡Ups! pago fallido
            </h1>
          </div>
        </div>
        <div className="app-pr-back-container">
          <h3>¡Inténtalo de nuevo!</h3>
          <Button
            active
            className="app-pr-btn-back"
            onClick={(e) => {
              setLoad(2);
            }}
          >
            Volver al inicio
          </Button>
        </div>
      </>
    );
  } else {
    return (
      <>
        <Header />
        <div className="appointment-pr-banner">
          <div data-aos="zoom-in" className="banner-pr-banner-container">
            <img src={Confirm} alt="mopit" />
            <h1 className="banner-pr-banner-container__thanks">
              ¡Pago Confirmado!
            </h1>
            {/* <h5>Tu orden Nº{appointmentID} ha sido procesada</h5> */}
          </div>
        </div>
        <div className="title-app-pr">
          <h2>¡Gracias confiar en nosotros!</h2>
        </div>

        <div className="app-pr-content">
          <div className="app-pr-content-box">
            <h5>Resumen de la orden:</h5>
            <hr />
            <ul>
              <li className="app-pr-point">
                <span>Nombre:</span>
                <span>{customerName}</span>
              </li>
              <li className="app-pr-point">
                <span>Dirección:</span>
                <span>{adress}</span>
              </li>
              <br />
              {appointmentsData.map((item, index) => {
                let date = new Date(appointmentsData[index].start_timestamp).toLocaleDateString(
                  "es-CL"
                );
                let startTimeDate = new Date(
                  appointmentsData[index].start_timestamp
                ).toLocaleTimeString("es-CL");
                let endTimeDate = new Date(
                  appointmentsData[index].end_timestamp
                ).toLocaleTimeString("es-CL");
                
                return (
                  <div>
                    <hr />
                    <li className="app-pr-point">
                    <b>Agendamiento {index+1}</b>
                    </li>

                    <li className="app-pr-point">
                      <span>Fecha:</span>
                      <span>{date}</span>
                    </li>
                    <li className="app-pr-point">
                      <span>Hora de inicio:</span>
                      <span>{startTimeDate}</span>
                    </li>
                    <li className="app-pr-point">
                      <span>Hora de finalización:</span>
                      <span>{endTimeDate}</span>
                    </li>
                  </div>
                );
              })}
              <hr />                           
            </ul>
          </div>
          <div className="app-pr-content-box">
            <h5>Detalles del Servicio</h5>
            <hr />
            <ul>
              {appointmentAttributes?.map((item, i) => {
                return (
                  <li className="app-pr-point">
                    <span>{niceName(item) + ":"}</span>
                    <span>{checkValue(item)}</span>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="app-pr-content-box">
            <h5>Pago</h5>
            <hr />
            <ul>
              <li className="app-pr-point">
                <span>Subtotal:</span>
                <span>${appointmentData.subtotal}</span>
              </li>
              <li className="app-pr-point">
                <span>Total:</span>
                <span>${appointmentData.total}</span>
              </li>
              <li className="app-pr-point">
                <span>Metodo de pago:</span>
                <img
                  className="app-pr-content-box__img"
                  src={selectImage()}
                  alt=""
                />
              </li>
            </ul>
          </div>
        </div>

        <div className="share-app-pr">
          <h1>¡Comparte con tus conocidos!</h1>
          <p>
            ¡Ayudanos a expandir el nombre de Mopit! Siguenos y comparte tu
            experiencia en nuestras redes sociales
          </p>

          <div className="socialNetwork-resume-container">
            <a
              className=""
              href={instagramURL}
              target="_blank"
              rel="noreferrer"
            >
              <Button color="instagram">
                <Icon name="instagram" />
                Instagram
              </Button>
            </a>

            <a className="" href={facebookURL} target="_blank" rel="noreferrer">
              <Button color="facebook">
                <Icon name="facebook" />
                Facebook
              </Button>
            </a>
          </div>
          <Button
            active
            className="app-pr-btn-back"
            onClick={(e) => {
              setLoad(2);
            }}
          >
            Agendar otro servicio
          </Button>
        </div>
        <Footer />
      </>
    );
  }
};

export default AppoinmentPaymentResume;
