import { useState, useContext } from "react";
import { Form, Input, Button, Checkbox } from "semantic-ui-react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { validate, format } from "rut.js";

import { UserContext } from "../../context/user/UserContext";
import { AppointmentContext } from "../../context/appointment/AppointmentContext";

// Importación de metodos
import * as validateMethods from "../../hook/ValidateMethods"; //Pasar a hooks!!!!!!!!!!!!!!!!!!!!!!!!!!!

function AppointmentUserForm(props) {
  const navigate = useNavigate();
  const { route } = useOutletContext();
  // appointment context
  const appointment = useContext(AppointmentContext);
  // user context
  const user = useContext(UserContext);

  const [emailValid, setEmailValid] = useState(true);
  const [rutValid, setRutValid] = useState(true);
  const [ticket, setTicket] = useState(false);
  const [terms, setTerms] = useState(false);

  if (!appointment.checkDetails()) {
    navigate(route.path_start);
  }

  const formValid =
    !user.state.name ||
    !user.state.lastName ||
    !user.state.email ||
    !user.state.dni ||
    !user.state.phone ||
    !ticket ||
    !terms;

  const onSubmit = async () => {
    const rut_valid = validate(user.state.dni);
    const email_valid = validateMethods.validateEmail(user.state.email);
    setRutValid(rut_valid);
    setEmailValid(email_valid);

    if (rut_valid && email_valid) {
      user.updateDni(format(user.state.dni, { dots: false }));
      return navigate(route.path_next);
    }
  };

  return (
    <div>
      <div className="container">
        <Form unstackable>
          <Form.Group widths="equal">
            <Form.Field
              required
              id="form-input-control-nombre"
              control={Input}
              label="Nombres"
              name="firstName"
              value={user.state.name}
              onChange={(e) => user.updateName(e.target.value)}
              placeholder="Raúl Paolo"
            />
            <Form.Field
              required
              id="form-input-control-apellido"
              control={Input}
              label="Apellidos"
              name="lastName"
              value={user.state.lastName}
              onChange={(e) => user.updateLastName(e.target.value)}
              placeholder="Campos Aravena"
            />
            <Form.Field
              required
              id="form-input-control-rut"
              control={Input}
              label="RUT"
              name="rut"
              value={user.state.dni ? format(user.state.dni) : ""}
              onChange={(e) => user.updateDni(format(e.target.value))}
              error={
                rutValid
                  ? false
                  : {
                      content: "Por favor ingrese un RUT valido",
                    }
              }
              placeholder="12.345.678-9"
            />
          </Form.Group>

          <Form.Group>
            <Form.Field
              required
              id="form-input-control-email"
              control={Input}
              label="Correo electronico"
              name="email"
              value={user.state.email}
              onChange={(e) => user.updateEmail(e.target.value)}
              error={
                emailValid
                  ? false
                  : {
                      content: "Por favor ingrese un e-mail valido",
                    }
              }
              placeholder="correo@mail.cl"
              width={12}
            />
            <Form.Field
              required
              id="form-input-control-phone"
              control={Input}
              label="Celular"
              name="phone"
              value={user.state.phone}
              onChange={(e) => user.updatePhone(e.target.value)}
              placeholder="+56912345678"
              width={4}
            />
          </Form.Group>

          <Form.Field
            required
            name="ticket"
            control={Checkbox}
            onClick={() => setTicket(!ticket)}
            label="¿Es consciente que debe tener los productos de limpieza y utensilios necesarios en el lugar a realizar el servicio?"
          />
          <Form.Field
            required
            name="ticket"
            control={Checkbox}
            onClick={() => setTerms(!terms)}
            label={
              <label>
                Declaro que he leído y acepto los{" "}
                <a href="/Terminos-Condiciones" target="_blank">
                  Terminos y Condiciones
                </a>
              </label>
            }
          />
          <div className="appointment-client-buttons">
            <Button onClick={(e) => navigate(route.path_prev)} basic>Volver</Button>
            <Button disabled={formValid} onClick={onSubmit} primary>
              Siguiente
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default AppointmentUserForm;
