import React, { useEffect } from "react";
import BrowserTitleBar from "../components/general/BrowserTitleBar";
import Footer from "../components/general/Footer";
import Header from "../components/general/Header";
import ServiceBanner from "../components/services/ServiceBanner";
import ServiceCard from "../components/services/ServiceCard";
import ServicesButton from "../components/services/ServicesButton";


const Gardening = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <BrowserTitleBar title="Mopit - Jardinería" />
            <Header />
            <ServiceBanner title="¡Contrata tu servicio de Jardinería!" subtitle="Escoge un subservicio" />
            <ServicesButton />
            <ServiceCard subService={"gardening"} />
            <footer>
                <Footer />
            </footer>
        </>
    )
};

export default Gardening;