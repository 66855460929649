import React from 'react';
import { NavLink } from 'react-router-dom';

function renderButtons(){
    return(
        <div className="special-container">
            <div className="services-container">
                <div className="service-button">
                    <NavLink to="/limpieza" className={({ isActive }) => (isActive ? " service-button--active" : "")}>
                        <div className="service-button__text">
                            <h2>Limpieza</h2>
                        </div>
                    </NavLink>
                    <NavLink to="/gasfiteria" className={({ isActive }) => (isActive ? " service-button--active" : "")}>
                        <div className="service-button__text">
                            <h2>Gasfitería</h2>
                        </div>
                        
                    </NavLink>
                    <NavLink to="/jardineria" className={({ isActive }) => (isActive ? " service-button--active" : "")}>
                        <div className="service-button__text">
                            <h2>Jardinería</h2>
                        </div>
                    </NavLink>
                    <NavLink to="/electricidad" className={({ isActive }) => (isActive ? " service-button--active" : "")}>
                        <div className="service-button__text">
                            <h2>Electricidad</h2>
                        </div>
                    </NavLink>
                    <NavLink to="/cerrajeria" className={({ isActive }) => (isActive ? " service-button--active" : "")}>
                        <div className="service-button__text">
                            <h2>Cerrajería</h2>
                        </div>
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

const ServicesButton = () => {
  return (
    <>  
        {renderButtons()}
    </> 
  );
};

export default ServicesButton;