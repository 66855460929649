import React from 'react'
import { AiFillEdit, AiFillDelete } from 'react-icons/ai'
import ModifyAddress from './ModifyAddress'
import DeleteAddress from './DeleteAddress'

function parseAddress(address) {
    if (!address.dept)
        return `${address.street} ${address.homeNumber}, ${address.commune}, ${address.region}`
    return `${address.street} ${address.homeNumber}, depto ${address.dept}, ${address.commune}, ${address.region}`
} 

function UserAddress({id, key, address}) {

    const [editing, setEditing] = React.useState(false);
    const [deleting, setDeleting] = React.useState(false);

    const handleEdit = () => {
        setEditing(true);
    }

    const handleDelete = () => {
        setDeleting(true);
    }

    return (
        <>
            <div className="profile-address">
                <div className="profile-address__title">
                    <span>{address.alias}</span>
                </div>
                <div className="profile-address__address">
                    <span>{parseAddress(address)}</span>
                </div>
            </div>
            <div className="profile-address-buttons">
                    {/* <span className="profile-address-buttons__edit">
                        <AiFillEdit 
                        onClick={handleEdit}
                        />
                    </span> */}
                    <span className="profile-address-buttons__delete">
                        <AiFillDelete 
                        onClick={handleDelete}
                        />
                    </span>
            </div>
            {editing && <ModifyAddress id={id} closedModal={() => setEditing(false)} />}
            {deleting && <DeleteAddress id={id} closedModal={() => setDeleting(false)} />}
        </>
    )
}

export default UserAddress;