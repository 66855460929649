import { useEffect, useState } from 'react';


const TYPING_INTERVAL = 150
const PAUSE_TIME = 1000
const DELETING_INTERVAL = 50

export const useTyped = (service) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [phase, setPhase] = useState("Typing");
    const [typed, setTyped] = useState('');
    useEffect(() => {
        switch(phase){
            case "Typing":{
                const nextService = service[selectedIndex].slice(
                    0,
                    typed.length + 1
                );
        
                if (nextService === typed){
                    setPhase("Pausing")
                    return
                }
        
                const timeout = setTimeout(() => {
                    setTyped(nextService)
                }, TYPING_INTERVAL);

                return () => clearTimeout(timeout);
            }


            case "Deleting":{
                if (!typed){
                    const nextIndex = selectedIndex + 1
                    setSelectedIndex(service[nextIndex] ? nextIndex : 0)
                    setPhase("Typing")
                    return;
                }

                const nextRemaining = service[selectedIndex].slice(
                    0,
                    typed.length - 1
                )
        
                const timeout = setTimeout(() => {
                    setTyped(nextRemaining)
                }, DELETING_INTERVAL);

                return () => clearTimeout(timeout);

            }

            case "Pausing":
            default:
                const timeout = setTimeout(() => {
                    setPhase("Deleting")
                }, PAUSE_TIME)
                return () => clearTimeout(timeout)
        }

    }, [service, typed, selectedIndex, phase]);

    return typed;
};