import { createContext, useReducer, useEffect, useState } from "react";
import { CommuneReducer } from "./CommuneReducer";

// api
import axios from "axios";
import { communeAPI } from "../../api/Api";

export const CommuneContext = createContext();

export const CommuneProvider = ({ children }) => {
    const { REACT_APP_COMMUNE_KEY } = process.env;

    const [state, communeDispatch] = useReducer(CommuneReducer, []);

    const [communes, setCommunes] = useState([]);
    const [countries, setCountries] = useState([{ key: "cl", text: "Chile", value: "Chile" }]);
    const [regions, setRegions] = useState([{ key: "rm", text: "Metropolitana", value: "Metropolitana" }, { key: "va", text: "Valparaíso", value: "Valparaíso" }]);

    // get communes from api on page load
    useEffect(() => {
        const fetchCommunes = async () => {
            try {
                const communes = await axios.get(communeAPI, {
                    headers: {
                        "x-api-key": REACT_APP_COMMUNE_KEY,
                    },
                });
                communeDispatch({
                    type: "updateCommunes",
                    payload: communes.data,
                });
                // create options for select
                const communeOptions = {};
                communes.data.forEach((commune) => {
                    if (!communeOptions[commune.region]) {
                        communeOptions[commune.region] = [];
                    }
                    communeOptions[commune.region].push({
                        key: commune.id,
                        value: commune.id,
                        text: commune.name,
                    });
                });
                setCommunes(communeOptions);
            } catch (err) { 
                console.log(err);
            }
        };
        fetchCommunes();
    }, []);

    //dispatch
    const updateCommunes = (communes) =>
        communeDispatch({ type: "updateCommunes", payload: communes });
    
    return (
        <CommuneContext.Provider
        value={{
            state,
            communes,
            countries,
            regions,
            updateCommunes,
        }}
        >
        {children}
        </CommuneContext.Provider>
    );
};


