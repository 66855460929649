import React from 'react';

const ServiceBanner = ({title="insert a title", subtitle="insert a subtitle"}) => {
  return (
    <>
        <div className="service-banner">
            <h3 className="service-banner__title">{title}</h3>
            <p className="service-banner__subtitle">{subtitle}</p>
        </div>
    </>
  );
};

export default ServiceBanner;