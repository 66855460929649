import React from 'react'
import moment from 'moment';

function parseAddresss(appointment){
    if (appointment.dept)
        return `${appointment.street} ${appointment.homeNumber}, depto ${appointment.dept}, ${appointment.commune}, ${appointment.region}`
    return `${appointment.street} ${appointment.homeNumber}, ${appointment.commune}, ${appointment.region}`
}

function ProfileAppointment({id, appointment}) {

    const address = parseAddresss(appointment);
    const date = moment(appointment.startTimestamp).format('DD/MM/YYYY');

    if (moment(appointment.startTimestamp).isSameOrAfter(moment())){
        appointment.status = 'Pendiente';
    } else{
        appointment.status = 'Terminada';
    }

    return (
        <div className='profile-appointment'>
            <div className='profile-appointment__title'>    
                {date}
            </div>
            <div className='profile-appointment__title'>    
                {moment(appointment.startTimestamp).format('HH:mm')} Hrs
            </div>
            <div className='profile-appointment__address'>
                {address}
            </div>
            <div className={`profile-appointment__status ${appointment.status === 'Pendiente' ? 'profile-appointment__status--pending' : 'profile-appointment__status--finished'}`}>
                {appointment.status}
            </div>
        </div>
    )
}

export default ProfileAppointment