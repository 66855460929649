import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../context/user/UserContext";
import ProfileAddress from "./ProfileAddress";

// new address
import { AiOutlinePlusCircle } from "react-icons/ai";
import NewAddress from "../users/NewAddress";

function ProfileAddresses() {
    const user = useContext(UserContext);
    const addresses = user.state.addressList;

    const [newAddress, setNewAddress] = useState(false);

    return (
        <div className="addresses-container">
            <div className="profile-addresses">
                {addresses.map((address, index) => {
                    return (
                        <div className="profile-address-container">
                            <ProfileAddress
                                id={address.id}
                                key={index}
                                address={address}
                            />
                        </div>
                    );
                })}
                <div className="profile-addresses__new-address">
                    <AiOutlinePlusCircle
                        size="2em"
                        onClick={() => setNewAddress(true)}
                    />
                    <span onClick={() => setNewAddress(true)}>
                        Agregar dirección
                    </span>
                </div>
                {newAddress && (
                    <NewAddress closedModal={() => setNewAddress(false)} />
                )}
            </div>
        </div>
    );
}

export default ProfileAddresses;
