import React, { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import AppointmentHeader from "../components/appointment/AppointmentHeader";
import AppointmentSummary from "../components/appointment/AppointmentSummary";
import { AppointmentContext } from "../context/appointment/AppointmentContext";
import { useLocation } from "react-router-dom";
import { routes } from "../data/routes.json";

function Appointment({ service }) {
  const { updateServiceType } = useContext(AppointmentContext);

  const [step, setStep] = useState(1);
  const [route, setRoute] = useState({});
  const [redirectMessage, setRedirectMessage] = useState("");

  // get the pathname

  const pathname = useLocation().pathname;

  useEffect(() => {
    updateServiceType(service);
  }, [service]);

  useEffect(() => {
    // find the actual step
    const routeObject = routes.find((route) => route.path === pathname);
    setStep(routeObject.step);
    setRoute(routeObject);
  }, [pathname]);

  return (
    <div className="grid-service-container">
      <div className="grid-step">
        <AppointmentHeader actualStep={step} />
        <Outlet context={{ route, setRedirectMessage, service }} />
      </div>
      <AppointmentSummary
        service={service}
        actualStep={step}
        redirectMessage={redirectMessage}
        setRedirectMessage={setRedirectMessage}
      />
    </div>
  );
}

export default Appointment;
