import React, { useState, useContext } from 'react'
import { Modal, Form, Button, Select } from "semantic-ui-react";
import axios from 'axios';

import { UserContext } from '../../context/user/UserContext';
import { CommuneContext } from '../../context/commune/CommuneContext';
import { createLocationBody } from '../../hook/ApiMethods';
import { locationAPI } from '../../api/Api';

function NewAddress({closedModal}) {

    const {REACT_APP_LOCATION_KEY} = process.env;

    const [open, setOpen] = React.useState(true);
    const [address, setAddress] = useState({
            communeId: null,
            country: "Chile",
            region: "Metropolitana",
            street: "",
            homeNumber: "",
            dept: "",
            other: "",
            alias: "",
    });
    const user = useContext(UserContext);
    const {communes, regions, countries} = useContext(CommuneContext);

    const formIncomplete = 
        !address.alias || 
        !address.communeId || 
        !address.street || 
        !address.homeNumber || 
        !address.region;

    const handleCancel = () => {
        setOpen(false);
        closedModal();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // add address to database (wait for location id)
        const customerId = user.state.id;
        const body = createLocationBody(customerId, address);
        const response = await axios.post(locationAPI, body, {
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': REACT_APP_LOCATION_KEY
            }
        });
        // add address to context
        const newAddress = {
            ...address,
            locationId: response.data.id,
        }
        user.addAddressList(newAddress);
        closedModal();
    }

    return (
        <Modal 
            open={open}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            onClose={() => {
                setOpen(false);
                closedModal();
            }}
        >
            <Modal.Header>Nueva Dirección</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Form>
                        <Form.Input 
                            required
                            label='Alias'   
                            placeholder='Alias'
                            value={address.alias}
                            onChange={(e) => setAddress({...address, alias: e.target.value})}
                        />
                        <Form.Input 
                            required
                            label='Dirección'   
                            placeholder='Dirección'
                            value={address.street}
                            onChange={(e) => setAddress({...address, street: e.target.value})}
                        />
                        <Form.Input
                            required
                            label='Número'
                            placeholder='Número'
                            value={address.homeNumber}
                            onChange={(e) => setAddress({...address, homeNumber: e.target.value})}
                        />
                        <Form.Input
                            label='Dpto/Oficina'
                            placeholder='Dpto/Oficina'
                            value={address.dept}
                            onChange={(e) => setAddress({...address, dept: e.target.value})}
                        />
                        <Form.Input
                            label='Otro'
                            placeholder='Otro'
                            value={address.other}
                            onChange={(e) => setAddress({...address, other: e.target.value})}
                        />
                        <Form.Group widths="equal">
                            <Form.Field
                                required
                                control={Select}
                                options={countries}
                                label="País"
                                name="country"
                                value={"Chile"}
                                onChange={(e, { value }) =>
                                setAddress({ ...address, country: value })
                                }
                            />
                            <Form.Field
                                required
                                control={Select}
                                options={regions}
                                label="Región"
                                name="region"
                                value={address.region}
                                onChange={(e, { value }) =>
                                setAddress({ ...address, region: value })
                                }
                            />
                            <Form.Field
                                required
                                control={Select}
                                options={communes[address.region]}
                                label="Comuna"
                                name="commune"
                                value={address.communeId}
                                onChange={(e, { value }) =>
                                setAddress({ ...address, communeId: value, commune: communes[address.region].find(commune => commune.value === value).text })
                                }
                            />
                        </Form.Group>
                    </Form>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleCancel}>
                    Cancelar
                </Button>
                <Button disabled={formIncomplete} primary onClick={handleSubmit}>
                    Guardar
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default NewAddress