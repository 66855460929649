import { useContext, useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import infoConfiguration from "../data/infoConfiguration.json";
import { AppointmentContext } from "../context/appointment/AppointmentContext";
import { useNavigate } from "react-router-dom";
import { appointmentAPI, employeeAPI } from "../api/Api.js";

const useCalendar = (route) => {
  const navigate = useNavigate();

  const appointment = useContext(AppointmentContext);

  // data
  const [dataIsReturned, setDataIsReturned] = useState(false);

  // mopal
  const [show, setShow] = useState(false);
  const [blocked, setBlocked] = useState([]);
  const [modalMessage, setModalMessage] = useState("");
  const [type, setType] = useState(0);
  const [reservation, setReservation] = useState({
    id: 0,
    start: appointment.state.startTimestamp,
    end: appointment.state.endTimestamp,
    title: "RESERVADO",
    color: infoConfiguration.reserved_color,
    state: "pending"
  });

  const [reservations, setReservations] = useState([]);
  const [pointer, setPointer] = useState(0);

  const showMessage = (message, type) => {
    setModalMessage(message);
    setType(type);
    setShow(true);
  };

  // get data from server
  useEffect(() => {
    if (!appointment.checkDetails()) {
      navigate("/");
      return;
    }
    const fetchData = async () => {
      try {
        const response = await axios.get(appointmentAPI + "/unavailable", {
          params: {
            commune: appointment.state.address.commune,
          },
          headers: {
            "x-api-key": process.env.REACT_APP_APPOINTMENT_KEY,
          },
        });
        setBlocked(response.data);
        setDataIsReturned(true);
      } catch (error) {
        console.log(error.response.data.message);
        alert("La comuna seleccionada no se encuentra disponible actualmente");
        return navigate(route.path);
      }
    };
    fetchData();
  }, [appointment.state.address.commune]);

  const getLastMonth = () => {
    return moment().add(infoConfiguration.last_month, "month").format();
  };

  const getMonday = () => {
    return moment().startOf("isoWeek").format();
  };

  const verifyDay = (date) => {
    const splitDate = date.split('T')[0];
    for (let i = 0; i < reservations.length; i++) {
      if(reservations[i].start.split('T')[0] === splitDate) {
        return true;
      }
    }
    return false;
  }

  const verifyDate = async (date, dateString) => {
    if(reservations[pointer] != null) {
      if(reservations[pointer].state === "pending")
        return;
    }
    if(pointer < appointment.state.appointmentQuantity) {
      let minDate = moment().add(infoConfiguration.days_intervals, "days");
      let selectedDate = moment(date);
      let nowDate = moment();
      if(verifyDay(selectedDate.format())) {
        showMessage("No se pueden reservar dos servicios para el mismo día", 0);
      } else if (selectedDate < nowDate) {
        showMessage("No se puede reservar para una fecha pasada", 0);
      } else if (selectedDate < minDate) {
        showMessage(
          `Debe reservar con ${
            infoConfiguration.days_intervals * 24
          } horas de anticipación`,
          0
        );
      } else {
        // calculate finish date
        let finishDate = moment(date).add(appointment.state.duration, "minutes");
        let available = await isAvailable(selectedDate, finishDate);
        if (!available) {
          showMessage("La duración del servicio excede la disponibilidad", 0);
          return;
        }
        var event = {
          id: pointer,
          title: "POR RESERVAR",
          start: selectedDate.format(),
          end: finishDate.format(),
          color: infoConfiguration.to_book_color,
          state: "pending"
        };
        setReservations([...reservations, event]);
        let msg = `Estás reservando para el día ${selectedDate.format(
          "DD/MM/YYYY"
        )} desde las ${selectedDate.format(
          "HH:mm"
        )} hasta las ${finishDate.format("HH:mm")}`;
        
        setReservation(event);
        setModalMessage(msg);
        showMessage(msg, 1);
      }
    }
    
  };

  // Funcion para eliminar el antiguo horario escogido
  const removeObjectWithId = (arr, id) => {
    const objWithIdIndex = arr.findIndex((obj) => obj.id === id);
    if (objWithIdIndex > -1) {
      arr.splice(objWithIdIndex, 1);
    }
    return arr;
  } 

  const addMinutes= (date, minutes) => {
    return new Date(date.getTime() + minutes*60000);
  }

  const filterPassedTime = (time) => {
    const selectedDate = new Date(time);
    let tomorrowCurrentDate = new Date(new Date().setDate(new Date().getDate() + 1));
    let minDate = new Date(selectedDate);
    minDate.setHours(8, 0, 0);
    let maxDate = new Date(selectedDate);
    maxDate.setHours(20, 30, 0);

    const passedMaxHour = selectedDate.getTime() <= maxDate.getTime();
    const passedMinHour = selectedDate.getTime() >= minDate.getTime();

    let fitDate = addMinutes(maxDate, -appointment.state.duration -1)
    const fitsIn = selectedDate.getTime() < fitDate.getTime();
    let arrayBlocked = getArrayBlocked(selectedDate);

    return tomorrowCurrentDate.getTime() < selectedDate.getTime() && passedMaxHour && passedMinHour  && arrayBlocked && fitsIn;
  };




  // Funcion para ver si el horario esta bien.
  const verifyDate2 = async (date, index) => {
    let minDate = moment().add(infoConfiguration.days_intervals, "days");
    let selectedDate = moment(date);
    let nowDate = moment();
    if (selectedDate < nowDate) {
      console.log("No se puede reservar para una fecha pasada");
      return false
    } else if (selectedDate < minDate) {
      console.log(
        `Debe reservar con ${
          infoConfiguration.days_intervals * 24
        } horas de anticipación`,
        0
      );
      return false
    } else {
      let finishDate = moment(date).add(appointment.state.duration, "minutes");
      let available = await isAvailable(selectedDate, finishDate);
      if (!available) {
        console.log("La duración del servicio excede la disponibilidad");
        return false;
      }
      
      let updateReservations = removeObjectWithId(reservations, index);
      setReservations(updateReservations);
      var event = {
        id: index,
        title: "POR RESERVAR",
        start: selectedDate.format(),
        end: finishDate.format(),
        color: infoConfiguration.to_book_color,
        state: "pending"
      };
      setReservations([...reservations, event]);

      setReservation(event);
    }
    return true;
  }

  const to24HourFormat = (arrHour) => {
    let hours = arrHour[0].split(":");
    hours = hours.map(x => parseInt(x));
    if (arrHour[1] === "PM" && hours[0]!==12){
      hours[0]+=12;
    }
    return hours
  }
  const getArrayBlocked = (date) => {
    let isoDate= date.toISOString();
    let askedDate = new Date(isoDate);
    let dateDate = isoDate.split("T")[0];
    let validHour = true;
    for (let i=0; i<blocked.length; i++) {
      if (blocked[i].daysOfWeek) {
        let selectedDay = date.getDay();
        if (selectedDay === parseInt(blocked[i].daysOfWeek[0])){
          let event = blocked[i]

          let newDate = new Date(date);
          let startHourBlocked = new Date(date);
          let endHourBlocked = new Date(date);

          let startHourParts = event.startTime.split(" ");
          let hours = to24HourFormat(startHourParts);

          let endtHourParts = event.endTime.split(" ");
          let hours2 = to24HourFormat(endtHourParts);
          startHourBlocked.setHours(hours[0], hours[1], hours[2]);
          endHourBlocked.setHours(hours2[0], hours2[1], hours2[2]);
          startHourBlocked = addMinutes(startHourBlocked, -appointment.state.duration);
          let afterStartHour = startHourBlocked.getTime() < newDate.getTime();
          let beforeFinishHour = newDate.getTime() < endHourBlocked.getTime();
          let check = !(afterStartHour && beforeFinishHour);
          validHour = check
        }
      }
      else if (dateDate === blocked[i].start.split("T")[0]){
        // a crear las limites de hora
        let startHourBlocked = new Date(blocked[i].start);
        let finishHourBlocked = new Date(blocked[i].end);
        startHourBlocked = addMinutes(startHourBlocked, -appointment.state.duration);
        let afterStartHour = startHourBlocked.getTime() < askedDate.getTime();
        let beforeFinishHour = askedDate.getTime() < finishHourBlocked.getTime();
        let check = !(afterStartHour && beforeFinishHour);

        validHour = check
      }
      if (!validHour) {
        return validHour;
      }
    }
    return validHour;
  }

  const isAvailable = async (startReservation, endReservation) => {
    const [hour, minute] = infoConfiguration.business_hours.end_time.split(":");
    let end = moment(endReservation);
    let maxTime = moment(startReservation).set({ hour: hour, minute: minute });
    if (end > maxTime) {
      return false;
    }
    // check with blocked dates
    let blockedEventsSize = blocked.length;
    for (let i = 0; i < blockedEventsSize; i++) {
      let event = blocked[i];
      let startTime, endTime;
      if (event.daysOfWeek) {
        // check if the event is in the same day
        let day = moment(startReservation).format("d");
        if (!event.daysOfWeek.includes(day)) {
          continue;
        }
        // check if the reservation overlaps with a blocked date (recurrent, by weekly_blocked)
        startTime = moment(startReservation).set({
          hour: event.startTime.split(":")[0],
          minute: event.startTime.split(":")[1],
        });
        endTime = moment(startReservation).set({
          hour: event.endTime.split(":")[0],
          minute: event.endTime.split(":")[1],
        });
      } else {
        // check if the reservation overlaps with a blocked date (by other appointments)
        startTime = moment(event.start);
        endTime = moment(event.end);
      }
      if (startReservation < endTime && endReservation > startTime) {
        return false;
      }
    }
    // check if employee is available
    const employeeResp = await axios.get(employeeAPI + "/pick-employee", {
      params: {
        startTimestamp: moment(startReservation).format(),
        endTimestamp: moment(endReservation).format(),
        commune: appointment.state.address.commune,
      },
      headers: {
        "x-api-key": process.env.REACT_APP_EMPLOYEE_KEY,
      },
    });

    if (employeeResp.data.employeeId === null) {
      return false;
    }
    return true;
  };

  const cancelSelected = () => {
    setReservation({
      id: 0,
      title: "POR RESERVAR",
      start: "",
      end: "",
      color: infoConfiguration.to_book_color,
      state: "pending"
    });
    setReservations([...reservations.slice(0, pointer)]);
    appointment.updateDate("");
    appointment.updateStartTimestamp("");
    appointment.updateEndTimestamp("");
    setShow(false);
  };

  const confirmAppointment = async () => {

    if(pointer < appointment.state.appointmentQuantity){
      let auxReservation = {
        ...reservation,
        color: infoConfiguration.reserved_color,
        title: "RESERVADO",
        state: "complete"
      }
      setReservation((reservation) => ({
        ...reservation,
        color: infoConfiguration.reserved_color,
        title: "RESERVADO",
        state: "complete"
      }));

      let auxArray = [...reservations];
      auxArray[pointer] = auxReservation
      setReservations(auxArray);
      setPointer(pointer + 1);
      appointment.updateDate(moment(reservation.start).format("DD/MM/YYYY"));
      appointment.updateStartTimestamp(reservation.start);
      appointment.updateEndTimestamp(reservation.end);
      setShow(false);
      window.scrollTo(0, window.innerHeight);
    }

  };

  const deleteAppointment = (index) => {
    setReservations([...reservations.slice(0, index), ...reservations.slice(index + 1)]);
    setPointer(pointer -1);
  }

  const addTimes = () => {
    let aux = [];
    for(let i = 0 ; i < appointment.state.appointmentQuantity; i++){
      aux = [...aux, {
        rawStartTime: reservations[i].start,
        rawEndTime: reservations[i].end,
        date: moment(reservations[i].start).format("DD/MM/YYYY"),
        start: moment(reservations[i].start).format("HH:mm"),
        end: moment(reservations[i].end).format("HH:mm"),
      }];
    }
    appointment.updateTimes([...appointment.state.times, ...aux]);
  }

  const emptyTimes = () => {
    appointment.updateTimes([]);
  }

  return {
    appointment,
    cancelSelected,
    setShow,
    confirmAppointment,
    deleteAppointment,
    type,
    modalMessage,
    infoConfiguration,
    blocked,
    reservation,
    reservations,
    pointer,
    verifyDate,
    getMonday,
    getLastMonth,
    dataIsReturned,
    show,
    addTimes,
    emptyTimes,
    isAvailable,
    verifyDate2,
    getArrayBlocked,
    addMinutes,
    filterPassedTime
  };
};

export default useCalendar;
