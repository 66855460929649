import React from 'react';
import HeaderSection from '../general/HeaderSection';
import Image1 from '../../assets/images/1.jpg'
import Image2 from '../../assets/images/2.jpg'
import Image3 from '../../assets/images/3.jpg'

const HomeChoose = () => {
    return (
        <>
            
            <HeaderSection title='¿Por qué escogernos?' />
            <div className='workflow-row'>
                <div data-aos="fade-up" data-aos-duration="1000" className='workflow-card'>
                    <div className='workflow-item'>
                        <div className='workflow-item__img'>
                            <img src={Image1} alt='workflow'/>
                        </div>

                        <div className='workflow-info'>
                            <h5 className='workflow-info__title'>Facilidad al Agendar</h5>
                            <div className='workflow-info__container'>
                                <p className='workflow-info__text'>
                                    En sólo 3 pasos ya puedes agendar para que un Moper pueda realizar las labores de aseo del hogar.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div data-aos="fade-up" data-aos-duration="1000" className='workflow-card'>
                    <div className='workflow-item'>
                        <div className='workflow-item__img'>
                            <img src={Image2} alt='workflow'/>
                        </div>

                        <div className='workflow-info'>
                            <h5 className='workflow-info__title'>Responsabilidad Social</h5>
                            <div className='workflow-info__container'>
                                <p className='workflow-info__text'>
                                    Queremos ampliar las oportunidades laborales a personas que tienen experiencia realizando servicios de aseo. 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div data-aos="fade-up" data-aos-duration="1000" className='workflow-card'>
                    <div className='workflow-item'>
                        <div className='workflow-item__img'>
                            <img src={Image3} alt='workflow'/>
                        </div>

                        <div className='workflow-info'>
                            <h5 className='workflow-info__title'>Confianza Total</h5>
                            <div className='workflow-info__container'>
                                <p className='workflow-info__text'>
                                    Tenemos estrictos protocolos de selección para nuestros Mopers y una alta disponibilidad para atender tus dudas o problemas.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default HomeChoose;