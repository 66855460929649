import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context/user/UserContext";

import BrowserTitleBar from "../components/general/BrowserTitleBar";
import Footer from "../components/general/Footer";
import Header from "../components/general/Header";
import OverHeader from "../components/general/OverHeader";
import SignUp from "../components/users/SignUp";

const Register = () => {

    const { state, getSession } = useContext(UserContext);

    const navigate = useNavigate();

    // if the user is logged in, redirect to the home page
    useEffect(() => {
        getSession().then((session) => {
            navigate("/");
        });
    }, []);

    return (
        <>
            <BrowserTitleBar title="Mopit - Servicios a domicilio" />
            <OverHeader />
            <Header />
            <SignUp />
            <Footer />
        </>
    );  
}

export default Register;