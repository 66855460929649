import React, { useEffect, useState } from 'react'

const Carousel = ({ images, autoPlayy = false, showButtons = true }) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedImage, setSelectedImage] = useState(images[0].images);
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        if (autoPlayy || !showButtons) {
            const interval = setInterval(() => {
                selectNewImage(selectedIndex, images)
            }, 1000)
            return () => clearInterval(interval)
        }
    })


    const selectNewImage = (index, images, next = true) => {
        setLoaded(false)

        //las imagenenes cargan muy rapido
        setTimeout(() => {
            //extreme case condition, for the next and the previous
            const condition = next ? (selectedIndex < images.length - 1) : (selectedIndex > 0)

            //nextIndex are nested if's
            const nextIndex = next ? (condition ? selectedIndex + 1 : 0) : (condition ? selectedIndex - 1 : images.length - 1);

            setSelectedImage(images[nextIndex].images);
            setSelectedIndex(nextIndex)
        }, 500)
    }


    return (
        <>
            <img
                src={require(`../../assets/images/banner/${selectedImage}`).default}
                alt="banner1"
                className={`home__img ${loaded ? "loaded" : ""}`}
                onLoad={() => setLoaded(true)}
            />
        </>
    )
}

export default Carousel