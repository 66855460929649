import { createContext, useReducer, useEffect } from "react";
import { UserReducer } from "./UserReducer";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";

import camelcaseKeys from "camelcase-keys";

// api
import { customerAPI } from "../../api/Api";
import axios from "axios";

// for guest users, set the state to default
const defaultUserState = {
  id: null,
  name: "",
  lastName: "",
  dni: "",
  email: "",
  phone: "",
  addressList: [],
};

export const UserContext = createContext();

async function getAddressList(customerId) {
    const { REACT_APP_CUSTOMER_KEY } = process.env;
    const response = await axios.get(`${customerAPI}/${customerId}/location`, {
        headers: {
            'x-api-key': REACT_APP_CUSTOMER_KEY
        }
    });
    // convert response keys to camelcase
    return camelcaseKeys(response.data, { deep: true });
}

export const UserProvider = ({ children }) => {

  const navigate = useNavigate();

  const [state, userDispatch] = useReducer(UserReducer, defaultUserState);

  // check if the user is logged in when refresh the page
  useEffect(() => {
    const user = Auth.currentAuthenticatedUser().then(user => {
      if (user) {
        updateDni(user.attributes["custom:dni"]);
        updateName(user.attributes.given_name);
        updateLastName(user.attributes.family_name);
        updateEmail(user.attributes.email);
        updatePhone(user.attributes.phone_number);
        updateId(parseInt(user.attributes["custom:customer_id"]));
        // get the address list
        const address = getAddressList(user.attributes["custom:customer_id"]).then(address => {
            updateAddressList(address);
        });
      }
    }).catch(err => {
      // user not authenticated
    });
  }, [state.id]);

  // auth
  const authenticate = async (email, password) => {
    try {
      const user = await Auth.signIn(email, password);
      const userData = await Auth.currentUserInfo();
      updateDni(userData.attributes["custom:dni"]);
      updateName(userData.attributes.given_name);
      updateLastName(userData.attributes.family_name);
      updateEmail(userData.attributes.email);
      updatePhone(userData.attributes.phone_number);
      updateId(userData.attributes["custom:customer_id"]);
      return user;
    } catch (error) {
      return error;
    }
  }

  const getSession = async () => {
    return await new Promise((resolve, reject) => {
      let cognitoUser = Auth.currentAuthenticatedUser().then(user => {
        if (user) {
          resolve(user);
        } else {
          reject(null);
        }
      }).catch(error => {
        reject(error);
      });
    });
  }

  const logout = async () => {
      await Auth.signOut();
      userDispatch({ type: "updateState", payload: defaultUserState });
      navigate("/");
  }

  // user data
  const updateName = (name) => {
    userDispatch({ type: "updateName", payload: name });
  };
  const updateLastName = (lastName) => {
    userDispatch({ type: "updateLastName", payload: lastName });
  };
  const updateDni = (dni) => {
    userDispatch({ type: "updateDni", payload: dni });
  };
  const updateEmail = (email) => {
    userDispatch({ type: "updateEmail", payload: email });
  };
  const updatePhone = (phone) => {
    userDispatch({ type: "updatePhone", payload: phone });
  };
  const updateAddressList = (addressList) => {
    userDispatch({ type: "updateAddressList", payload: addressList });
  };
  const addAddressList = (address) => {
    userDispatch({ type: "addAddressList", payload: address });
  };
  const updateId = (id) => {
    userDispatch({ type: "updateId", payload: id });
  };
  const deleteAddress = (locationId) => {
    userDispatch({ type: "deleteAddress", payload: locationId });
  };
  const updateAddress = (address) => {  
    userDispatch({ type: "updateAddress", payload: address });
  };
  return (
    <UserContext.Provider
      value={{
        state,
        updateName,
        updateLastName,
        updateDni,
        updateEmail,
        updatePhone,
        updateId,
        updateAddressList,
        addAddressList,
        authenticate,
        getSession,
        logout,
        deleteAddress,
        updateAddress
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
