import { RoutesLinks } from './routes/RoutesLinks';
import './scss/main.scss'
import { AppointmentProvider } from './context/appointment/AppointmentContext'
import { UserProvider } from './context/user/UserContext';
import { CommuneProvider } from './context/commune/CommuneContext';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Amplify from 'aws-amplify';

Amplify.configure({
    Auth: {
        region: process.env.REACT_APP_COGNITO_REGION,
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    }
});

function usePageViewsGA() {
    const { REACT_APP_GA_CODE } = process.env;
	let location = useLocation();
	useEffect(() => {
        ReactGA.initialize(REACT_APP_GA_CODE);
		ReactGA.set({ page: location.pathname });
		ReactGA.pageview(location.pathname);
	}, [location]);
}

function usePageViewFP() {
    const { REACT_APP_FP_CODE } = process.env;
    let location = useLocation();
    const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
    };
    useEffect(() => {
        ReactPixel.init(REACT_APP_FP_CODE, options);
        ReactPixel.pageView();
    }, [location]);
}


function App() {
    usePageViewsGA();
    usePageViewFP();
    return (
        <CommuneProvider>
            <UserProvider>
                <AppointmentProvider>
                    <RoutesLinks/>
                </AppointmentProvider>
            </UserProvider>
        </CommuneProvider>
    );
}

export default App;