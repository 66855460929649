import React, { useState, useEffect } from 'react';
import BrowserTitleBar from '../components/general/BrowserTitleBar';
import Footer from '../components/general/Footer';
import Header from '../components/general/Header';

const FAQ = () => {

    const info = require('../data/faq.json').faq;

    const [selected, setSelected] = useState(null)
    const toggle = i => {
        if(selected === i){
            return setSelected(null)
        }
        setSelected(i)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
    return (
        <>
            <BrowserTitleBar title='Mopit - Preguntas Frecuentes'/>
            <Header/>
            <div className="container">
                <div className="faq-container">
                <h1>Preguntas frecuentes</h1>
                    <div className="accordion">
                        {
                            info.map((item, i) => {
                                return(
                                    <div>
                                        <h2 className='faq-subtitle-style'>{item.title}</h2>
                                        {
                                            item.data.map((data, j) => {
                                                return(
                                                    <div className='item-accordion-container' onClick={() => toggle(j) }>
                                                        <div className='item-accordion-container__question'>
                                                            <h3>{data.question}</h3>
                                                            <span>{selected === j ? '-' : '+'}</span>
                                                        </div>
                                                        <div 
                                                            className={selected === j ? 'item-accordion-container__content item-accordion-container__content--show' : 'item-accordion-container__content'}
                                                            onClick={() => toggle(j)}
                                                        >
                                                            <p className='spaces'>{data.answer}</p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <footer>
                    <Footer />
            </footer>
        </>

    );
};

export default FAQ;
