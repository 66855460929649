import React, { useEffect } from 'react'
import Header from '../components/general/Header';
import { wspURL } from '../api/Api'
import Footer from '../components/general/Footer';
import BrowserTitleBar from '../components/general/BrowserTitleBar';
import Email from '../assets/images/workWithUs/wwu-email.png'

//images
import WorkWithUs1 from '../assets/images/workWithUs/wwus1.jpg';
import WorkWithUs2 from '../assets/images/workWithUs/wwus2.jpg';
import WorkWithUs3 from '../assets/images/workWithUs/wwus3.jpg';
import WorkWithUs4 from '../assets/images/workWithUs/wwus4.jpg';
import WorkWithUs5 from '../assets/images/workWithUs/wwus5.jpg';

//aos
import Aos from 'aos';
import 'aos/dist/aos.css';

const WorkWithUS = () => {

    const data = require('../data/workWithUs.json');

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        Aos.init({ duration: 1000, once: true });
    }, []);

    const imageStep = (title) => {
        switch (title) {
            case 0:
                return WorkWithUs1
            case 1:
                return WorkWithUs2
            case 2:
                return WorkWithUs3
            case 3:
                return WorkWithUs4
            case 4:
                return WorkWithUs5
            default:
                return;
        }
    }

    return (
        <>
            <BrowserTitleBar title="Mopit - Trabaja con nosotros" />
            <Header />
            <div className='wwu-box'>
                <h1 className='wwu-box__title'>¡Trabaja con nosotros!</h1>
                <div className='wwu-box__text'>
                    <p className='wwu-box__text--color'>¿Quieres trabajar con nosotros? ¡Super! envianos un correo a&nbsp;
                    <span>
                        <a href="mailto:contacto@mopit.cl">contacto@mopit.cl</a>
                    </span>
                    &nbsp;con la siguiente información</p>
                </div>
            </div>
            <div data-aos="fade-up" className="container">
                {
                    data.steps.map((item, i) => {
                        return (
                            <div className='wwu-steps'>
                                <div className="step-circle-text">
                                    <di className='wwu-circular'>
                                        <div className='wwu-circular__number'>
                                            <h1>{i + 1}</h1>
                                        </div>
                                    </di>
                                    <div className='wwu-text-container'>
                                        <h1 className='wwu-circular__text'>{item.title}</h1>
                                        <p className='wwu-steps__text'>{item.text}</p>
                                    </div>
                                </div>
                                <img src={imageStep(i)} alt="image-step" />
                            </div>
                        );
                    })
                }
            </div>

            <a data-aos="zoom-in" className="wwu-mail-container" href="mailto:contacto@mopit.cl">
                <div className="wwu-mail-content">
                    <h5>Envíanos tus datos aquí</h5>
                    <img src={Email} alt="email" />
                </div>
            </a>

            <div data-aos="zoom-out" className='wwu-help'>
                <h1>Si tienes alguna duda, envíanos un mensaje a nuestro&nbsp;</h1>
                <a href={wspURL} target="_blank"><h1 className='wwu-help__wsp'>Whatsapp</h1></a>
            </div>

            <footer>
                <Footer />
            </footer>
        </>
    )
}

export default WorkWithUS;
