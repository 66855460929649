import React, {useContext} from 'react'
import { Modal, Button } from 'semantic-ui-react'
import { UserContext } from '../../context/user/UserContext';

import axios from 'axios';
import { locationAPI } from '../../api/Api';

function DeleteAddress({id, closedModal}) {
    const { REACT_APP_LOCATION_KEY } = process.env;

    const { state, deleteAddress } = useContext(UserContext);
    const address = state.addressList.find(address => address.id === id);

    const [open, setOpen] = React.useState(true);

    const handleDelete = async () => {
        deleteAddress(id);
        const response = await axios.put(`${locationAPI}/${id}/disable`, {}, {
            headers: {
                'x-api-key': REACT_APP_LOCATION_KEY
            }
        });
        setOpen(false);
        closedModal();
    }

    return (
        <Modal
            open={open}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            onClose={() => {
                setOpen(false);
                closedModal();
            }}
        >
            <Modal.Header>Eliminar Dirección</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <p>
                        ¿Estás seguro que deseas eliminar la dirección <b>{address.alias}</b>?
                    </p>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    negative
                    content='Eliminar'
                    onClick={handleDelete}
                />
                <Button
                    content='Cancelar'
                    onClick={() => {
                        setOpen(false);
                        closedModal();
                    }}
                />
            </Modal.Actions>
        </Modal>
  )
}

export default DeleteAddress