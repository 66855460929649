import React, {useContext} from 'react'
import { Modal, Form, Select, Button} from 'semantic-ui-react'
import { UserContext } from '../../context/user/UserContext';
import { CommuneContext } from '../../context/commune/CommuneContext';

// api
import axios from 'axios';
import { locationAPI } from '../../api/Api';

function ModifyAddress({id, closedModal}) {
    const { REACT_APP_LOCATION_KEY } = process.env;

    const { state, updateAddress } = useContext(UserContext);
    const { communes, regions, countries } = useContext(CommuneContext);

    const [open, setOpen] = React.useState(true);

    const address = state.addressList.find(address => address.id === id);

    const [editedAddress, setEditedAddress] = React.useState(address);

    const formIncomplete = 
        !editedAddress.alias || 
        !editedAddress.communeId || 
        !editedAddress.street || 
        !editedAddress.homeNumber || 
        !editedAddress.region

    const handleCancel = () => {
        setOpen(false);
        closedModal();
    };

    const handleSubmit = async () => {
        updateAddress(editedAddress);
        // update address in database
        const response = await axios.put(`${locationAPI}/${id}`, editedAddress, {
            headers: {
                'x-api-key': REACT_APP_LOCATION_KEY
            }
        });
        console.log(response);
        setOpen(false);
        closedModal();
    };

    return (
        <Modal 
            open={open}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            onClose={() => {
                setOpen(false);
                closedModal();
            }}
        >
            <Modal.Header>Editar Dirección</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Form>
                        <Form.Input 
                            required
                            label='Alias'   
                            placeholder='Alias'
                            value={editedAddress.alias}
                            onChange={(e) => setEditedAddress({...editedAddress, alias: e.target.value})}
                        />
                        <Form.Input 
                            required
                            label='Dirección'   
                            placeholder='Dirección'
                            value={editedAddress.street}
                            onChange={(e) => setEditedAddress({...editedAddress, street: e.target.value})}
                        />
                        <Form.Input
                            required
                            label='Número'
                            placeholder='Número'
                            value={editedAddress.homeNumber}
                            onChange={(e) => setEditedAddress({...editedAddress, homeNumber: e.target.value})}
                        />
                        <Form.Input
                            label='Dpto/Oficina'
                            placeholder='Dpto/Oficina'
                            value={editedAddress.dept}
                            onChange={(e) => setEditedAddress({...editedAddress, dept: e.target.value})}
                        />
                        <Form.Input
                            label='Otro'
                            placeholder='Otro'
                            value={editedAddress.other}
                            onChange={(e) => setEditedAddress({...editedAddress, other: e.target.value})}
                        />
                        <Form.Group widths="equal">
                            <Form.Field
                                required
                                control={Select}
                                options={countries}
                                label="País"
                                name="country"
                                value={"Chile"}
                                onChange={(e, { value }) =>
                                setEditedAddress({ ...editedAddress, country: value })
                                }
                            />
                            <Form.Field
                                required
                                control={Select}
                                options={regions}
                                label="Región"
                                name="region"
                                placeholder={editedAddress.region}
                                value={editedAddress.region}
                                onChange={(e, { value }) =>
                                setEditedAddress({ ...editedAddress, region: value })
                                }
                            />
                            <Form.Field
                                required
                                control={Select}
                                options={communes[editedAddress.region]}
                                label="Comuna"
                                name="commune"
                                value={editedAddress.communeId}
                                onChange={(e, { value }) =>
                                setEditedAddress({ ...editedAddress, communeId: value, commune: communes[editedAddress.region].find(commune => commune.value === value).text })
                                }
                            />
                        </Form.Group>
                    </Form>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleCancel}>
                    Cancelar
                </Button>
                <Button disabled={formIncomplete} primary onClick={handleSubmit}>
                    Guardar
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default ModifyAddress