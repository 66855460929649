import React, { useEffect } from 'react';
import Header from '../components/general/Header';
import principalImg from '../assets/images/houseAesthetic.jpg'
import visionImage from '../assets/images/cleaning-table.jpg'
import missionImage from '../assets/images/cleaning-mission.jpg'
import mopitIcon from '../assets/images/mopit_icon.png'
import Footer from '../components/general/Footer';
import BrowserTitleBar from '../components/general/BrowserTitleBar';

const AboutUs = () => {

    const data = require('../data/aboutUs.json');

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>      
            <BrowserTitleBar title="Mopit - Sobre nosotros"/>
            <Header/>

            <div className="about-us-square">
                <div className='container'>
                    <div className='about-us-square__title-container'>
                        <h1>Sobre Mopit</h1>
                        <img src={mopitIcon}  alt='fb' className='about-us-square__title-container--icon'/>
                    </div>                 
                    <p className='about-us-square__text-box'>{data.about_us}</p>
                </div>
            </div>
            <div className='about-us-square-img'>
                <img className='about-us-square-img__image-size' src={principalImg} alt="" />
            </div>
            <div className='about-us-box-vision'>
                <div>
                    <img src={visionImage} alt="" className='about-us-box-vision__image'/>
                </div>
                <div className='about-us-box-vision__text'>
                    <h1 className=''>Nuestra visión</h1>
                    <p className='about-us-box-vision__text--style'>
                        {data.vision}
                    </p>
                </div>
            </div>

            <div className='about-us-box-mission'>
                <div>
                    <img src={missionImage} alt="" className='about-us-box-mission__image'/>
                </div>
                <div className='about-us-box-mission__text'>
                    <h1 className=''>Nuestra misión</h1>
                    <p className='about-us-box-mission__text--style'>
                        {data.our_mision}
                    </p>
                </div>
            </div>
            

            <div className='about-us-box'>
                <div className="container about-us-box__information spaces">
                    <h1>Nuestra historia</h1>
                    <p>
                        {data.information.our_history.text}
                    </p>
                </div>
                <div className="container about-us-box__information spaces">
                    <h1>Nuestros valores</h1>
                    <p>
                        {data.information.our_values.text}
                    </p>
                    <ul className='about-us-box__information--ul-margin'>
                        {data.information.our_values.list.map((item, i) => {
                            return(
                                <li className='about-us-box__information--li-margin'>{item.title}: {item.info}</li>
                            );
                        })}
                    </ul>
                </div>
            </div>

            <footer>
                <Footer />
            </footer>
        </>

    );
};

export default AboutUs;
