import { useState, useContext, useEffect } from "react";
import { Form, Input, Button, Grid } from "semantic-ui-react";
import { useNavigate, useOutletContext } from "react-router-dom";

function AppointmentAddSubstract(props) {
    const [price, setPrice] = useState(0);
    const [marks, setMarks] = useState(new Array(props.obj.options.length - 1).fill(false));

    useEffect(()=> {
        props.appointment.updateOptionalDetails({ [props.obj.id]:'0' })
      }, []);

    const getKey = (value, obj) => {
        for (let i in obj.options) {
          if (obj.options[i].value === value) {
            return obj.options[i].key
          }
        }
        return ""
      }
    
    //Resetea los botones dependiendo de que se escoge
    const clickHandler = (value, ind) => {
        // Color handler
        let auxArray = new Array(props.obj.options.length - 1).fill(false);
        auxArray[ind] = true;
        setMarks(auxArray);

        if (value === price){
            setMarks(new Array(props.obj.options.length - 1).fill(false))
            value = 0;
        }
        // Price handler
        setPrice(value);  
        props.onChangeServices(props.obj, value);
        let v = getKey(value, props.obj);
        props.appointment.updateDetails({ [props.obj.id + "_value"]: value });
        props.appointment.updateOptionalDetails({ [props.obj.id]: v })
      
    }
    const createButtons = () => {
        return props.obj.options.map((obj, index) => {
            if (index!==0){
                let valor = obj.value;
                if(index === 1){
                    return (
                        <Button 
                            active={marks[index]} 
                            color={marks[index] ? 'blue' : null}
                            onClick={() => clickHandler(valor, index)}>{obj.text}</Button>     
                )
                }
                else{
                    return (
                        <>
                            <Button.Or text="o"/>
                            <Button 
                                active={marks[index]} 
                                color={marks[index] ? 'blue' : null}
                                onClick={() => clickHandler(valor, index)}>{obj.text}</Button>
                        </>
                    )
                }
                
            }
        })
    }
    return (
            <div  >
                <Button.Group>
                    <> {createButtons()} </>
                </Button.Group>
                {price != 0 && (<p>Valor: ${price}</p>)}
            </div>
    )
}
export default AppointmentAddSubstract;