import { useState, useContext, useEffect } from "react";
import { AppointmentContext } from "../../context/appointment/AppointmentContext";
import { Form, Input, Button, Select, Icon, Popup } from "semantic-ui-react";
import { useNavigate, useOutletContext } from "react-router-dom";
import * as constClass from "../../api/Api";
import AppointmentPropertyTypeSelector from "./AppointmentPropertyTypeSelector";

function AppointmentServiceForm(props) {
  const navigate = useNavigate();

  const { route, service } = useOutletContext();

  // general-cleaning-service

  const { updateDetails, updatePropertyType, updatePrice,updateAllPrices, updateDuration, getDetail, checkDetails, checkDetails2, updateUnitPrice, state } =
    useContext(AppointmentContext);
    
  const { REACT_APP_PRICING_CALCULATOR_API_KEY } = process.env;

  const [maxTimeError, setMaxTimeError] = useState("");

  const formValid = !checkDetails2();

  useEffect(() => {
    sessionStorage.setItem("pop_return_status", "true");
  }, [])

  async function getWorkInfo(url = "", data = {}) {
    let response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": REACT_APP_PRICING_CALCULATOR_API_KEY,
      },
      body: JSON.stringify(data),
    });
    let json = await response.json();
    return json;
  }

  const onSubmit = (e) => {
    e.preventDefault();
    // change this body to generalize
    const body = {
      bedrooms: getDetail("rooms"),
      bathrooms: getDetail("bathrooms"),
      size: getDetail("size"),
      dirtiness: getDetail("dirtiness"),
      property_type: getDetail("propertyType")
    };
    getWorkInfo(constClass.pricingCalculatorAPI, body).then((data) => {
      if (data.statusCode === 200) {
        console.log(data);
        updateUnitPrice(data.body.values.price);
        updatePrice(data.body.values.price);
        updateAllPrices(JSON.parse(data.body.values.allPrices));
        updateDuration(data.body.values.durationMinutes);
        // redirect to next step
        navigate(route.path_next);
      } else {
        setMaxTimeError(data.body.userMessage);
      }
    });
  };

  const infoServices = require("../../data/services.json").services;
  const infoServiceForm = infoServices[service].form;

  function renderServiceForm() {
    return (
      <div className="appointment-house-container">
        <Form unstackable>
          {infoServiceForm.map((item, index) => {
            switch (item.type) {
              case "number":
                return (
                  <Form.Field required key={index}>
                    <label>{item.label}</label>
                    <Input
                      style={{ textAlign: "center" }}
                      type="number"
                      min="0"
                      max="5"
                      value={Number(getDetail(item.id)).toString() || ""}
                      onChange={(e, { value, min, max }) =>
                        updateDetails({ [item.id]: Math.max(Number(min), Math.min(Number(max), Number(Math.round(value))))})
                      }
                    />
                  </Form.Field>
                );
              case "dropdown":
                return (
                  <Form.Field required key={index}>
                    <label>{item.label}</label>
                    <Select
                      placeholder="Seleccione una opción"
                      options={item.options}
                      value={getDetail(item.id) || ""}
                      onChange={(e, { value }) =>
                        updateDetails({ [item.id]: value })
                      }
                    />
                  </Form.Field>
                );
              default:
                return null;
            }
          })}
          <Form.Field required>
            <label>Tipo de vivienda </label>
            <AppointmentPropertyTypeSelector
              updatePropertyType={updatePropertyType}  
            />
          </Form.Field>
        </Form>
      </div>
    );
  }

  return (
    <div className="container">
      
      
      {renderServiceForm()}
      
      <div className="appointment-price-error">{maxTimeError}</div>
      <div className="appointment-service-icon">
        <Popup
          trigger={<Icon name='question' circular inverted color='blue' size='small' />}
          wide='very'
          content='Los atributos seleccionados determinarán el tiempo estimado que trabajará el moper en el domicilio. Por favor, ingresa con certeza la información requerida.'
          position='bottom center'
        />
      </div>
      <div className="appointment-next-button">
        <Button type="submit" onClick={() => navigate(route.path_prev)} basic>
          Volver
        </Button>
        <Button disabled={formValid} type="submit" onClick={onSubmit} primary>
          Siguiente
        </Button>
      </div>
    </div>
  );
}

export default AppointmentServiceForm;
