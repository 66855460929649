import {
  mercadopagoTestingAPI,
  webpayTestingAPI,
  paymentAPI,
  mainPageURL
} from "../api/Api";
import { Navigate } from "react-router-dom";
import { createMercadoPagoBody, createWebpayBody } from "./ApiMethods";
import axios from "axios";

export async function Pay(appIDs, price, email, paymentMethod) {
  const {
    REACT_APP_MP_API_KEY,
    REACT_APP_WEBPAY_API_KEY,
    REACT_APP_PAYMENT_KEY,
  } = process.env;
  try {
    switch (paymentMethod) {
      case "MercadoPago":
        const mercadopagoBody = createMercadoPagoBody(
          price,
          email,
          appIDs
        );
        const lambdaResponse = await axios.post(
          mercadopagoTestingAPI,
          mercadopagoBody,
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": REACT_APP_MP_API_KEY,
            },
          }
        );
        const mercadopagoLink = lambdaResponse.data.body;
        const params = new URL(mercadopagoLink).searchParams;
        const paymentId = params.get("pref_id");
        // create payment_id in db
        for (let i = 0; i < appIDs.length; i++) {
          const newPayment = await axios.post(
            `${paymentAPI}/${appIDs[i]}`,
            {
              paymentMethod: paymentMethod,
              paymentId: paymentId,
            },
            {
              headers: {
                "Content-Type": "application/json",
                "x-api-key": REACT_APP_PAYMENT_KEY,
              },
            }
          );
        }
        
        // navigate to mercadopago link
        return (window.location.href = mercadopagoLink);
      case "Webpay":
        const webpayBody = createWebpayBody(appIDs, price);
        const webpayLambdaResponse = await axios.post(
          webpayTestingAPI,
          webpayBody,
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": REACT_APP_WEBPAY_API_KEY,
            },
          }
        );
        for (let i = 0; i < appIDs.length; i++) {
          const newWebpayPayment = await axios.post(
            `${paymentAPI}/${appIDs[i]}`,
            {
              paymentMethod: paymentMethod,
              paymentId: webpayLambdaResponse.data.body.token,
            },
            {
              headers: {
                "Content-Type": "application/json",
                "x-api-key": REACT_APP_PAYMENT_KEY,
              },
            }
          );
        }
        let webpayLink = `${webpayLambdaResponse.data.body.url}?token_ws=${webpayLambdaResponse.data.body.token}`;
        return (window.location.href = webpayLink);
      case "TransferenciaBancaria": {
        let appIDsString = appIDs.join("x");
        return (window.location.href = `${mainPageURL}/datos-transferencia?appointment_id=${appIDsString}&price=${price}`);
      }
      default:
        return null;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
}
