import React, { useContext, useState, useEffect } from 'react'
import { Form, Select, Button, Message, Grid , Icon, Popup} from 'semantic-ui-react'
import { useNavigate, useOutletContext } from "react-router-dom";
import { AppointmentContext } from "../../context/appointment/AppointmentContext";
import AppointmentOnOff from "./AppointmentOnOff";
import AppontmentAddSubstract from "./AppointemntAddSubstract";
import AppointmentDropDown from "./AppointmentDropDown";
let optionalServices = require("../../data/cardOptionalServices.json");

const AppointmentOptionalServices = () => {
    const max_width = 800;
    const navigate = useNavigate();
    const appointment = useContext(AppointmentContext);
    const { route } = useOutletContext();
    const [quantity, setQuantity] = useState(appointment.state.appointmentQuantity);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    useEffect(() => {
        window.addEventListener("resize", function() {
            setWindowWidth(window.innerWidth)
        })
    }, [])

    // estado para saber cual servicio extra está eligiendo
    const [services, setService] = useState({
      planchado: 0, 
      limpiezaVentanas: 0,
      limpiezaMicroondas: 0,
      limpiezaRefrigerador: 0,
      limpiezaHorno: 0,
      limpiezaHornoRefriMicroondas: 0
    });

    useEffect(()=> {
      const aux = {
        planchado: 0, 
        limpiezaVentanas: 0,
        limpiezaMicroondas: 0,
        limpiezaRefrigerador: 0,
        limpiezaHorno: 0,
        limpiezaHornoRefriMicroondas: 0
      }
      appointment.addOptionalServices(aux);
    }, []);

    if (!appointment.checkDetails()) {
        navigate(route.path_start);
    }
    
    const onSubmit = (quantity) => {
        let total = 0;
        let values = Object.values(services);
        for(let i = 0; i<values.length; i++){
          total+=values[i];
        }
        appointment.addPrice(total);
        appointment.addOptionalServices(services);
        navigate(route.path_next);
    }
    const onChangeServices = (obj, value) => {
      setService(prevFormData => {
        return {
          ...prevFormData,
          [obj.id]: value
        }
      })
    }

    const ShowOptions = () => {
        
        let optionalServicesData = optionalServices.optionalServicesCard;
        return optionalServicesData.map((obj, index) => {
            
            if (obj.type == "add-substract"){
              if (windowWidth > max_width){
                return(
                  <Form.Field>
                      <div className='appointment-opt-serv-price-box' >
                            <Message className='appointment-opt-serv-price-box__color'>
                                
                                <Grid columns="equal">
                                  <Grid.Row>
                                    <Grid.Column></Grid.Column>
                                    <Grid.Column width={12}>
                                      <h3>{obj.title}</h3>
                                    </Grid.Column>
                                    <Grid.Column>
                                    <Popup
                                      trigger={<Icon name='info'  circular inverted color='blue' size='tiny' />}
                                      wide='very'
                                      content={obj.restrictions}
                                      position='top right'
                                    />
                                    </Grid.Column>
                                  </Grid.Row>
                                </Grid>
                                <p>{obj.description}</p>
                                <label>{obj.label}</label>
                                <AppontmentAddSubstract
                                  appointment={appointment}
                                  onChangeServices={onChangeServices}
                                  obj={obj}
                                />
                            </Message>
                      </div> 
                  </Form.Field>
                  )   
              }
              else {
                return(
                  <Form.Field>
                      <div className='appointment-opt-serv-price-box' >
                            <Message className='appointment-opt-serv-price-box__color'>
                                <Grid columns="equal">
                                  <Grid.Row>
                                    <Grid.Column></Grid.Column>
                                    <Grid.Column width={10}>
                                      <h3>{obj.title}</h3>
                                    </Grid.Column>
                                    <Grid.Column>
                                    <Popup
                                      trigger={<Icon name='info'  circular inverted color='blue' size='tiny' />}
                                      wide='very'
                                      content={obj.restrictions}
                                      position='top right'
                                    />
                                    </Grid.Column>
                                  </Grid.Row>
                                </Grid>
                                <p>{obj.description}</p>
                                <label>{obj.label}</label>
                                <AppointmentDropDown
                                  appointment={appointment}
                                  onChangeServices={onChangeServices}
                                  obj={obj}
                                />
                            </Message>
                      </div> 
                  </Form.Field>
                )
              }
            }
            else if (obj.type == "on-off") {
              return(
                <Form.Field>
                    <div className='appointment-opt-serv-price-box' >
                        <Message className='appointment-opt-serv-price-box__color'>
                        <Grid columns="equal">
                                  <Grid.Row>
                                    <Grid.Column></Grid.Column>
                                    <Grid.Column width={10}>
                                      <h3 className='appointment-opt-serv-center-text'>{obj.title}</h3>
                                    </Grid.Column>
                                    <Grid.Column>
                                    <Popup
                                      trigger={<Icon name='info'  circular inverted color='blue' size='tiny' />}
                                      wide='very'
                                      content={obj.restrictions}
                                      position='top right'
                                    />
                                    </Grid.Column>
                                  </Grid.Row>
                                </Grid>
                          
                          <p>{obj.description}</p>
                          <label>{obj.label}</label>
                          <AppointmentOnOff
                            appointment={appointment}
                            onChangeServices={onChangeServices}
                            obj={obj}
                            />
                        </Message>
                        
                    </div> 

                </Form.Field>
                )
            }
            
        })
    }
    return (
        <>
        <div className='appointment-opt-serv-container'>
          <h1>¿Le gustaría agregar servicios adicionales?</h1>
          <p className='container'>
            Aprovecha tu servicio de limpieza y agenda servicios adicionales para mejorar tu experiencia
          </p>
          <Form>
            <div className={windowWidth > max_width ? 'appointment-opt-serv-size-box': 'appointment-opt-serv-size-box-small' } > {ShowOptions()} </div>
          </Form>
          <div className='appointment-opt-serv-container__button'>
            <Button variant="primary" onClick={() => navigate(route.path_prev)} basic color="gray">Volver</Button>
            <Button disabled={quantity < 1 ? true : false} variant="primary" onClick={() => onSubmit(quantity)} primary>Siguiente</Button>
          </div>
        </div>
        
        </>
      )
}

export default AppointmentOptionalServices
