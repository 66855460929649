import React, { useEffect, useState } from "react";
import { Form, Button, Input, Message } from "semantic-ui-react";
import { Auth } from "aws-amplify";
import { validateEmail } from "../../hook/ValidateMethods";
import usePasswordSecurityValidation from "../../hook/usePasswordSecurityValidation";

function ForgotPassword() {
    const [email, setEmail] = useState("");
    const [emailValid, setEmailValid] = useState(true);

    const [code, setCode] = useState("");
    const [codeValid, setCodeValid] = useState(true);
    const [codeSent, setCodeSent] = useState(false);
    const [passwordChanged, setPasswordChanged] = useState(false);
    const [password, setPassword, passwordValid] =
        usePasswordSecurityValidation();
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordMatch, setPasswordMatch] = useState(true);
    const disablePasswordForm = passwordValid && confirmPassword;

    const [emailSent, setEmailSent] = useState(false);

    useEffect(() => {
        if (passwordChanged) {
            // wait 1.5 second before refreshing the page
            setTimeout(() => {
                window.location.reload();
            }, 1500);
        }
    }, [passwordChanged]);

    const formDisabled = email.length === 0;

    const handleCodeRequest = (e) => {
        e.preventDefault();
        const validEmail = validateEmail(email);
        if (validEmail) {
            Auth.forgotPassword(email)
                .then((data) => {
                    console.log(data);
                    setEmailSent(true);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            setEmailValid(false);
        }
    };

    const handlePasswordReset = (e) => {
        e.preventDefault();
        if (password === confirmPassword) {
            setCodeSent(true);
            Auth.forgotPasswordSubmit(email, code, password)
                .then((data) => {
                    setCodeValid(true);
                    setPasswordChanged(true);
                })
                .catch((err) => {
                    setCodeValid(false);
                });
        } else {
            setPasswordMatch(false);
        }
    };

    if (emailSent) {
        return (
            <div className="login-container">
                {passwordChanged && (
                    <Message success>
                        <Message.Header>Contraseña actualizada</Message.Header>
                        <p>
                            Tu contraseña ha sido actualizada. Redireccionando a
                            la página de inicio de sesión.
                        </p>
                    </Message>
                )}
                <h3>
                    Ingresa el código de verificación que te hemos enviado a{" "}
                    {email}
                </h3>
                <Form onSubmit={handlePasswordReset}>
                    <Form.Field
                        required
                        id="form-input-control-code"
                        control={Input}
                        label="Código"
                        name="code"
                        value={code}
                        onChange={(e) => {
                            setCode(e.target.value);
                            setCodeValid(true);
                        }}
                        placeholder="Código"
                        error={
                            !codeValid && {
                                content: "Código inválido",
                            }
                        }
                    />
                    <Form.Field
                        required
                        id="form-input-control-password"
                        control={Input}
                        type="password"
                        label="Nueva contraseña"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        error={
                            !passwordValid && {
                                content:
                                    "La contraseña debe tener al menos 8 caracteres, 1 número, 1 mayúscula y 1 minúscula",
                                pointing: "above",
                            }
                        }
                    />
                    <Form.Field
                        required
                        id="form-input-control-confirm-password"
                        control={Input}
                        type="password"
                        label="Confirmar contraseña"
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => {
                            setConfirmPassword(e.target.value);
                            setPasswordMatch(true);
                        }}
                        error={
                            !passwordMatch && {
                                content: "Las contraseñas no coinciden",
                                pointing: "above",
                            }
                        }
                    />
                    <div className="login-container__send-button">
                        <Button disabled={!disablePasswordForm} primary>
                            Cambiar contraseña
                        </Button>
                    </div>
                </Form>
            </div>
        );
    }

    return (
        <div className="login-container">
            <div className="login-container__header">Recuperar contraseña</div>
            <br />
            <div>
                Ingresa tu correo electrónico y te enviaremos un código para
                recuperar tu contraseña.
            </div>
            <br />
            <Form onSubmit={handleCodeRequest}>
                <Form.Field
                    required
                    id="form-input-control-email"
                    control={Input}
                    label="Correo electronico"
                    name="email"
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value);
                        setEmailValid(true);
                    }}
                    placeholder="correo@mail.cl"
                    error={
                        !emailValid && {
                            content: "Ingresa un correo electrónico válido",
                        }
                    }
                />
                <div className="login-container__send-button">
                    <Button disabled={formDisabled} primary>
                        Enviar
                    </Button>
                </div>
            </Form>
        </div>
    );
}

export default ForgotPassword;
