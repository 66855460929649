import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import HouseImg from '../../assets/images/house-ilustration.png';

//aos
import Aos from 'aos';
import 'aos/dist/aos.css';

const HomeReadMore = () => {
    useEffect(() => {
        Aos.init({duration: 1000, once: true});
    }, []);
  return (
    <div className="read-more-banner">
        <div className="read-more-container">
            <div data-aos="zoom-in-up"  className='read-more-container__img'>
                <img src={HouseImg} alt="house" />
            </div>
            <div data-aos="fade-right"  className="read-more-text-container">
                <h1>Sobre Mopit</h1>
                <p>Mopit surge debido a la necesidad de conexión entre oferentes de servicios y personas que requieren servicios de mantención a domicilio, pero no tienen tiempo para hacer una selección personalizada. Mediante el uso de herramientas tecnológicas avanzadas, Mopit se encarga de encontrar el Moper ideal para ti.</p>
                <NavLink to="/sobre-nosotros">
                    <div className="btn-read-more">
                        <span>Leer más</span>
                    </div>

                </NavLink>
            </div>
        </div>
    </div>
  )
}

export default HomeReadMore;