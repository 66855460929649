import React, { useState, useEffect, useContext} from 'react'
import axios from 'axios'
import { UserContext } from '../../context/user/UserContext';
import ProfileAppointment from './ProfileAppointment';
import moment from 'moment';

// api
import {customerAPI} from '../../api/Api'
import camelcaseKeys from 'camelcase-keys';

function ProfileAppointments() {

    const { REACT_APP_CUSTOMER_KEY } = process.env;

    const user = useContext(UserContext);
    const customerId = user.state.id;

    const [appointments, setAppointments] = useState([])
    const [appointmentsToShow, setAppointmentsToShow] = useState([])

    useEffect(() => {
        // get user appointments
        if (customerId) {
            const url = `${customerAPI}/${customerId}/appointments`;
            axios.get(url, {
                headers: {
                    'x-api-key': REACT_APP_CUSTOMER_KEY
                }
            }).then(res => {
                const data = camelcaseKeys(res.data, {deep: true});
                setAppointments(data);
                setAppointmentsToShow(data);
            }).catch(err => {
                // console.log(err);
            })
        }
    }, [customerId])

    // filters
    const [dateFilter, setDateFilter] = useState(false);
    const [statusFilter, setStatusFilter] = useState(false);

    useEffect(() => {
        // filter by date
        if (dateFilter) {
            const filteredAppointments = appointments.filter(appointment => {
                const actualDate = moment();
                const appointmentDate = moment(appointment.startTimestamp);
                return appointmentDate.isSameOrAfter(actualDate);
            })
            setAppointmentsToShow(filteredAppointments);
        }
        // filter by status
        if (statusFilter) {
            const filteredAppointments = appointments.filter(appointment => {
                return appointment.status === statusFilter;
            })
            setAppointmentsToShow(filteredAppointments);
        }   
    }, [dateFilter, statusFilter, appointments])

    return (
        <div className="appointments-container">
            <div className='profile-appointments'>
                {appointmentsToShow.map((appointment, index) => {
                    return (
                        <div className='profile-appointment-container'>
                            <ProfileAppointment id={appointment.id} key={index} appointment={appointment} />
                        </div>
                    )
                }
                )}
            </div>
        </div>
    )
}

export default ProfileAppointments;