import React, {useContext} from 'react'
import { Link } from 'react-router-dom'
import { Button, Menu } from 'semantic-ui-react'

import { BiUserCircle } from 'react-icons/bi'

// context
import { UserContext } from "../../context/user/UserContext";

function ProfileMenu() {

  const { state, logout } = useContext(UserContext);

  return (
    <div className="profile-menu">
      <div className="profile-menu-account">
        <div className="profile-menu-account__avatar">
          <BiUserCircle />
        </div>
        <div className="profile-menu-account__name">
          {`${state.name} ${state.lastName}`}
        </div>
      </div>
      <div className="profile-menu__buttons">
        <Menu fluid vertical tabular>
          <Menu.Item>
            <Button fluid as={Link} to="cuenta" icon="edit" content="Mi cuenta"/>
          </Menu.Item>
          <Menu.Item>
            <Button fluid as={Link} to="direcciones" icon="map" content="Mis direcciones" />
          </Menu.Item>
          <Menu.Item>
            <Button fluid as={Link} to="citas" icon="calendar" content="Mis citas" />
          </Menu.Item>
          <Menu.Item>
            <Button fluid as={Link} to="cambiar-contrasena" icon="key" content="Cambiar contraseña" />
          </Menu.Item>
          <Menu.Item onClick={logout}>
            <Button fluid icon="sign-out" content="Cerrar sesión" />
          </Menu.Item>
        </Menu>
      </div>
    </div>
  )
}

export default ProfileMenu;