import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import HeaderSection from '../general/HeaderSection';
import comillasImg from '../../assets/images/comillas.png';

export const HomeTestimonials = () => {
    const data = require('../../data/testimonials.json').testimonials;
    return (
        <>
            <HeaderSection title='Testimonios de clientes'/>
            <Carousel 
                autoPlay={true} 
                infiniteLoop={true}
                showStatus={false}
                renderIndicator={(onClickHandler, isSelected, index, label) => {
                    const defStyle = { marginLeft: 5, color: "black", cursor: "pointer" };
                    const style = isSelected
                      ? { ...defStyle, color: "orange" }
                      : { ...defStyle };
                    return (
                      <span
                        style={style}
                        // className='nnn'
                        onClick={onClickHandler}
                        onKeyDown={onClickHandler}
                        value={index}
                        key={index}
                        role="button"
                        tabIndex={0}
                        aria-label={`${label} ${index + 1}`}
                      >
                        {"_"}
                      </span>
                    );
                  }}
            >
                {
                    data.map((item, i) => {
                        return(
                          // style={{backgroundColor: "rgb(234,244,2)"}}
                            <div className='ht-margin container'>
                              <div>
                                <img className='ht-margin__img' src={comillasImg} alt="" />
                                <p>{item.text}</p>
                                <p>- {item.name} -</p>
                              </div>
                                
                            </div>
                        );
                    })
                }
            </Carousel>
        </>
  )
}
