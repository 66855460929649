import React from 'react'
import {RiFacebookFill, AiOutlineInstagram, AiOutlineWhatsApp, AiFillLinkedin, AiOutlineYoutube, AiOutlineMail} from 'react-icons/all'

const OverHeader = () => {
    return (
        <div className='overheader'>
            <ul className='overheader__list'>
                <li className='overheader__item'>
                    <a className='overheader__link' target='_blank' rel="noreferrer" href='https://www.facebook.com/mopit.app1'>
                        <RiFacebookFill/>
                    </a>
                </li>

                <li className='overheader__item'>
                    <a className='overheader__link' target='_blank' rel="noreferrer" href='https://www.instagram.com/mopit.app'>
                        <AiOutlineInstagram/>
                    </a>
                </li>

                <li className='overheader__item'>
                    <a className='overheader__link' target='_blank' rel="noreferrer" href='https://api.whatsapp.com/send/?phone=%2B56992129138&text&app_absent=0'>
                        <AiOutlineWhatsApp/>
                    </a>
                </li>

                <li className='overheader__item'>
                    <a className='overheader__link' target='_blank' rel="noreferrer" href='https://cl.linkedin.com/company/mopit'>
                        <AiFillLinkedin/>
                    </a>
                </li>

                <li className='overheader__item'>
                    <a className='overheader__link' target='_blank' rel="noreferrer" href='https://www.youtube.com/channel/UCpA3QWluILxp77BESLdvvpw'>
                        <AiOutlineYoutube/>
                    </a>
                </li>

                <li className='overheader__item'>
                    <a className='overheader__link' target='_blank' rel="noreferrer" href='mailto:contacto@mopit.cl'>
                        <AiOutlineMail/>
                    </a>
                </li>  
            </ul>
        </div>
    )
}

export default OverHeader