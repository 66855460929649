import React, { useState, useEffect, useContext } from "react";
import {UserContext} from "../../context/user/UserContext";

import { BiMenuAltRight } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";

import { Menu, Dropdown, Icon } from 'semantic-ui-react';

// import './header.scss';
import mopitlogo from '../../assets/images/mopitlogo.png'
import { Link, NavLink } from "react-router-dom";

const guestItem = () => {
   

    return (
        <>
        <li><NavLink to="/login" className={({ isActive }) => (isActive ? " item--active" : "")}>Iniciar Sesión</NavLink></li>
        <li><NavLink to="/registro" className={({ isActive }) => (isActive ? " item--active" : "")}>Registrarse</NavLink></li>
        </>
    )
}

const loggedInItem = (logout, name) => {
    const trigger = (
        <div>
            <Icon name='user' />
            {name}
            <Icon name='caret down' />
        </div> 
    );

    return (
        <li>
            <Dropdown
                icon={null}
                trigger={trigger}
                className="header-dropdown"
                direction="left"
            >
                <Dropdown.Menu>
                    <Dropdown.Item style={{marginLeft: 0 + "px"}} as={Link} to="/perfil">Mi Cuenta</Dropdown.Item>
                    <Dropdown.Item style={{marginLeft: 0 + "px"}} as={Link} to="/perfil/direcciones">Mis direcciones</Dropdown.Item>
                    <Dropdown.Item style={{marginLeft: 0 + "px"}} as={Link} to="/perfil/citas">Mis Citas</Dropdown.Item>
                    <Dropdown.Item style={{marginLeft: 0 + "px"}} as={Link} to="/" onClick={logout}>Cerrar Sesión</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </li>
    )
}


const Header = ({transparent=false}) => {
    /* Black color - transparent */
    const [navbar, setNavbar] = useState(false);
    const changeBackground = () => {
        if(window.scrollY >= 20){
            setNavbar(true)
        } else{
            setNavbar(false)
        }
    }
    window.addEventListener('scroll', changeBackground)
    
    // logged in
    const { state, getSession, logout } = useContext(UserContext);
    const [loggedIn, setLoggedIn] = useState(false);
    useEffect(() => {
        getSession().then((session) => {
            if(session){
                setLoggedIn(true);
            }
        }).catch((error) => {
            setLoggedIn(false);
        });
    }, [state]);

    /* Burger action */
    const [showMobileMenu, setShowMobileMenu] = useState(false)



    function renderContent(){

        const trigger = (
            <div >
                
               Servicios
               <Icon name='caret down' />
            </div> 
        );
        return(
            <>
                <Link to='/' className='navbar__link'>
                    <img src={mopitlogo} alt="logo" className="navbar__img"/>
                </Link>

                <ul className={`menu ${showMobileMenu ? 'menu--active' : ""}`}>
                
                    <li><NavLink to="/" className={({ isActive }) => (isActive ? " item--active" : "")}>
                        Inicio
                        </NavLink>
                    </li>

                    <li>
                        <Dropdown
                            icon={null}
                            trigger={trigger}
                            className="header-dropdown"
                            
                        >
                            <Dropdown.Menu>
                                <Dropdown.Item style={{marginLeft: 0 + "px"}} as={Link} to="/limpieza">Limpieza</Dropdown.Item>
                                <Dropdown.Item style={{marginLeft: 0 + "px"}} as={Link} to="/gasfiteria">Gasfitería</Dropdown.Item>
                                <Dropdown.Item style={{marginLeft: 0 + "px"}} as={Link} to="/jardineria">Jardinería</Dropdown.Item>
                                <Dropdown.Item style={{marginLeft: 0 + "px"}} as={Link} to="/electricidad">Electricidad</Dropdown.Item>
                                <Dropdown.Item style={{marginLeft: 0 + "px"}} as={Link} to="/cerrajeria">Cerrajería</Dropdown.Item>
                                
                            </Dropdown.Menu>
                        </Dropdown>
                    </li>

                    
                    <li><NavLink to="/faq" className={({ isActive }) => (isActive ? " item--active" : "")}>Ayuda</NavLink></li>

                    
                    { loggedIn ? loggedInItem(logout, state.name) : guestItem() }
                </ul>

                <div className='menu-btn' onClick={ () => setShowMobileMenu(!showMobileMenu)}>
                    {!showMobileMenu ? (
                        <BiMenuAltRight  />
                    ) : (
                        <AiOutlineClose  />
                    )}
                </div>
            </>
        )
    }


    if(transparent){
        return(
            <nav className={`navbar ${navbar ? 'navbar__active' : ''} navbar--home`}>
                <div className="max-width">
                    {renderContent()}
                </div>
            </nav>
        )
    }

    else{
        return(
            <nav className={'navbar navbar__active'}>
                <div className="max-width">
                    {renderContent()}  
                </div>
            </nav>
        )
    }


    
}

export default Header;