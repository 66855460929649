import React, {useContext, useEffect, useState} from 'react'
import { UserContext } from '../../context/user/UserContext';
import { Form, Input, Button, Message } from 'semantic-ui-react'
import { Auth } from 'aws-amplify';
import UpdateUser from './UpdateUser';
import { format, validate } from 'rut.js';

function ProfileAccount() {

  const user = useContext(UserContext);

  const [editedUser, setEditedUser] = useState(user.state);
  const [showModal, setShowModal] = useState(false);

  const [rutValid, setRutValid] = useState(true);

  useEffect(() => {
    setEditedUser(user.state);
  }, [user.state]);

  const disableForm =
    editedUser.name === user.state.name &&
    editedUser.lastName === user.state.lastName &&  
    editedUser.email === user.state.email &&
    editedUser.phone === user.state.phone &&
    editedUser.dni === user.state.dni;

  
  // show Message and remove after 2 seconds
  const [showMessage, setShowMessage] = useState(false);
  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        setShowMessage(false);
      }, 2000);
    }
  }, [showMessage]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // validate dni
    const rut_valid = validate(editedUser.dni);
    if (rut_valid) {  
      setShowModal(true);
    } else {
      setRutValid(false);
    }
  }

  const handleCloseModal = () => {
    setShowModal(false);
    setShowMessage(true);
  }

  return (
    <div className="account-container">
        {showModal && <UpdateUser editedUser={editedUser} closedModal={handleCloseModal} previousEmail={user.email}/>}
        {showMessage && <Message success header='Datos actualizados' content='Los datos se han actualizado correctamente' />}
        <Form>
          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              label='Nombre(s)'
              placeholder='Nombre'
              value={editedUser.name}
              onChange={(e) => setEditedUser({...editedUser, name: e.target.value})}
            />
            <Form.Field 
              control={Input}
              label='Apellido(s)'
              placeholder='Apellido'
              value={editedUser.lastName}
              onChange={(e) => setEditedUser({...editedUser, lastName: e.target.value})}
            />
          </Form.Group>
          <Form.Field
            control={Input}
            label='Correo electrónico'
            placeholder='Correo electrónico'
            value={editedUser.email}
            onChange={(e) => setEditedUser({...editedUser, email: e.target.value})}
            disabled
          />
          <Form.Field
            control={Input}
            label='Teléfono'
            placeholder='Teléfono'
            value={editedUser.phone}
            onChange={(e) => setEditedUser({...editedUser, phone: e.target.value})}
          />
          <Form.Field
            control={Input} 
            label='RUT'
            placeholder='RUT'
            value={format(editedUser.dni)}
            onChange={(e) => {
              setEditedUser({...editedUser, dni: format(e.target.value, {dots: false})})
              setRutValid(true)
            }}
            error={
              rutValid
                ? false
                : {
                    content: "Por favor ingrese un RUT valido",
                  }
            }
          />
        <Button disabled={disableForm} primary onClick={handleSubmit}>Actualizar datos</Button>
        </Form>
    </div>
  )
}

export default ProfileAccount