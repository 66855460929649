import React, { useEffect } from 'react'
import ServiceBanner from "../components/services/ServiceBanner";
import ServicesButton from "../components/services/ServicesButton";
import Header from "../components/general/Header";
import Footer from '../components/general/Footer';
import ServiceCard from '../components/services/ServiceCard';
import BrowserTitleBar from "../components/general/BrowserTitleBar";

const Locksmith = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <BrowserTitleBar title="Mopit - Cerrajería" />
            <Header />
            <ServiceBanner title="¡Contrata tu servicio de Cerrajería!" subtitle="Escoge un subservicio" />
            <ServicesButton />
            <ServiceCard subService={"locksmith"} />
            <footer>
                <Footer />
            </footer>
        </>
    )
}

export default Locksmith