import React, { useContext, useEffect } from "react";
import { Form, Radio, Button } from "semantic-ui-react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { AppointmentContext } from "../../context/appointment/AppointmentContext";
import MercadoPago from "../../assets/images/payMethods/mercado-pago.png";
import WebPay from "../../assets/images/payMethods/webpay.png";
import Transfer from "../../assets/images/payMethods/transferencia.png";

const AppointmentPaymentMethod = () => {
  const navigate = useNavigate();
  const { route, setRedirectMessage } = useOutletContext();
  const { state, updatePaymentMethod, checkDetails } = useContext(AppointmentContext);

  useEffect(() => {
    // remove payment method on component unmount (when user moves to another page)
    // also remove the redirect message
    return () => {
      updatePaymentMethod("");
      setRedirectMessage("");
    };
  }, []);

  if(!checkDetails()) {
    navigate(route.path_start);
  }

  return (
    <div className="pay-container">
      <h1>Método de pago</h1>
      Seleccionado: {state.paymentMethod}
      <Form unstackable>
        <div
          className="pay-method-box"
          onClick={() => {
            updatePaymentMethod("MercadoPago");
            window.scrollTo(100, window.outerHeight*2);
          }}
        >
          <Form.Field>
            <Radio
              label="Mercado Pago"
              name="radioGroup"
              value="MercadoPago"
              checked={state.paymentMethod === "MercadoPago"}
            />
          </Form.Field>
          <div className="pay-image-box pay-image-box--mercadopago">
            <img
              className="pay-image__mercadopago"
              src={MercadoPago}
              alt="MercadoPago"
            />
          </div>
        </div>
        <div
          className="pay-method-box"
          onClick={() => {
            updatePaymentMethod("Webpay");
            window.scrollTo(100, window.outerHeight*2);
          }}
        >
          <Form.Field>
            <Radio
              label="Webpay"
              name="radioGroup"
              value="Webpay"
              checked={state.paymentMethod === "Webpay"}
              onClick={e => window.scrollTo(100, window.outerHeight*2)}
            />
          </Form.Field>
          <div className="pay-image-box">
            <img className="pay-image-box__webpay" src={WebPay} alt="WebPay" />
          </div>
        </div>
        <div 
          className="pay-method-box"
          onClick={() => {
            updatePaymentMethod("TransferenciaBancaria");
            window.scrollTo(100, window.outerHeight*2);
          }}
        >
          <Form.Field>
            <Radio
              label="Trasferencia Bancaria"
              name="radioGroup"
              value="TransferenciaBancaria"
              checked={state.paymentMethod === "TransferenciaBancaria"}
              onClick={e => window.scrollTo(100, window.outerHeight*2)}
            />
          </Form.Field>
          <div className="pay-image-box pay-image-box--transfer">
            <img
              className="pay-image-box--transfer"
              src={Transfer}
              alt="Transfer"
            />
          </div>
        </div>
      </Form>
      <br></br>
      <Button onClick={() => navigate(route.path_prev)} basic color="gray">Volver</Button>
    </div>
  );
};

export default AppointmentPaymentMethod;
