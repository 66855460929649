import React, { useEffect } from "react";
import ServiceBanner from "../components/services/ServiceBanner";
import ServicesButton from "../components/services/ServicesButton";
import Header from "../components/general/Header";
import Footer from '../components/general/Footer';
import ServiceCard from '../components/services/ServiceCard';
import BrowserTitleBar from "../components/general/BrowserTitleBar";


const Cleaning = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <BrowserTitleBar title="Mopit - Limpieza a domicilio" />
            <Header />
            <ServiceBanner title="¡Contrata tu servicio de Limpieza!" subtitle="Escoge un subservicio" />
            <ServicesButton />
            <ServiceCard subService={"cleaning"} />
            <footer>
                <Footer />
            </footer>
        </>
    )
};

export default Cleaning;