import React from 'react';
import Logo from '../../assets/images/mopitlogo.png';
import LogoCorfo from '../../assets/images/corfo.png';
import LogoGobChile from '../../assets/images/logoGobChile.png';
import LogoOHCW from '../../assets/images/logoOHCW.png';
import ig from '../../assets/images/social-media-icons/social-ig.png';
import fb from '../../assets/images/social-media-icons/social-fb.png';
import wsp from '../../assets/images/social-media-icons/social-wsp.png';
import yt from '../../assets/images/social-media-icons/social-yt.png';
import lin from '../../assets/images/social-media-icons/social-in.png';

//icons Ionic
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { NavLink } from 'react-router-dom';

const Footer = () => {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // for smoothly scrolling
        });
    };

    return (
        <>
            <div className="footerContainer">
                <div className='footerItemsContainer'>
                    <div className='footerColumnMain'>
                        <img src={Logo} alt='mopitLogo' onClick={() => scrollToTop()} />
                        <br />
                        <h3 className='footerColumnMain__text'>Conoce más de nosotros en:</h3>
                        <div className='socialNetworksContainer'>
                            <a href="https://www.facebook.com/mopit.app1" target="_blank" rel="noreferrer">
                                <img src={fb} alt='fb' className='socialNetworksContainer__icon' />
                            </a>
                            <a href="https://www.instagram.com/mopit.app" target="_blank" rel="noreferrer">
                                <img src={ig} alt='instagram' className='socialNetworksContainer__icon' />
                            </a>
                            <a href="https://wa.me/+56992129138" target="_blank" rel="noreferrer">
                                <img src={wsp} alt='whatsaap' className='socialNetworksContainer__icon' />
                            </a>
                            <a href="https://www.youtube.com/channel/UCpA3QWluILxp77BESLdvvpw" target="_blank" rel="noreferrer">
                                <img src={yt} alt='youtube' className='socialNetworksContainer__icon' />
                            </a>
                            <a href="https://cl.linkedin.com/company/mopit" target="_blank" rel="noreferrer">
                                <img src={lin} alt='lindekin' className='socialNetworksContainer__icon' />
                            </a>
                        </div>
                    </div>

                    <div className='footerColumn'>
                        <h3 className='footerColumn__title'>Links de interés</h3>
                        <br />
                        <NavLink to='/faq'>
                            <h5 className='footerColumn__text'>Preguntas Frecuentes</h5>
                        </NavLink>
                        <NavLink to='/sobre-nosotros'>
                            <h5 className='footerColumn__text'>Sobre nosotros</h5>
                        </NavLink>
                        <NavLink to='/trabaja-con-nosotros'>
                            <h5 className='footerColumn__text'>Trabaja con nosotros</h5>
                        </NavLink>
                        <NavLink to='/soporte'>
                            <h5 className='footerColumn__text'>Soporte</h5>
                        </NavLink>
                        <NavLink to='/Terminos-Condiciones'>
                            <h5 className='footerColumn__text'>Términos y Condiciones</h5>
                        </NavLink>
                    </div>

                    <div className='footerColumn'>
                        <h3 className='footerColumn__title'>Contacto</h3>
                        <br />
                        <div className="iconTextContainer">
                            <EmailOutlinedIcon className='iconTextContainer__icon' />
                            <a target='_blank' rel="noreferrer" href='mailto:contacto@mopit.cl'>
                                <h5 className='iconTextContainer__text'>contacto@mopit.cl</h5>
                            </a>
                        </div>
                        <div className="iconTextContainer"></div>
                        {/* <div className="iconTextContainer">
                            <CallOutlinedIcon className='iconTextContainer__icon'/>
                            <h5 className='iconTextContainer__text'>+56 9 9212 9138</h5>
                        </div> */}
                    </div>

                    <div className='footerColumn'>
                        <h3 className='footerColumn__title'>Proyecto apoyado por</h3>
                        <br />
                        <div className="sponsorContainer">
                            <img className='sponsorContainer__logoCorfo' src={LogoCorfo} alt='corfo' />
                            <img className='sponsorContainer__logoGobChile' src={LogoGobChile} alt='Gobierno de Chile' />
                            <img className='sponsorContainer__logoOHCW' src={LogoOHCW} alt='OHCW' />
                        </div>
                    </div>
                </div>
            </div>

            <div className='copyrightFooter'>
                <p className='copyrightFooter__copyrightText'>Copyright &copy; Mopit 2022</p>
            </div>
        </>
    );
};

export default Footer;