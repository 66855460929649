import React, { useState, useContext } from 'react';
import {Form, Button, Input, Message} from 'semantic-ui-react';
import { UserContext } from '../../context/user/UserContext';
import { Link, useNavigate } from 'react-router-dom';
import ForgotPassword from './ForgotPassword';
import loginLogo from '../../assets/images/banner/bannerCleanning.png';


const SignIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [loginError, setLoginError] = useState(false);

    const [forgotPassword, setForgotPassword] = useState(false);

    const { authenticate } = useContext(UserContext);
    const navigate = useNavigate();

    const onSubmit = async (e) => {
        e.preventDefault();
        const response = await authenticate(email, password);
        if (response.message) {
            setLoginError(true);
        } else {
            navigate('/');
        }
    }

    if (forgotPassword) {
        return <ForgotPassword />
    }

    return (
        <div className='login-container'>
            <img className="login-container__logo" src={loginLogo} alt="Mopit" />
            <Form>
                <Form.Field
                    required
                    id="form-input-control-email"
                    control={Input}
                    label="Correo electronico"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="correo@mail.cl"
                />
                <Form.Field
                    required
                    id="form-input-control-password"
                    control={Input}
                    type="password"
                    label="Contraseña"
                    name="password"
                    value={password}
                    onChange={(e) => {
                        setPassword(e.target.value)
                        setLoginError(false)
                    }}
                    error={
                        loginError && {
                            content: "Usuario o contraseña incorrectos",
                            pointing: "above",
                        }
                    }
                />
                <div className="login-container__forgot-password">
                    <a href="#" onClick={() => setForgotPassword(!forgotPassword)}>¿Olvidaste tu contraseña?</a>
                </div>
                <div className='login-container__send-button'>
                    <Button onClick={onSubmit} primary>
                        Ingresar
                    </Button>
                </div>
            </Form>
        </div>
    );
}

export default SignIn;

        