import React from 'react';
import { useTyped } from '../../hook/useTyped';
import { NavLink } from 'react-router-dom';
import Carousel from '../general/Carousel';


const service = ['Limpieza', 'Gasfitería', 'Jardinería', 'Electricidad', 'Cerrajería']
const HomeBanner = () => {

    const typed = useTyped(service)

    const info = [
        { "images": "bannerCleanning.png" },
        { "images": "bannerPlumbing.png" },
        { "images": "bannerGardener.png" },
        { "images": "bannerElectricity.png" },
        { "images": "bannerLocksmith.png" },
    ]

    return (
        <main className="home">
            <div className="max-width">
                <div className='home-content'>
                    <h4 className='home-content__top'>Bienvenid@s, somos</h4>
                    <h1 className='home-content__mid'>Mopit</h1>
                    <h3 className='home-content__bottom'>Brindamos servicios de <span className='home-content__typing'>{typed}</span></h3>
                    <NavLink to="/limpieza" className='home-content__button'>
                        Cotiza y agenda
                    </NavLink>
                </div>
                <div className='home-carousel'>
                    <Carousel images={info} autoPlayy={true} />
                </div>
            </div>
        </main>
    )
};

export default HomeBanner;