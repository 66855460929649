import React, { useEffect } from "react";
import BrowserTitleBar from "../components/general/BrowserTitleBar";
import Footer from "../components/general/Footer";
import Header from "../components/general/Header";
import ServiceBanner from "../components/services/ServiceBanner";
import ServiceCard from "../components/services/ServiceCard";
import ServicesButton from "../components/services/ServicesButton";

const Plumbing = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <BrowserTitleBar title="Mopit - Gasfitería" />
            <Header />
            <ServiceBanner title="¡Contrata tu servicio de Gasfitería!" subtitle="Escoge un subservicio" />
            <ServicesButton />
            <ServiceCard subService={"plumbing"} />
            <footer>
                <Footer />
            </footer>
        </>
    )
};

export default Plumbing;