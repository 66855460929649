import { useState, useContext, useEffect } from "react";
import { Form, Input, Button, Checkbox, Select } from "semantic-ui-react";
import { useNavigate, useOutletContext } from "react-router-dom";

function AppointmentDropDown(props) {
    const [price, setPrice] = useState(0);

    useEffect(()=> {
      props.appointment.updateOptionalDetails({ [props.obj.id]:'0' })
    }, []);

    const getKey = (value, obj) => {
        for (let i in obj.options) {
          if (obj.options[i].value === value) {
            return obj.options[i].key
          }
        }
        return ""
      }
   

    return (
      <div class="center"> 
        <Select className="appointment-opt-serv-fit"
                          placeholder="Seleccione"
                          options={props.obj.options}
                          value={props.appointment.getDetail(props.obj.id + "_value") || ""}
                          onChange={(e, { value }) =>
                           {
                           setPrice(value);
                           props.onChangeServices(props.obj, value);
                            let v = getKey(value, props.obj);
                            props.appointment.updateDetails({ [props.obj.id + "_value"]: value });
                            props.appointment.updateOptionalDetails({ [props.obj.id]: v })
                          }}
                          />
                {price != 0 && (<p>Valor: ${price}</p>)}
        </div>

    )
}
export default AppointmentDropDown;