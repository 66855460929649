import{ mainPageURL } from '../api/Api';
import randomstring from "randomstring";

// ENV KEYS
const { REACT_APP_MP_API_KEY } = process.env;

// API Calls Methods
export const createData = async (url, data) => {
  let response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  let json = await response.json();
  return json;
};

export const updateAppointment = async (url, data) => {
  let response = await fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  let json = await response.json();
  return json;
};

export const getMercadoPagoLink = async (url = "", data = {}) => {
  let response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-api-key": REACT_APP_MP_API_KEY,
    },
    body: JSON.stringify(data),
  });
  let json = await response.json();
  return json;
};

export const getData = async (url) => {
  let response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  let json = await response.json();
  return json;
};

// Create Body Methods
export const createCustomerBody = (user) => {
  return {
    first_name: user.name,
    last_name: user.lastname,
    email: user.email,
    phone_number: user.phone,
    dni: user.rut,
  };
};

export const createAppointmentBody = (appointment, user, subtotal, total, index, systemAttributes) => {
  return {
    startTimestamp: appointment.times[index].rawStartTime,
    endTimestamp: appointment.times[index].rawEndTime,
    couponId: appointment.couponId,
    subtotal: subtotal,
    total: total,
    couponId: appointment.couponId,
    customer: user,
    address: appointment.address,
    systemAttributes
  };
};

export const createMercadoPagoBody = (
  appointmentPrice,
  userEmail,
  appIDs
) => {
  return {
    title: "Limpieza del hogar",
    price: appointmentPrice,
    email: userEmail,
    name: "NAME",
    appointment_id: appIDs.join("x"),
    payment_method: "MercadoPago",
    success_link: `${mainPageURL}/resumen-pago`,
    pending_link: `${mainPageURL}/resumen-pago`,
    failure_link: `${mainPageURL}/resumen-pago`,
  };
};

export const createWebpayBody = (appIDs, price) => {
  const ids = appIDs.join("x");
  console.log(ids);
  return {
    buy_order: `ordenCompra-${appIDs[0]}`,
    session_id: `sessionID-${appIDs[0]}`,
    amount: price,
    return_url: `${mainPageURL}/resumen-pago?appointment_id=${ids}&payment_method=Webpay`,
  };
};

export const createAttributesBody = (appointment, appointmentID) => {
  let queryList =  [
    {
      appointment_id: appointmentID,
      attribute_name: "bathrooms",
      attribute_title: "Cantidad de baños",
      attribute_value: appointment.details.bathrooms,
      service_type: "cleaning",
    },
    {
      appointment_id: appointmentID,
      attribute_name: "bedrooms",
      attribute_title: "Cantidad de habitaciones",
      attribute_value: appointment.details.rooms,
      service_type: "cleaning",
    },
    {
      appointment_id: appointmentID,
      attribute_name: "size",
      attribute_title: "Tamaño del hogar",
      attribute_value: appointment.details.size,
      service_type: "cleaning",
    },
    {
      appointment_id: appointmentID,
      attribute_name: "dirtiness",
      attribute_title: "Nivel de suciedad",
      attribute_value: appointment.details.dirtiness,
      service_type: "cleaning",
    },
    {
      appointment_id: appointmentID,
      attribute_name: "propertyType",
      attribute_title: "Tipo de propiedad",
      attribute_value: appointment.details.propertyType,
      service_type: "cleaning",
    },
  ];
  // Array with the names of additional services
  let optionalServicesArray = ["planchado", "limpiezaVentanas", "limpiezaMicroondas", "limpiezaRefrigerador", "limpiezaHorno", "limpiezaHornoRefriMicroondas"];
  
  let exists = "optionalDetails" in appointment.details ? true : false;
  
  if (exists) {
    // Nice way to add optional services to the query list
    optionalServicesArray.forEach(service => {
  
      let attribute = service in appointment.details.optionalDetails ? appointment.details.optionalDetails[service] : "0";
  
      if (attribute !== "0") {
        let obj = {
          appointment_id: appointmentID,
          attribute_name: service,
          attribute_title: "Horas",
          attribute_value: attribute,
          service_type: "additional_service",
        };
        queryList.push(obj); 
      }
    })
  }
  return queryList;
};

export const createMopitSupportEmailBody = (name, emailFrom, subject, emailText) => {
  return {
    "name": name,
    "email_from": emailFrom,
    "email_to": "contacto@mopit.cl",
    "subject": subject,
    "email_text": emailText,
    "message": "Está recibiendo este correo electrónico porque alguien ha solicitado ayuda"
  };
}

export const createCustomerSupportEmailBody = (name, emailFrom, subject, emailText) => {
  return {
    "name": name,
    "email_from": emailFrom,
    "email_to": emailFrom,
    "subject": subject,
    "email_text": emailText,
    "message": "Está recibiendo este correo electrónico porque ha solicitado ayuda, por favor no responda este correo"
  };
}

export const createLocationBody = (customerId, address) => {
  return {
    customerId: customerId,
    communeId: address.communeId,
    street: address.street,
    homeNumber: address.homeNumber,
    dept: address.dept,
    other: address.other,
    alias: address.alias,
  }
}


