import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Form, Input, Button, Message } from "semantic-ui-react";
import usePasswordSecurityValidation from "../../hook/usePasswordSecurityValidation";

const MessageSuccess = () => {
    return (
        <Message success>
            <Message.Header>Contraseña actualizada</Message.Header>
            <p>Tu contraseña ha sido cambiada exitosamente</p>
        </Message>
    );
};

const MessageError = ({ message, type }) => {
    let err = "";
    console.log(type);
    switch (type) {
        case "LimitExceededException":
            err =
                "Has excedido el límite de intentos. Inténtalo de nuevo más tarde.";
            break;
        default:
            err = "Ocurrió un error al intentar cambiar tu contraseña.";
    }
    return (
        <Message error>
            <Message.Header>Error al cambiar contraseña</Message.Header>
            <p>{err}</p>
        </Message>
    );
};

function ProfileChangePassword() {
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword, passwordValid] =
        usePasswordSecurityValidation();
    const [passwordMatch, setPasswordMatch] = useState(true);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [errorType, setErrorType] = useState("");
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    // remove messages after 3 seconds
    useEffect(() => {
        if (success) {
            setTimeout(() => {
                setSuccess(false);
            }, 3000);
        }
    }, [success]);

    useEffect(() => {
        if (errorMessage) {
            setTimeout(() => {
                setErrorMessage("");
                setErrorType("");
            }, 3000);
        }
    }, [errorMessage]);

    // check if passwords match
    const checkPasswordMatch = () => {
        if (newPassword === confirmPassword) {
            setPasswordMatch(true);
            return true;
        } else {
            setPasswordMatch(false);
            return false;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (!passwordValid) {
            setLoading(false);
            return;
        }
        if (!checkPasswordMatch()) {
            setLoading(false);
            return;
        }
        try {
            await Auth.currentAuthenticatedUser()
                .then((user) =>
                    Auth.changePassword(user, oldPassword, newPassword)
                )
                .then(() => {
                    setOldPassword("");
                    setNewPassword("");
                    setConfirmPassword("");
                    setSuccess(true);
                    setLoading(false);
                })
                .catch((err) => {
                    setErrorMessage(err.message);
                    setErrorType(err.code);
                    setSuccess(false);
                    setLoading(false);
                });
        } catch (err) {
            setErrorMessage(err.message);
            setErrorType(err.code);
            setSuccess(false);
            setLoading(false);
        }
    };

    return (
        <div className="change-password-container">
            {success && <MessageSuccess />}
            {errorMessage !== "" && (
                <MessageError message={errorMessage} type={errorType} />
            )}
            <Form onSubmit={handleSubmit}>
                <Form.Field
                    control={Input}
                    label="Contraseña actual"
                    type="password"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                />
                <Form.Field
                    control={Input}
                    label="Nueva contraseña"
                    type="password"
                    value={newPassword}
                    error={
                        !passwordValid && {
                            content:
                                "La contraseña debe tener al menos 8 caracteres, 1 número, 1 mayúscula y 1 minúscula",
                            pointing: "above",
                        }
                    }
                    onChange={(e) => setNewPassword(e.target.value)}
                />
                <Form.Field
                    control={Input}
                    label="Confirmar nueva contraseña"
                    type="password"
                    value={confirmPassword}
                    error={
                        !passwordMatch && {
                            content: "Las contraseñas no coinciden",
                            pointing: "above",
                        }
                    }
                    onChange={(e) => {
                        setConfirmPassword(e.target.value);
                        setPasswordMatch(true);
                    }}
                />
                <Button loading={loading} primary>
                    Cambiar contraseña
                </Button>
            </Form>
        </div>
    );
}

export default ProfileChangePassword;
