import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom';
import { Form, Input, Button, TextArea } from "semantic-ui-react";
import axios from "axios";
import BrowserTitleBar from '../components/general/BrowserTitleBar';
import Footer from '../components/general/Footer';
import Header from '../components/general/Header';
import { validateEmail } from '../hook/ValidateMethods';
import { sendSupportEmailAPI } from "../api/Api";
import { createMopitSupportEmailBody, createCustomerSupportEmailBody } from "../hook/ApiMethods"

const Support = () => {

    const [name, setName] = useState("");
    const [emailFrom, setEmailFrom] = useState("");
    const [emailFromValid, setEmailFromValid] = useState(true);
    const [subject, setSubject] = useState("");
    const [emailText, setEmailText] = useState("");
    const [sendResult, setSendResult] = useState("");

    const { REACT_APP_SEND_SUPPORT_EMAIL_KEY } = process.env;

    const formValid = 
        !name ||
        !emailFrom ||
        !subject ||
        !emailText;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const onSubmit = async() => {
        const emailValid = validateEmail(emailFrom);
        setEmailFromValid(emailValid);
        if(emailValid) {
            setSendResult("Enviando correo...");

            const mopitEmailBody = createMopitSupportEmailBody(name, emailFrom, subject, emailText);

            const mopitEmailResponse = await axios.post(
                `${sendSupportEmailAPI}`,
                mopitEmailBody,
                {
                    headers: {
                      "Content-Type": "application/json",
                      "x-api-key": REACT_APP_SEND_SUPPORT_EMAIL_KEY,
                    },
                }
            );

            const customerEmailBody = createCustomerSupportEmailBody(name, emailFrom, subject, emailText);
            const customerEmailResponse = await axios.post(
                `${sendSupportEmailAPI}`,
                customerEmailBody,
                {
                    headers: {
                      "Content-Type": "application/json",
                      "x-api-key": REACT_APP_SEND_SUPPORT_EMAIL_KEY,
                    },
                }
            );

            setSendResult("¡Correo enviado correctamente!");
            setName("");
            setEmailFrom("");
            setSubject("");
            setEmailText("");
        }
    }

    return (
        <>
            <BrowserTitleBar title="Mopit - Soporte"/>
            <Header/>
            <div className='support-box'>
                <h1 className='support-box__title'>Soporte</h1>
                <div className='support-box__text'>
                    <p className='support-box__text--color'>Recuerda que si tienes alguna duda, primero puedes visitar el apartado de&nbsp;
                    <span>
                        <NavLink to="/faq">
                            Preguntas Frecuentes 
                        </NavLink>
                    </span>
                    ,&nbsp;si tu duda no se encuentra ahí con gusto la resolveremos</p>
                </div>
            </div>

            <div className='support-inputs'>
                <Form>
                    <Form.Field
                        className='support-inputs__margin'
                        required
                        id="form-input-control-nombre"
                        control={Input}
                        label="Nombre"
                        name="name"
                        value={name}
                        onChange={e => {setName(e.target.value)}}
                        placeholder="Benjamín González"
                    />
                    <Form.Field
                        className='support-inputs__margin'
                        required
                        id="form-input-control-nombre"
                        control={Input}
                        label="Correo electrónico"
                        name="emailFrom"
                        value={emailFrom}
                        error={
                            emailFromValid 
                                ? false
                                : {
                                    content: "Por favor ingrese un e-mail valido",
                                }
                        }
                        onChange={e => {setEmailFrom(e.target.value)}}
                        placeholder="correo@mail.cl"
                    />
                    <Form.Field
                        className='support-inputs__margin'
                        required
                        id="form-input-control-nombre"
                        control={Input}
                        label="Asunto"
                        name="subject"
                        value={subject}
                        onChange={e => {setSubject(e.target.value)}}
                        placeholder="Duda con limpieza"
                    />
                    <Form.Field
                        className='support-inputs__margin'
                        required
                        id="form-input-control-nombre"
                        control={TextArea}
                        maxlength="600"
                        rows={8}
                        label="Descripción"
                        name="emailText"
                        value={emailText}
                        onChange={e => {setEmailText(e.target.value)}}
                        placeholder="Que pasa si el Moper..."
                    />
                </Form>
                <h3>{sendResult}</h3>
                <Button className='support-form-button' disabled={formValid} onClick={e => onSubmit()}>
                    Siguiente
                </Button>
            </div>
                        
            <footer>
                <Footer />
            </footer>
        </>
    )
}

export default Support;
