
const defaultCleaning = {
  rooms: 0,
  bathrooms: 0,
  size: 0,
  dirtiness: "",
};

export const AppointmentReducer = (state, action) => {
  switch (action.type) {
    case "updateServiceType":
      let newState = { ...state };
      newState.serviceType = action.payload;
      // update service details based on service type
      switch (action.payload) {
        case "generalCleaningDept":
          newState.details = defaultCleaning;
          break;
        default:
          newState.details = {};
          break;
      }
      return newState;
    case "updateDetails":
      return {
        ...state,
        details: {
          ...state.details,
          ...action.payload,
        },
      };



    case "updateOptionalDetails":
      return {
        ...state,
        details: {
          ...state.details,
          optionalDetails: {
            ...state.details.optionalDetails,
            ...action.payload,
          }
          
        },
      };


      
    case "updatePropertyType":
      let a = {
        ...state,
        details: {
          ...state.details,
          ...action.payload, 
        },
        ...action.payload,
      };
      return a;

    case "updateAppointmentQuantity":
      return {
        ...state,
        appointmentQuantity: action.payload,
      }
    case "updateTimes":
      return {
        ...state,
        times: action.payload,
      }
    case "updateDate":
      return {
        ...state,
        date: action.payload,
      };
    case "updateStartTimestamp":
      return {
        ...state,
        startTimestamp: action.payload,
      };
    case "updateEndTimestamp":
      return {
        ...state,
        endTimestamp: action.payload,
      };
    case "updateDuration":
      return {
        ...state,
        duration: action.payload,
      };
    case "updateUnitPrice":
      return {
        ...state,
        unitPrice: action.payload,
      };
    case "updateAllPrices":
      return {
        ...state,
        allPrices: action.payload,
      };
    case "updatePrice":
      return {
        ...state,
        price: action.payload,
        base_price : action.payload,
      };
    // para agregar el precio de los servicios adicionales
    case "addPrice":
      return {
        ...state,
        price: state.base_price + action.payload,
      };
    // funcion para marcar la existencia de servicios opcionales
    case "addOptionalServices":
      return {
        ...state,
        // optionalServicesCheck: servicesCheck,
        optionalServicesPrice: action.payload
      };
    case "updateCoupon":
      return {
        ...state,
        couponId: action.payload,
      };
    case "updateAddress":
      return {
        ...state,
        address: action.payload,
      };
    case "updatePaymentMethod":
      return {
        ...state,
        paymentMethod: action.payload,
      };
    default:
      return state;
  }
};
