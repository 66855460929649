import axios from 'axios';

export const UserReducer = (state, action) => {
  switch (action.type) {
    case "updateState":
      return action.payload;
    case "updateName":
      return {
        ...state,
        name: action.payload,
      };
    case "updateLastName":
      return {
        ...state,
        lastName: action.payload,
      };
    case "updateDni":
      return {
        ...state,
        dni: action.payload,
      };
    case "updateEmail":
      return {
        ...state,
        email: action.payload,
      };
    case "updatePhone":
      return {
        ...state,
        phone: action.payload,
      };
    case "updateAddressList":
      return {
        ...state,
        addressList: action.payload,
      };
    case "addAddressList":
      return {
        ...state,
        addressList: [action.payload, ...state.addressList],
      };
    case "updateId":
      return {
        ...state,
        id: action.payload,
      };
    case "deleteAddress":
      return {
        ...state,
        addressList: state.addressList.filter(
          (address) => address.id !== action.payload
        )};
    case "updateAddress":
      return {
        ...state,
        addressList: state.addressList.map((address) => {
          if (address.id === action.payload.id) {
            return action.payload;
          } else {
            return address;
          }
        })
      };
    default:
      return state;
  }
};
