import React, { useEffect } from 'react'
import { wspURL } from '../api/Api';
import mopitLogo from "../assets/images/mopitlogo.png";

const WhatsappRedirect = ({name}) => {
    useEffect(() => {
        const wspMessage = `¡Hola! Me gustaría saber más sobre el servicio de ${name}`;
        window.location.href = `${wspURL}?text=${wspMessage}`;
    }, [])
    
    return (
        <div className='wsp-box'>
            <img src={mopitLogo} alt="Logo Mopit" />
            <h3>Redireccionando a Whatsapp...</h3>
        </div>
    )
}

export default WhatsappRedirect