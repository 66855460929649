import React from 'react';
import { useState, useEffect } from 'react';
import BrowserTitleBar from '../components/general/BrowserTitleBar';
import Header from '../components/general/Header';
import mopitIcon from '../assets/images/mopit_icon.png'
import Footer from '../components/general/Footer';


const Terms = () => {

  const data = require('../data/terms.json').options;
  const [index, setIndex] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const subTitles = () => {
    let sub = data[index]?.sub_titles;
    if(sub != null) {
      return (
        sub.map((item, i) => {
          return(
            <div>
              <h3>{item.title}</h3>
              <p className='spaces'>{item.text}</p>
              <br />
            </div>
          )
        })
      );
    } else {
      return(
        <div></div>
      )
    }
  }

  return (
    <>
      <BrowserTitleBar title="Mopit - Términos y Condiciones"/>
      <Header/>
      <div className="container terms-boxes">
        <div className='terms-options'>
          {
            data.map((item, i) => {
              return (
                <div className='terms-title-container terms-active-box' onClick={e => {setIndex(i)}}>
                  <h2 className='title-container__text'>{item.title}</h2>
                  <img src={mopitIcon}  alt='fb' className='title-container__icon'/>
                </div>
              );
            })
          }
        </div>
        <div className='terms-text container'>
          <h1>{data[index].title}</h1>
          <p>{data[index].text}</p>
          {subTitles()}
        </div>
      </div>
      <footer>
        <Footer />
      </footer>
    </>
  );
};

export default Terms;
