// price comes in CLP
// need to convert to locale currency if needed
const usePriceFormatter = () => {

  const CURRENCY_CODE = "CLP";
  let priceFormat = new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: CURRENCY_CODE,
  });

  const priceFormatter = (price) => {
    return priceFormat.format(price);
  }

  return {
    priceFormatter
  }
}


export default usePriceFormatter;
