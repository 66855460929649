import React from 'react';
// import './headerSectionTest.scss'

const HeaderSection = ({title="Nuestros servicios", left=true}) => {
  return (
    <>
          <div className='containerHeader'>
            {left
              ? <>
                  <h2 className='containerHeader__title'>{title}</h2>
                  <div className='containerHeader__line'/>
                </>
              : <>
                    <div className='containerHeader__line'/>
                    <h2 className='containerHeader__title'>{title}</h2>
                </>
            }
          </div>
    </>
  );
};

export default HeaderSection;