import React, { useEffect } from "react";
import Header from '../components/general/Header'
import Footer from '../components/general/Footer'
import HomeBanner from "../components/home/HomeBanner";
import HomeChoose from "../components/home/HomeChoose";
import HomeService from "../components/home/HomeService";
import HomeWorkflow from "../components/home/HomeWorkflow";
import HomeMobile from "../components/home/HomeMobile";
import BrowserTitleBar from "../components/general/BrowserTitleBar";
import HomeReadMore from "../components/home/HomeReadMore";

//aos
import Aos from 'aos';
import 'aos/dist/aos.css';
import OverHeader from "../components/general/OverHeader";
import HomeModal from "../components/home/HomeModal";
import { HomeTestimonials } from "../components/home/HomeTestimonials";


const Home = () => {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>
            <BrowserTitleBar title="Mopit - Servicios a domicilio" />
            <OverHeader />
            <Header transparent={true} />

            <HomeBanner />

            <HomeModal />

            <section className="service section">
                <div className="container">
                    <HomeService />
                </div>
            </section>

            <section className="read-more section">
                <HomeReadMore />
            </section>

            <section className="choose section">
                <div className="container">
                    <HomeChoose />
                </div>
            </section>


            <section className="workflow section">
                <div className="container">
                    <HomeWorkflow />
                </div>
            </section>

            <section className="testimonials section">
                <div className="container">
                    <HomeTestimonials />
                </div>
            </section>

            <section className="mobile section">
                <div className="container">
                    <HomeMobile />
                </div>
            </section>

            <footer>
                <Footer />
            </footer>
        </>
    )

};

export default Home;