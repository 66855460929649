import { useContext, useEffect } from "react";
import BrowserTitleBar from "../components/general/BrowserTitleBar";
import Footer from "../components/general/Footer";
import Header from "../components/general/Header";
import OverHeader from "../components/general/OverHeader";
import SignIn from "../components/users/SignIn";
import { UserContext } from "../context/user/UserContext";
import { useNavigate } from "react-router-dom";

const Login = () => {

    const { state, getSession } = useContext(UserContext);

    const navigate = useNavigate(); 

    // if the user is logged in, redirect to the home page
    useEffect(() => {
        getSession().then((session) => {
            navigate("/");
        }).catch((err) => {
            // user not authenticated
        });
    }, []);


    return (
        <>
            <BrowserTitleBar title="Mopit - Servicios a domicilio" />
            <OverHeader />
            <Header />
            <SignIn />
            <Footer />
        </>
    );  
}

export default Login;