import { createContext, useReducer } from "react";
import { AppointmentReducer } from "./AppointmentReducer";

import usePriceFormatter from "../../hook/usePriceFormatter";


// Inicializar objeto con valores default
const optionalServices = {
  planchado: "0",
  limpiezaVentanas: "0",
  limpiezaMicroondas: "0",
  limpiezaRefrigerador: "0",
  limpiezaHorno: "0",
  limpiezaHornoRefriMicroondas: "0",
}

const defaultAppointmentState = {
  serviceType: "",
  date: "",
  appointmentQuantity: 1,
  times: [],
  startTimestamp: "",
  endTimestamp: "",
  duration: 0,
  unitPrice: 0,
  price: 0,
  allPrices: [0,0,0,0,0],
  couponId: null,
  paymentMethod: "",
  details: {
    optionalServices: {
      planchado: "0",
      limpiezaVentanas: "0",
      limpiezaMicroondas: "0",
      limpiezaRefrigerador: "0",
      limpiezaHorno: "0",
      limpiezaHornoRefriMicroondas: "0",
    },
  },
  address: {
    commune: "",
    country: "Chile",
    region: "Metropolitana",
    street: "",
    homeNumber: "",
    dept: "",
    other: "",
  },
};
export const AppointmentContext = createContext();


export const AppointmentProvider = ({ children }) => {
  const [state, appointmentDispatch] = useReducer(
    AppointmentReducer,
    defaultAppointmentState
  );
  
  const { priceFormatter } = usePriceFormatter();

  const checkDetails = () => {
    switch (state.serviceType) {
      case "generalCleaningDept":
        // check if size and dirtiness are set
        if (!state.details.size || !state.details.dirtiness) {
          return false;
        }
        return true;
      default:
        return false;
    }
  }
  const checkDetails2 = () => {
    switch (state.serviceType) {
      case "generalCleaningDept":
        // check if size and dirtiness are set
        if (!state.details.size || !state.details.dirtiness || !state.details.propertyType) {
          return false;
        }
        return true;
      default:
        return false;
    }
  }
  //dispatch
  const updateServiceType = (serviceType) =>
    appointmentDispatch({ type: "updateServiceType", payload: serviceType });
  const updateDetails = (details) =>
    appointmentDispatch({ type: "updateDetails", payload: details });
  // funcion para guardar dentro de details la info de los servicios opcionales
  const updateOptionalDetails = (details) =>
    appointmentDispatch({ type: "updateOptionalDetails", payload: details });
  const updateAppointmentQuantity = (appointmentQuantity) =>
    appointmentDispatch({ type : "updateAppointmentQuantity", payload: appointmentQuantity });
  const updateTimes = (times) =>
    appointmentDispatch({ type : "updateTimes", payload: times });
  const updateDate = (date) => {
    appointmentDispatch({ type: "updateDate", payload: date });
  };
  const updateStartTimestamp = (startTimestamp) => {
    appointmentDispatch({
      type: "updateStartTimestamp",
      payload: startTimestamp,
    });
  };
  const updateEndTimestamp = (endTimestamp) => {
    appointmentDispatch({ type: "updateEndTimestamp", payload: endTimestamp });
  };
  const updateDuration = (duration) => {
    appointmentDispatch({
      type: "updateDuration",
      payload: duration,
    });
  };
  const updateUnitPrice = (unitPrice) => {
    appointmentDispatch({ type: "updateUnitPrice", payload: unitPrice });
  };
  const updatePrice = (price) => {
    appointmentDispatch({ type: "updatePrice", payload: price });
  };
  const updateAllPrices = (allPrices) => {
    appointmentDispatch({ type: "updateAllPrices", payload: allPrices });
  };
  // nueva funcion para agregar el precio de los servicios adicionales
  const addPrice = (price) => {
    appointmentDispatch({ type: "addPrice", payload: price });  //aca se podria agregar otros inputs para saber que servicios extra existen
  };
  // Para modificar el objeto y marcar que servicios opcionales estan contratados
  const addOptionalServices = (services) => {
    appointmentDispatch({ type: "addOptionalServices", payload: services });
  }
  const updateCoupon = (couponId) => {
    appointmentDispatch({ type: "updateCoupon", payload: couponId });
  };
  const updateAddress = (address) => {
    appointmentDispatch({ type: "updateAddress", payload: address });
  };
  const updatePaymentMethod = (paymentMethod) => {
    appointmentDispatch({
      type: "updatePaymentMethod",
      payload: paymentMethod,
    });
  };

  const updatePropertyType = (propType) => {
    appointmentDispatch({ type: "updatePropertyType", payload: propType });
  }

  const getDetail = (name) => {
    return state.details[name];
  };
  // funcion para obtener valor de los servicios adicionales.
  const getOptionalDetail = (name) => {
    let detail = state.details.optionalDetails[name] || "";
    return detail
    
  };

  //functions
  function getSizeName(size) {
    switch (size) {
      case 1:
        return "1-45 (m²)";
      case 2:
        return "46-100 (m²)";
      case 3:
        return "101-180 (m²)";
      default:
        return undefined;
    }
  }

  function getDirtiness(lvl) {
    switch (lvl) {
      case "low":
        return "Bajo";
      case "normal":
        return "Medio";
      case "high":
        return "Alto";
      default:
        return undefined;
    }
  }
  function getPropertyType(name) {
    switch (name) {
      case "house":
        return "Casa";
      case "apartment":
        return "Departamento";
      default:
        return undefined;
    }
  }

  function getSummary() {
    return (
      <>
        {state.details.size !== 0 && state.details.dirtiness !== "" && (
          <>
            <ul>
              <li className="item-details-summary">
                <span>Habitaciones:</span>
                <span>{getDetail("rooms")}</span>
              </li>
              <li className="item-details-summary">
                <span>Baños:</span>
                <span>{getDetail("bathrooms")}</span>
              </li>
              <li className="item-details-summary">
                <span>Tamaño del hogar:</span>
                <span>{getSizeName(getDetail("size"))}</span>
              </li>
              <li className="item-details-summary">
                <span>Nivel de suciedad:</span>
                <span>{getDirtiness(getDetail("dirtiness"))}</span>
              </li>
              <li className="item-details-summary">
                <span>Tipo de vivienda:</span>
                <span>{getPropertyType(getDetail("propertyType"))}</span>
              </li>
            </ul>
            <hr />
          </>
        )}
      </>
    );
  }
  function niceName(name){
    switch (name){
      case "planchado":
        return "Planchado: "
      case "limpiezaVentanas":
        return "Limpieza de ventana: "
      case "limpiezaMicroondas":
        return "Limpieza microondas: "
      case "limpiezaRefrigerador":
        return "Limpieza refrigerador: "
      case "limpiezaHorno":
        return "Limpieza horno: "
      case "limpiezaHornoRefriMicroondas":
        return "Pack  electrodomésticos: "
      default:
        return null;
    }
  }
  function getOptionalServices() {
    return (
      <>  
            <ul>
              <li className="item-details-summary">
                <span>Valor limpieza:</span>
                <span>{priceFormatter(state.base_price)}</span>
              </li>
              {Object.keys(state.optionalServicesPrice).map((key, index) => {
                let estado = state.optionalServicesPrice[key]==0 ? "hidden" : "";
                if (state.optionalServicesPrice[key]!=0) {
                  return (
                    <li className="item-details-summary" estado>
                      <span estado>{niceName(key)}</span>
                      <span estado>{priceFormatter(state.optionalServicesPrice[key])}</span>
                    </li>)
                }     
              })}
            </ul>
            <hr />
          </>
    );
  }

  return (
    <AppointmentContext.Provider
      value={{
        state,
        updateServiceType,
        updateDetails,
        updateOptionalDetails,
        updateDate,
        updateAppointmentQuantity,
        updateTimes,
        updateStartTimestamp,
        updateEndTimestamp,
        updateDuration,
        updateUnitPrice,
        updatePrice,
        updateAllPrices,
        addPrice,
        addOptionalServices,
        updateCoupon,
        updateAddress,
        updatePaymentMethod,
        getSummary,
        getOptionalServices,
        appointmentDispatch,
        getDetail,
        getOptionalDetail,
        checkDetails,
        checkDetails2,
        updatePropertyType,
      }}
    >
      {children}
    </AppointmentContext.Provider>
  );
};
