import React, { useState, useContext, useEffect } from "react";
import BrowserTitleBar from "../components/general/BrowserTitleBar";
import Footer from "../components/general/Footer";
import Header from "../components/general/Header";
import OverHeader from "../components/general/OverHeader";
import { UserContext } from "../context/user/UserContext";
import { useNavigate, Outlet } from "react-router-dom";
import { Button } from "semantic-ui-react";

import ProfileMenu from "../components/profile/ProfileMenu";

function Profile() {
    const { getSession, logout } = useContext(UserContext);
    const navigate = useNavigate();

    // if user is not logged in, redirect to login page 
    useEffect(() => {
        getSession().then((session) => {
            // console.log("session", session);
        }).catch((error) => {
            console.log("error", error);
            navigate("/login");
        });
    }, []);

    return (
        <>
            <BrowserTitleBar title="Mopit - Servicios a domicilio" />
            <OverHeader />
            <Header />
            <div className="profile-container">
                <div className="menu-container">
                    <ProfileMenu />
                </div>
                <Outlet />
            </div>
            <Footer />
        </>
  )
}

export default Profile;