import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import momentPlugin from "@fullcalendar/moment";
import { Button, Loader } from "semantic-ui-react";
import { useNavigate, useOutletContext } from "react-router-dom";
import Modal from "../general/Modal";
import useCalendar from "../../hook/useCalendar";

import OnePageCalendar from "../onePageCalendar/OnePageCalendar";
function AppointmentCalendar(props) {
  const navigate = useNavigate();

  const { route } = useOutletContext();
  const [dateNow, setDateNow] = useState("")
  const {
    appointment,
    dataIsReturned,
    addTimes,
    emptyTimes,
    verifyDate2, 
    getArrayBlocked,
    addMinutes,
    filterPassedTime
  } = useCalendar(route);

  
  useEffect(() => {
    let dateNow = new Date().toLocaleString('es-CL');
    var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -5);
    setDateNow(localISOTime); //20220706
    //setDateNow(`${dateNow.split("-")[2].split(" ")[0]}-${dateNow.split("-")[1]}-${dateNow.split("-")[0]}T${dateNow.split(" ")[1]}`);
    emptyTimes();
  }, [])
  
  if (dataIsReturned === false || dateNow === "") {
    return <> 
      <div className="loader-top">
        <Loader  active inline='centered' >Cargando calendario</Loader>
      </div>
    </>;
  } else {
    return (
      <>
        <div>
          <OnePageCalendar
            appointment = {appointment}
            verifyDate2 =  {verifyDate2}
            addTimes = {addTimes}
            getArrayBlocked = {getArrayBlocked}
            addMinutes = {addMinutes}
            route = {route}
            filterPassedTime = {filterPassedTime}
          />
        </div>
      </>
    );
  }
}
export default AppointmentCalendar;