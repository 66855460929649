import { useState, useContext, useEffect } from "react";
import {
    Form,
    Input,
    TextArea,
    Button,
    Select,
    Radio,
} from "semantic-ui-react";
import { AppointmentContext } from "../../context/appointment/AppointmentContext";
import { UserContext } from "../../context/user/UserContext";
import { CommuneContext } from "../../context/commune/CommuneContext";
import { useNavigate, useOutletContext } from "react-router-dom";

import UserAddress from "../users/UserAddress";
import NewAddress from "../users/NewAddress";
import { AiOutlinePlusCircle } from "react-icons/ai";

function AppointmentAddress() {
    const navigate = useNavigate();

    const { route } = useOutletContext();

    const appointment = useContext(AppointmentContext);
    const user = useContext(UserContext);
    const { communes, regions, countries } = useContext(CommuneContext);

    const [address, setAddress] = useState(appointment.state.address);

    // Logged in user
    const [newAddress, setNewAddress] = useState(false);
    const [selected, setSelected] = useState(-1);
    // change address when user modifies it, or when user selects an address
    useEffect(() => {
        if (user.state.addressList.length > 0) {
            const addressFromList = user.state.addressList.find(
                (address) => address.id === selected
            );
            setAddress({ ...addressFromList, country: "Chile" });
        }
    }, [user.state.addressList, selected]);

    const newAddressHandler = () => {
        setNewAddress(true);
        setSelected(-1);
    };

    // Guest
    const formValid = user.state.id
        ? selected === -1
        : !address.commune ||
          !address.country ||
          !address.region ||
          !address.street ||
          !address.homeNumber;

    const onSubmit = (e) => {
        e.preventDefault();
        // update address
        appointment.updateAddress(address);
        // go to next step (agendamiento)
        navigate(route.path_next);
    };

    if (!appointment.checkDetails()) {
        navigate(route.path_start);
    }

    return (
        <div className="appointment-address-container">
            {/* Logged User */}
            {user.state.id ? (
                <>
                    <div className="appointment-user-addresses">
                        {user.state.addressList.map((address, index) => {
                            return (
                                <div
                                    className="appointment-user-address-container"
                                    key={index}
                                    onClick={() => setSelected(address.id)}
                                >
                                    <div className="appointment-user-address-container__radio">
                                        <Radio
                                            checked={selected === address.id}
                                            onChange={() =>
                                                setSelected(address.id)
                                            }
                                        />
                                    </div>
                                    <UserAddress
                                        id={address.id}
                                        address={address}
                                    />
                                </div>
                            );
                        })}
                    </div>

                    <div className="appointment-address-container__new-address">
                        <AiOutlinePlusCircle
                            size="2em"
                            onClick={newAddressHandler}
                        />
                        <span onClick={newAddressHandler}>
                            Agregar dirección
                        </span>
                    </div>
                    {newAddress && (
                        <NewAddress closedModal={() => setNewAddress(false)} />
                    )}
                </>
            ) : (
                /* Guest User */
                <Form>
                    <Form.Group required>
                        <Form.Field
                            required
                            id="form-input-control-address"
                            control={Input}
                            label="Dirección"
                            name="street"
                            placeholder="Av. Mopit"
                            width={8}
                            value={address.street}
                            onChange={(e) =>
                                setAddress({
                                    ...address,
                                    street: e.target.value,
                                })
                            }
                        />
                        <Form.Field
                            required
                            id="form-input-control-number"
                            control={Input}
                            label="Número"
                            name="homeNumber"
                            placeholder="11"
                            width={4}
                            value={address.homeNumber}
                            onChange={(e) =>
                                setAddress({
                                    ...address,
                                    homeNumber: e.target.value,
                                })
                            }
                        />
                        <Form.Field
                            id="form-input-control-dpto"
                            control={Input}
                            label="Dpto/Oficina"
                            name="dept"
                            placeholder="27"
                            width={4}
                            value={address.dept}
                            onChange={(e) =>
                                setAddress({ ...address, dept: e.target.value })
                            }
                        />
                    </Form.Group>
                    <Form.Field
                        control={TextArea}
                        label="Otro"
                        name="other"
                        placeholder="A la derecha de la panaderia..."
                        maxLength="150"
                        rows={1}
                        value={address.other}
                        onChange={(e) =>
                            setAddress({ ...address, other: e.target.value })
                        }
                    />
                    <Form.Group widths="equal">
                        <Form.Field
                            required
                            control={Select}
                            options={countries}
                            label="País"
                            name="country"
                            value={address.country}
                            onChange={(e, { value }) =>
                                setAddress({ ...address, country: value })
                            }
                        />
                        <Form.Field
                            required
                            control={Select}
                            options={regions}
                            label="Región"
                            name="region"
                            placeholder={address.region}
                            value={address.region}
                            onChange={(e, { value }) =>
                                setAddress({ ...address, region: value })
                            }
                        />
                        <Form.Field
                            required
                            control={Select}
                            options={communes[address.region]}
                            label="Comuna"
                            name="commune"
                            value={address.communeId}
                            onChange={(e, { value }) =>
                                setAddress({
                                    ...address,
                                    communeId: value,
                                    commune: communes[address.region].find(
                                        (commune) => commune.value === value
                                    ).text,
                                })
                            }
                        />
                    </Form.Group>
                </Form>
            )}
            <div className="address-buttons-container">
                <Button
                    variant="primary"
                    onClick={() => navigate(route.path_prev)}
                    basic
                >
                    Volver
                </Button>
                <Button
                    disabled={formValid}
                    variant="primary"
                    onClick={onSubmit}
                    primary
                >
                    Siguiente
                </Button>
            </div>
        </div>
    );
}

export default AppointmentAddress;
