import React, { useContext, useState, useEffect } from "react";
import { AppointmentContext } from "../../context/appointment/AppointmentContext";
import { UserContext } from "../../context/user/UserContext";
import moment from "moment";
import { UAParser } from "ua-parser-js";
import { Button, Form, Input, Loader, Dimmer } from "semantic-ui-react";
import useServiceNameTranslate from "../../hook/useServiceNameTranslate";
import {
  createAppointmentBody,
  createAttributesBody,
} from "../../hook/ApiMethods";
import { appointmentAPI, couponAPI } from "../../api/Api";
import axios from "axios";

import { Pay } from "../../hook/Pay";
import usePriceFormatter from "../../hook/usePriceFormatter";


function AppointmentSummary({
  service,
  actualStep,
  redirectMessage,
  setRedirectMessage,
}) {
  const user = useContext(UserContext);
  const { state, getSummary, updateCoupon, getOptionalServices, updateAppointmentQuantity, updatePrice } = useContext(AppointmentContext);

  const { priceFormatter } = usePriceFormatter();

  const [subtotal, setSubtotal] = useState(state.price);
  const [total, setTotal] = useState(state.price);

  const { serviceCategory, serviceName } = useServiceNameTranslate(service);

  const [blockViewFlag, setBlockViewFlag] = useState(0);

  //to input text
  const [coupon, setCoupon] = useState("");
  // coupon object with information
  const [couponData, setCouponData] = useState(null);
  // discount
  const [discount, setDiscount] = useState(0);
  //validate coupon in db
  const [isValidCoupon, setIsValidCoupon] = useState(false);
  // to show error message
  const [couponError, setCouponError] = useState(false);

  const [couponErrorMessage, setCouponErrorMessage] = useState("Código incorrecto.")

  // device and location
  const [city, setCity] = useState(null);
  const [region, setRegion] = useState(null);
  const [country, setCountry] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [accuracy, setAccuracy] = useState(null);
  const [os, setOs] = useState(null);
  const [osModel, setOsModel] = useState(null);
  const [architecture, setArchitecture] = useState(null);
  const [browser, setBrowser] = useState(null);
  const [infoRaw, setInfoRaw] = useState(null);

  const { 
    REACT_APP_SPECIAL_COUPON,
    REACT_APP_SPECIAL_COUPON_FIXED_DISCOUNT,
    REACT_APP_SPECIAL_COUPON_LIMIT_ROOMS,
    REACT_APP_SPECIAL_COUPON_LIMIT_BATHROOMS
  } = process.env;
  
  useEffect(() => {
    // get location info
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
        setAccuracy(position.coords.accuracy);
        axios
          .get(
            `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=es`
          )
          .then((res) => {
            setCity(res.data.city);
            setRegion(res.data.principalSubdivision);
            setCountry(res.data.countryName);
          });
      },
      (error) => {},
      { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
    );
    // device info
    try {
      const parser = new UAParser();
      const userAgent = navigator.userAgent;
      parser.setUA(userAgent);
      const info = parser.getResult();
      setOs(info.os.name);
      setOsModel(info.os.version);
      if (info.device.model) {
        setArchitecture(info.device.model);
      } else {
        setArchitecture(info.cpu.architecture);
      }
      setBrowser(info.browser.name);
      setInfoRaw(userAgent);
    } catch (error) {
      // do nothing
    }
   
  }, []);

  useEffect(() => {
    setTotal(state.price);
    setSubtotal(state.price);
  }, [state.price]);

  useEffect(() => {
    if(actualStep != 5) {
      setTotal(state.price);
      setSubtotal(state.price);
      setCouponData(null);
      setCouponErrorMessage("");
    }
  }, [actualStep]);

  useEffect(() => {
    if (couponData) {
      updateCoupon(couponData.id);
      setCouponError(false);
      setIsValidCoupon(true);
      
      const disc = discountPrice();
      setDiscount(disc);
      setTotal(subtotal - disc);
    }
  }, [couponData]);

  //Coupons & Discounts
  let fetchCoupon = async () => {
    try {
      const res = await axios.get(couponAPI, {
        params: {
          serviceType: service,
          couponName: coupon,
          dni: user.state.dni,
          bedrooms: state.details.rooms,
          bathrooms: state.details.bathrooms,
          size: state.details.size,
          dirtiness: state.details.dirtiness,
        },
        headers: {
          "x-api-key": process.env.REACT_APP_COUPON_KEY,
        },
      });
      setCouponData(res.data.coupon);
      const couponResp = res.data.coupon;
      if (!couponResp) {
        setCouponErrorMessage("Código incorrecto.");
        setCouponError(true);
        setIsValidCoupon(false);
        setTotal(subtotal);
      }
    } catch (error) {
      setCouponErrorMessage(error.response.data.message);
      setCouponError(true);
      setIsValidCoupon(false);
      setTotal(subtotal);
    }
  };

  function isCouponsEmpty() {
    if (coupon) {
      return false;
    }
    return true;
  }

  async function validateCoupons() {
    await fetchCoupon();
  }

  function discountPrice() {
    let discountAux = 0;
    switch (couponData.type) {
      case 1:
      case "percentage":
        discountAux = (subtotal * couponData.discount) / 100;
        break;
      case 2:
      case "fixed":
        discountAux = couponData.discount;
        break;
      case "range" :
        discountAux = (couponData.price - subtotal) * -1;
        const unitPrice = subtotal - discountAux;
        const unitDiscount = 0.05;
        const newPrice = (state.appointmentQuantity * unitPrice) - (unitPrice * ((state.appointmentQuantity - 1) * unitDiscount));
        discountAux = subtotal - newPrice;
        couponData.discount = discountAux;
        break;
      default:
        discountAux = 0;
        break;
    }
    return Math.round(discountAux) ;
  }

  function createRedirectMessage() {
    if (redirectMessage === "Error al crear la cita") {
      return (
        <div className="appointment-loading">
          <h5> {redirectMessage} </h5>
        </div>
      );
    } else if (redirectMessage !== "") {
      return (
        <div className="appointment-loading">
          <div class="appointment-loading__icon"> </div>
          <h5> {redirectMessage} </h5>
        </div>
      );
    } else {
      return <div> </div>;
    }
  }

  function renderDiscount() {
    if (couponError) {
      return (
        <div
          className={isCouponsEmpty() ? "item-empty" : "item-details-summary"}
        >
          <span> {couponErrorMessage} </span>
        </div>
      );
    }
    if (!couponData) {
      return null;
    }
    if (isValidCoupon) {
      return (
        <>
          <br />
          <li className="item-details-summary">
            <span> Cupón: </span>
            {couponData.type === "percentage" ? (
              <span> -{couponData.discount} % </span>
            ) : (
              <span> -{priceFormatter(couponData.discount)} </span>
            )}
          </li>
          <li className="item-details-summary">
            <span> Descuento: </span> <span> -{priceFormatter(discount)} </span>
          </li>
          <li className="item-details-summary">
            <span> Subtotal: </span>
            <span> {priceFormatter(state.price)} </span>
          </li>
          <hr />
        </>
      );
    }
  }

  const onSubmit = async () => {
    const appIDs = [];
    for (let i = 0; i < state.appointmentQuantity; i++) {
      const appointmentCreateBody = createAppointmentBody(
        state,
        user.state,
        subtotal,
        total,
        i,
        {
          os,
          osModel,
          architecture,
          browser,
          infoRaw,
          latitude,
          longitude,
          accuracy,
          city,
          region,
          country,
        }
      );
      try {
        setBlockViewFlag(1);
        setRedirectMessage("Creando cita");
        const appointmentResponse = await axios.post(
          appointmentAPI,
          appointmentCreateBody,
          {
            headers: {
              "x-api-key": process.env.REACT_APP_APPOINTMENT_KEY,
            },
          }
        );
        const appointmentId = appointmentResponse.data.id;
        appIDs.push(appointmentId);
        const appointmentAttributesBody = createAttributesBody(
          state,
          appointmentId
        );
        await axios.post(
          appointmentAPI + "/attributes",
          appointmentAttributesBody,
          {
            headers: {
              "x-api-key": process.env.REACT_APP_APPOINTMENT_KEY,
            },
          }
        );
        // setRedirectMessage(`Redireccionando a ${state.paymentMethod}`);
        // Pay
        if(i == state.appointmentQuantity - 1) {
          await Pay(appIDs, total, user.state.email, state.paymentMethod);
        }
      } catch (error) {
        setBlockViewFlag(0);
        setRedirectMessage("Error al crear la cita");
        return;
      }
    }
    
    
  };

  const renderPaymentMethod = () => {
    if (state.paymentMethod === null || state.paymentMethod === "") {
      return <span></span>;
    } else {
      return (
        <li className="item-details-summary">
          <span>Metodo de pago:</span>
          <span>{state.paymentMethod}</span>
        </li>
      );
    }
  };

  const blockView = () => {
    if (blockViewFlag === 1) {
      return (
        <div className="bloqued-view">
          <Dimmer active>
            <Loader size="large">Cargando agendamiento</Loader>
          </Dimmer>
        </div>
      );
    }

    return <div></div>;
  };

  const validatePaymentMethod = state.paymentMethod === "";
  const address =
    state.address.street +
    " " +
    state.address.homeNumber +
    ", " +
    (state.address.dept ? `Dpto  ${state.address.dept},` : "") +
    state.address.commune +
    ", " +
    state.address.region +
    ", " +
    state.address.country;
  return (
    <div className="grid-summary">
      {blockView()}
      <div className="grid-service-name-container">
        <img
          className="grid-service-name-container__img-service"
          src={require(`../../assets/images/services/${service}.png`).default}
          alt="service-test"
        />
        <div className="grid-service-name-container__name-service">
          <h3> {serviceCategory} </h3> <h4> {serviceName} </h4>
        </div>
      </div>
      <div className="grid-service-description">
        <div className="grid-service-description__title">
          <h3> Resumen de compra </h3>
        </div>
        <hr /> {/* details */} {actualStep >= 2 && getSummary()} 
        {/* details */} {actualStep >= 4 && getOptionalServices()} 
        {/* address */}
        {actualStep >= 5 && (
          <>
            <h4> Dirección </h4> <span> {address} </span> <hr />
          </>
        )}
        {/* date */}
        {actualStep >= 6 && (
          state.times.map((obj, index) => {
            return(
              <ul>
                <li className="item-details-summary">
                  <span className="item-details-summary--title">Agendamiento {index+1}:</span>
                </li>
                <li className="item-details-summary">
                  <span> <b>Fecha:</b> {obj.date}</span> <span> <b>Hora:</b> {obj.start} a {obj.end}</span>
                </li>
                <hr />
              </ul>
            );
          })
        )}
        {/* contacto */} 
        {/* price */}
        {actualStep >= 3 && (
          <>
            <ul>
              {renderPaymentMethod()}
              {actualStep >= 5 && (
              <li className="item-details-summary--coupon">
                <Form.Field
                  fluid
                  className="input-coupon"
                  required
                  id="form-coupon"
                  control={Input}
                  name="coupon"
                  value={coupon}
                  onChange={(e) => {
                    setCouponError(false);
                    setCoupon(e.target.value);
                  }}
                  placeholder="Código promocional"
                />
                <Button
                  basic
                  color="blue"
                  disabled={isCouponsEmpty()}
                  onClick={() => validateCoupons()}
                >
                  Canjear
                </Button>
              </li>
              )}
              {actualStep >= 5 && renderDiscount()}
              <li className="item-details-summary price-container">
                <span>
                  <strong> Total: </strong>
                </span>
                <span>
                  <strong> {priceFormatter(total)} </strong>
                </span>
              </li>
            </ul>
            {/* pay button */}
            <Button primary disabled={validatePaymentMethod} onClick={onSubmit}>
              Pagar
            </Button>
            {createRedirectMessage()}
          </>
        )}
      </div>
    </div>
  );
}

export default AppointmentSummary;
