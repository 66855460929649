import React, { useContext, useState } from 'react'
import { Form, Radio, Button, Message } from 'semantic-ui-react'
import { useNavigate, useOutletContext } from "react-router-dom";

import { AppointmentContext } from "../../context/appointment/AppointmentContext";

const AppointmentCount = () => {

  const navigate = useNavigate();
  const appointment = useContext(AppointmentContext);

  const { route } = useOutletContext();

  const [quantity, setQuantity] = useState(appointment.state.appointmentQuantity);

  const maxQuantity = [1, 2, 3, 4, 5];


  if (!appointment.checkDetails()) {
    navigate(route.path_start);
  }

  const onSubmit = (quantity) => {
    appointment.updateAppointmentQuantity(quantity);
    appointment.updatePrice(appointment.state.allPrices[quantity-1]);
    navigate(route.path_next);
  }

  const onQuantityChange = (value) => {
    setQuantity(value);
  }

  const showOptions = () => {
    return maxQuantity.map((index) => {
      return(
            <Form.Field>
              <div className='appointment-count-price-box' >
                <Message className='appointment-count-price-box__color'>
                  <Radio
                    className='appointment-count-text'
                    label={`${index} Servicio(s) - Precio: $${new Intl.NumberFormat('es-CL').format(appointment.state.allPrices[index-1])}`}
                    name='radioGroup'
                    value={index}
                    checked={quantity === index}
                    onChange={(e) => onQuantityChange(index)}
                  />
                </Message>
              </div>
              
              
            </Form.Field>
          );
    })
  }

  return (
    <>
    <div className='appointment-count-container'>
      <h3>Seleccione la cantidad de servicios que desea agendar</h3>
      <p className='container'>
        El precio actual es de ${new Intl.NumberFormat('es-CL').format(appointment.state.unitPrice)} por un servicio, si desea agendar más de uno el precio aumentara pero se aplicara un descuento por cada servicio extra.
      </p>
      <Form>
        {/* <Form.Field>
          Cantidad seleccionada: {quantity}
        </Form.Field> */}
        <div> {showOptions()} </div>
        
      </Form>
      <div className='appointment-count-container__button'>
        <Button variant="primary" onClick={() => navigate(route.path_prev)} basic color="gray">Volver</Button>
        <Button disabled={quantity < 1 ? true : false} variant="primary" onClick={() => onSubmit(quantity)} primary>Siguiente</Button>
      </div>
    </div>
    
    </>
  )
}

export default AppointmentCount