import React from 'react'
import wspLogo from '../../assets/images/whatsapp_logo.png'
import { wspURL } from '../../api/Api'

export const WhatsappModal = () => {
    return (
        <div className='wsp-background'>
            <a href={wspURL} target="_blank" rel="noreferrer">
                <img className='corner-wsp' src={wspLogo}/>
            </a>
        </div> 
  )
}
