import React from "react";

function Modal(props) {
  const onCancel = () => {
    props.onCancel();
  };

  const onClose = () => {
    props.onClose(false);
  };

  const onTrigger = () => {
    props.parentCallback();
  };

  if (props.type === 0) {
    return (
      <div className="custom-modal" id="custom-modal">
        <h2>Mensaje</h2>
        <div className="content">{props.children}</div>
        <div className="actions">
          <button className="toggle-button custom-modal-button-color" onClick={onClose}>
            Cerrar
          </button>
        </div>
      </div>
    );
  }
  return (
    <div className="custom-modal" id="custom-modal">
      <h2>Confirmación de cita</h2>
      <div className="content">{props.children}</div>
      <div className="actions">
        <button className="toggle-button custom-modal-button-color" onClick={onCancel}>
          Cancelar
        </button>
        <button className="toggle-button" onClick={onTrigger}>
          Aceptar
        </button>
      </div>
    </div>
  );
}

export default Modal;
