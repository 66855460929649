import React, { useEffect, useState } from "react";
import { Modal, Image, Form, Button } from "semantic-ui-react";
import { validateEmail } from "../../hook/ValidateMethods";
import axios from "axios";
import { couponAPI, sendCouponEmailAPI } from "../../api/Api";
import voiceAlert from "../../assets/images/voice.png";
import promoImg from "../../assets/images/mopit_promo.png";

function HomeModal() {
  const [open, setOpen] = useState(false);
  const [returnOpen, setReturnOpen] = useState(false)
  const [firstModal, setFirstModal] = useState(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [checkTrap, setCheckTrap] = useState(null);
  const [error, setError] = useState("");
  const [coupon, setCoupon] = useState("");
  const [expiredMinutes, setExpiredMinutes] = useState(0);
  const [expiredSeconds, setExpiredSeconds] = useState(0);

  //Promo Modal
  const [promoModal, setPromoModal] = useState(false)

  const formValid = !email || !firstName || !lastName;

  const modalsData = require('../../data/modals.json');

  let countDownDate = new Date();
  let timerMinutes = 10;
  countDownDate.setMinutes(countDownDate.getMinutes() + timerMinutes);
  // countDownDate.setSeconds(countDownDate.getSeconds() + 16);


  const getTimeRemaining = (endtime) => {
    const total = Date.parse(endtime) - Date.parse(new Date());
    // Se parcea el total en milisegundos a minutos y segundos
    const seconds = Math.floor( (total/1000) % 60 );
    const minutes = Math.floor( (total/1000/60) % 60 );
  
    return {
      total,
      minutes,
      seconds
    };
  }

  const initializeClock = (endtime) =>{
    function updateClock(){
      const t = getTimeRemaining(endtime);
      setExpiredMinutes(t.minutes);
      setExpiredSeconds(t.seconds);
      if (t.total <= 0) {
        clearInterval(timeinterval);
        setOpen(false);
      }
    }
    updateClock();
    var timeinterval = setInterval(updateClock,1000);
  }

  //   render modal after N seconds
  useEffect(() => {
    let pop_status = sessionStorage.getItem("pop_status");
    let pop_return_status = sessionStorage.getItem("pop_return_status");

    let pop_promo = sessionStorage.getItem("pop_promo");
  
    if(pop_return_status == "true") {
      setTimeout(() => setReturnOpen(true), modalsData.returnModal.timer);
    }

    // Promo Modal
    if(!pop_promo) {
      setTimeout(() => setPromoModal(true), 2000);
      sessionStorage.setItem("pop_promo", "true");
    }

    let timer;
    if (!pop_status) {
      timer = setTimeout(() => setFirstModal(true), modalsData.firstModal.timer);
      sessionStorage.setItem("pop_status", "true");
    }
    return () => clearTimeout(timer);
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    if(checkTrap != null) {
      return;
    }
    if (validateEmail(email)) {
      // get coupon code
      try {
        const couponResp = await axios.post(
          couponAPI + "/new-user",
          { email, firstName, lastName },
          {
            headers: {
              "x-api-key": process.env.REACT_APP_COUPON_KEY,
            },
          }
        );
        const coupon = couponResp.data.coupon;
        setCoupon(coupon);
        // send email to user
        await axios.post(sendCouponEmailAPI, {
          email: email,
          firstName: firstName,
          lastName: lastName,
          coupon: coupon,
          discount: "10%"
          },
          {
            headers: {
              "x-api-key": process.env.REACT_APP_SEND_COUPON_EMAIL_KEY,
            },
          });
      } catch (error) {
        if (error.response.status === 409) {
          setError("Ya existe un cupón para este correo");
        } else {
          setError("El correo ingresado no es válido");
        }
      }
    } else {
      setError("El correo ingresado no es válido");
    }
  };

  const showModal = (option) => {
    switch (option) {
      case 1: {
        closeReturnModal();
        break;
      }
      case 2: {
        setFirstModal(false);
        break;
      }
      default:
        break;
    }
    setOpen(true);
    initializeClock(countDownDate);
  }

  const closeReturnModal = () => {
    setReturnOpen((returnOpen) => !returnOpen);
    sessionStorage.setItem("pop_return_status", "false");
  }

  
  /*if(promoModal) {
    return (
      <Modal
        size="tiny"
        open={promoModal}
        closeIcon
        onClose={(e) => setPromoModal(false)}
      > 
      <Image size='massive' src={promoImg} />
       { /* <div className="alert-cyber">
          <img src={cyberDay} alt="Cyber Day 2022" />
    </div>  } 
      </Modal>
    )
  } */
   

  if(returnOpen){
    return (
      <Modal
        size="tiny"
        open={returnOpen}
        closeIcon
        onClose={(e) => closeReturnModal()}
      >
        <Modal.Header> {modalsData.returnModal.title} </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <div className="alert-box">
              <img className="alert-img" src={voiceAlert} alt="" />
              <p>
              {modalsData.returnModal.subtitle}
                <br />
                {modalsData.returnModal.content}&nbsp;
                <span className="bold-limited">
                {modalsData.returnModal.highlighted_word}
                </span>
              </p>
            </div>
            
            <div className="alert-button">
              <Button  primary onClick={e => showModal(1)}>Lo quiero</Button>
              <Button onClick={(e) => closeReturnModal()}>No gracias</Button>
            </div>

          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  } else {
    if (coupon !== "") {
      return (
        <Modal
          size="tiny"
          open={open}
          closeIcon
          onClose={() => setOpen((open) => !open)}
        >
          <Modal.Header> ¡Felicidades! </Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <p>
                ¡Has obtenido un cupón de descuento!
                <br />
                Recibirás un correo con este cupón de descuento.
                <br />
                <br />
                <strong>
                  <u>Código:</u>
                </strong>
                <br />
                {coupon}
              </p>
            </Modal.Description>
          </Modal.Content>
        </Modal>
      );
    } else {
      if(firstModal) {
        return(
          <Modal
        size="tiny"
        open={firstModal}
        closeIcon
        onClose={(e) => setFirstModal(false)}
      >
        <Modal.Header> {modalsData.firstModal.title} </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <div className="alert-box">
              <img className="alert-img" src={voiceAlert} alt="" />
              <p>
                {modalsData.firstModal.subtitle}
                <br />
                {modalsData.firstModal.content}&nbsp;
                <span className="bold-limited">
                  {modalsData.firstModal.highlighted_word}
                </span>
              </p>
            </div>
            
            <div className="alert-button">
              <Button  primary onClick={e => showModal(2)}>Lo quiero</Button>
              <Button onClick={(e) => setFirstModal(false)}>No gracias</Button>
            </div>

          </Modal.Description>
        </Modal.Content>
      </Modal>
        );
      }
    }
  
    return (
      <Modal
        size="tiny"
        open={open}
        closeIcon
        onClose={() => setOpen((open) => !open)}
      >
        <Modal.Header>{modalsData.principalModal.title}</Modal.Header>
        <Modal.Content image>
          <Image
            wrapped
            size="small"
            src={require("../../assets/images/discount.png").default}
          />
          <Modal.Description>
            <p>
              {modalsData.principalModal.subtitle}
              <br />
              {modalsData.principalModal.content}
              <br />
              <span className="bold-limited">
                {modalsData.principalModal.highlighted_word}
              </span>
            </p>
            <Form>
              <Form.Input
                fluid
                placeholder="Email"
                onChange={(e) => {
                  setEmail(e.target.value);
                  setError("");
                }}
                error={
                  error !== ""
                    ? {
                        content: error,
                      }
                    : false
                }
              ></Form.Input>
              <Form.Group widths="equal">
  
                <Form.Input
                  fluid
                  placeholder="Nombre(s)"
                  onChange={(e) => setFirstName(e.target.value)}
                ></Form.Input>
                <Form.Input
                  fluid
                  placeholder="Apellido(s)"
                  onChange={(e) => setLastName(e.target.value)}
                ></Form.Input>
              </Form.Group>
              <input 
                className="invInput" 
                autocomplete="off" 
                value={checkTrap} 
                onChange={e => {setCheckTrap(e.target.value)}} 
                type="email" 
                id="email" 
                name="email" 
                placeholder="Your e-mail here">
              </input>
              <Button primary disabled={formValid} onClick={onSubmit}>Enviar</Button>
              <p>
                Cupón valido por los siguientes {`${expiredMinutes}:${expiredSeconds < 10 ? `0${expiredSeconds}` : expiredSeconds}`} minutos
              </p>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }

  
}

export default HomeModal;